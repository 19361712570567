import { DisplayNames } from "../../types";
import { DemandRow } from "./models";
import { DemandWebsiteDisplayDetailsRow } from "./types/websiteDisplay";
import { DemandActivitiesCalendarDetailsRow } from "./types/activitesCalendar";
import { DemandElectronicPanelsDetailsRow } from "./types/electronicPanels";
import { DemandFundraiserDetailsRow } from "./types/fundraiser";
import { DemandInfoRequestDetailsRow } from "./types/infoRequest";
import { DemandPrismeDetailsRow } from "./types/prisme";
import { DemandStandardEquipmentDetailsRow } from "./types/standardEquipment";
import { DemandTelevisionDisplayDetailsRow } from "./types/televisionDisplay";
import { DemandAdmissibilityDetailsRow } from "./types/admissibility";
import { DemandPublicSpaceDetailsRow } from "./types/publicSpace/publicSpace";
import { DemandPublicSpaceReservationRow } from "./types/publicSpace/reservation";
import { DemandSpecialEventsDetailsGeneralRow, DemandSpecialEventsGeneralRow } from "./types/specialEvents/specialEventsGeneral";
import { DemandSpecialEventDetailsRow } from "./types/specialEvents/specialEvent/specialEvent";
import { DemandPublicRoadDetailsRow } from "./types/publicRoad";
import { DemandSecurityPreventionDetailsRow } from "./types/securityPrevention";
import { DemandPermitDetailsRow } from "./types/permit";
import { DemandEcoSupportDetailsRow } from "./types/ecoSupport";
import { DemandFilmingDetailsRow } from "./types/specialEvents/filming/filming";
import { DemandEventSupportDetailsRow } from "./types/eventSupport";
import { DemandSpecialEventsDateBase } from "./types/specialEvents/dates";

const demandDisplayNames: DisplayNames<DemandRow> = {
  title: "Sujet de la demande",
  vdl_contactprincipal: "Contact principal",
  vdl_typededemande: "Type de demande",
  vdl_detailsdelademande: "Détails",
  type: "Type de demande",
  incidentid: "",
  ticketnumber: "Numéro de la demande",
  statuscode: "Status",
  modifiedon: "Date de modification",
  createdon: "Date de création",
  vdl_lienhautduformulaireportail: "Lien vers le processus",
  regroupement: "Regroupement"
};

const infoRequestDisplayNames: DisplayNames<DemandInfoRequestDetailsRow> = {
  vdl_demandeinformationid: "",
  vdl_sujetdelademande: "Sujet de la demande",
  vdl_name: "",
  vdl_descriptiondelademande: "Description de la demande",
};

const standardEquipmentDisplayNames: DisplayNames<DemandStandardEquipmentDetailsRow> =
  {
    vdl_equipementmunicipalid: "",
    vdl_adressecompleterecuperation: "Adresse complète",
    vdl_identiquealadressedelivraison: "Identique à l'adresse de livraison",
    vdl_adressecompletelivraison: "Adresse complète",
    vdl_datedelivraison: "Date de livraison",
    vdl_datederecuperation: "Date de récupération",
    vdl_name: "",
    vdl_secteurdeladressederecuperation: "Secteur de l'adresse de récupération",
    vdl_secteurdeladressedelivraison: "Secteur de l'adresse de livraison",
    vdl_remarqueoucommentaire: "Remarque ou commentaire",
    vdl_remarqueoucommentairerecuperation: "Remarque ou commentaire",
    vdl_nomdubatimentrecuperation: "Nom du bâtiment",
    vdl_nomdubatimentlivraison: "Nom du bâtiment",
    products: "Produits",
    vdl_typedeproduit: "Type de produits",
    vdl_horaire: "Horaire de livraison",
    vdl_horairerecuperation: "Horaire de récupération"
  };

const fundraiserDisplayNames: DisplayNames<DemandFundraiserDetailsRow> = {
  vdl_projetdefinancement: "Projet de financement",
  vdl_collectedargentid: "",
  vdl_erjour: "1er choix",
  vdl_emejour: "2e choix",
  vdl_3emejour: "3e choix",
  vdl_4emejour: "4e choix",
  vdl_5emejour: "5e choix",
  vdl_jemengageasuivrelaformationobligatoire:
    "Je m'engage à suivre la formation obligatoire",
  vdl_jaidejasuivilaformationobligatoireannee:
    "J'ai déjà suivi la formation obligatoire (Année)",
  vdl_nombredejeunesparticipants18ansetmoins:
    "Nombre de jeunes qui participeront",
  vdl_nombredadultesparticipants5min: "Nombre d'adultes qui participeront",
  vdl_chomedeycartier: "Chomedey/Cartier",
  vdl_jeanberauddanieljohnson: "Jean-Béraud/Daniel-Johnson",
  vdl_notredamelavatere: "Notre-Dame/Lavatère",
  vdl_stmartinboisdeboulogne: "St-Martin/Bois-De-Boulogne",
  vdl_engagementaurespectdesproceduresetdirectives:
    "En tant que responsable de l'activité, je m'engage à faire respecter les procédures et directives de la municipalité dont j'ai pris connaissance préalablement et je dégage Ville de Laval de toute responsabilité dans le cadre de cet événement.",
};

const electronicPanelsDisplayNames: DisplayNames<DemandElectronicPanelsDetailsRow> =
  {
    vdl_soutienpromotionnelid: "",
    vdl_datededebutdelaffichage: "Date de début de l'affichage",
    vdl_datedefindelaffichage: "Date de fin de l'affichage",
    vdl_quoi: "Quoi ?",
    vdl_qui: "Qui ?",
    vdl_quanddatesetheures: "Quand? Date(s) et heure(s)",
    vdl_ou: "Où ?",
    vdl_telephonepourinformationsurlevenement:
      "Téléphone pour information sur l'évènement ?",
  };

const activitiesCalendarDisplayNames: DisplayNames<DemandActivitiesCalendarDetailsRow> = {
  vdl_soutienpromotionnelid: "",
  vdl_titredelevenement: "Titre de l'évènement",
  vdl_lieudelevenement: "Lieu de l'évènement",
  vdl_adressecompletedulieudelevenement: "Adresse complète du lieu de l'évènement",
  vdl_dateetheurededebut: "Date et heure de début",
  vdl_dateetheuredefin: "Date et heure de fin",
  vdl_commentairessurleshorairesdelevenement: "Commentaires sur les horaires de l'événement",
  vdl_cout: "Coût",
  vdl_adultes: "Adultes",
  vdl_retraites: "Retraités",
  vdl_etudiants: "Etudiants",
  vdl_enfants: "Enfants",
  vdl_prixdefamille: "Prix famille",
  vdl_prixdegroupe: "Prix de groupe",
  vdl_autressvpdetaillez2: "Autres, svp détaillez",
  vdl_stationnementpayant: "Stationnement payant",
  vdl_coutdestationnement: "Coût de stationnement",
  vdl_clientelesvisees: "Clientèle(s) visée(s)",
  vdl_siteinternet: "Site internet",
  vdl_pagefacebook: "Page Facebook",
  vdl_courriel: "Courriel",
  vdl_telephonecalendrier: "Téléphone",
  vdl_descriptionsommairedelevenement: "Description sommaire de l'évènement",
  vdl_autreinformationpertinente: "Autre information pertinente",
  vdl_veuillezjoindreunephotofile: "Veuillez joindre une photo"
};
const televisionDisplayDisplayNames: DisplayNames<DemandTelevisionDisplayDetailsRow> =
  {
    vdl_soutienpromotionnelid: "",
    vdl_imagedepromotionfile: "Image de promotion",
    vdl_debutdediffusion: "Début de diffusion",
    vdl_findediffusion: "Fin de diffusion",
    vdl_secteur1: "Secteur 1",
    vdl_secteur2: "Secteur 2",
    vdl_secteur3: "Secteur 3",
    vdl_secteur4: "Secteur 4",
    vdl_secteur5: "Secteur 5",
    vdl_secteur6: "Secteur 6"
  };
const websiteDisplayDisplayNames: DisplayNames<DemandWebsiteDisplayDetailsRow> =
  {
    vdl_soutienpromotionnelid: "",
    vdl_lieninternetdelapageconcernee: "Lien internet de la page concernée",
    vdl_piecejointe: "Pièce jointe",
  };

const prismeDisplayNames: DisplayNames<DemandPrismeDetailsRow> = {
  vdl_soutienpromotionnelid: "",
  vdl_activiteapromouvoir: "Activité à promouvoir",
  vdl_datedelactivite: "Date de l'activité",
  vdl_nombredepanneaux: "Nombre de panneaux",
  vdl_datededebutdelevenement: "Date de début de l'évènement",
  vdl_datedefindelevenement: "Date de fin de l'évènement",
  vdl_autoroute25bouldelaconcordeestsite3: "Autoroute 25 & boul. de la Concorde Est (Site 3)",
  vdl_monteedumoulinleparcdumoulinsite10: "Montée du moulin & le parc du Moulin (site 10)",
  vdl_bouldeslaurentidesboulsaintmartinsite1: "Boul. des laurentides & boul. Saint Martin(Site 1)",
  vdl_bouldeslaurentidesboulcartiersite2: "Boul. des laurentides & boul. Cartier (Site 2)",
  vdl_boullecorbusierbouldusouvenirsite8: "Boul. le Corbusier & Boul. du Souvenir (Site 8)",
  vdl_bouldelaconcordebouldelavenirsite9: "Boul. de la Concorde & boul. de l'Avenir (Site 9)",
  vdl_boulcurelabelleboullevesquesite4: "Boul. Curé-Labelle & boul. Lévesque (Site 4)",
  vdl_boularthursauvebouldagenaisouestsite6: "Boul. Arthur-Sauvé & boul. Dagenais Ouest (Site 6)",
  vdl_boulsamsonavjosaphatdemerssite11: "Boul. Samson & av. Josaphat-Demers (Site 11)",
  vdl_bouldeslaurentidesbouldagenaisestsite5: "Boul. des Laurentides & boul. Dagenais Est(Site 5)",
  vdl_esquissedelaffichefile: "Esquisse de l'affiche"
};

const admissibilityDisplayNames: DisplayNames<DemandAdmissibilityDetailsRow> = {
  vdl_admisibiliteid: "",
  vdl_dorganismeabutnonlucratif: "D'organisme à but non lucratif",
  vdl_dorganismedebienfaisanceenregistre: "D'organisme de bienfaisance enregistré",
  vdl_etesvousunchapitrelavalloisduneinstancepro: "Êtes-vous un chapitre lavallois d'une instance provinciale légalement constituée?",
  vdl_siouiquelestlenometleneqdelorg: "Si oui, quel est le nom et le NEQ de l'organisme?",
  vdl_aucuneassurance: "Aucune assurance",
  vdl_assurancedesadministrateurs: "Assurance accident des administrateurs non rémunérés et bénévoles",
  vdl_assurancedesbiens: "Assurance des biens",
  vdl_assuranceresponsabilitecivile: "Assurance responsabilité civile",
  vdl_assuranceadministrateursetdirigeants: "Assurance administrateurs et dirigeants",
  vdl_nomdelacompagniedassurance: "Nom de la compagnie d'assurance",
  vdl_numerodelapolicedassurance: "Numéro de la police d'assurance",
  vdl_datedecheancedelapolicedassurance: "Date d'échéance de la police d'assurance",
  vdl_missionetobjectif: "Mission et Objectif",
  vdl_realitebesoinsoutien: "Réalité, Besoin, Soutien",
  vdl_pointsdeservicealaval: "Points de service à Laval",
  vdl_siouiaquelendroit: "Si oui, à quel endroit",
  vdl_dansqueldomainedactivitevotreorganismeinter: "Dans quel domaine d'activité votre organisme intervient-il?",
  vdl_siautresprecisez: "Si autres, précisez",
  vdl_principauxserviceouactivites: "Principaux Service ou Activités",
  vdl_desregroupementsetoudesorganismes: "Regroupements et/ou organismes",
  vdl_territoiresdesservisparlorganisme2: "Territoires Desservis par l'Organisme",
  vdl_siailleursprecisez: "Si ailleurs, précisez",
  vdl_nombredeparticipantsauxactivites: "Nombre de participants aux activités",
  vdl_typedeclientele: "Quel type de clientèle votre organisme dessert-il?",
  vdl_prescolaire0a4ans: "Préscolaire (0 à 4 ans)",
  vdl_enfants5a12ans: "Enfants (5 à 12 ans)",
  vdl_adolescents13a17ans: "Adolescents (13 à 17 ans)",
  vdl_adultes18a64ans: "Adultes (18 à 64 ans)",
  vdl_aines65ansetplus65: "Aînés (65 ans et plus)",
  vdl_politiquedeverification: "Politique de vérification",
  vdl_entente: "Entente",
  vdl_codedeconduite: "Code de conduite",
  vdl_formationsurladoptiondecomportementsadequats: "Formation sur l'adoption de comportements adéquats",
  vdl_codedeconduitedelorganisation: "Code de conduite de l'organisation",
  vdl_codeetsignatureduncontratethique: "Code et signature d'un contrat éthique",
  vdl_verificationantecedentjudiciaire: "Vérification antécédents judiciaires",
  vdl_mecanismedegestionderisqueencasdedoute: "Mécanisme de gestion de risque en cas de doute",
  vdl_utilisationdelaplateformejebenevoleca: "Utilisation de la Plateforme Je bénévole.ca ",
  vdl_jeveuxplusdinformationacesujet: "Je veux plus d'information à ce sujet",
  vdl_nombrederencontresduca: "Nombre de rencontres du CA l'année dernière",
  vdl_nombredepresencesalaga: "Nombre de présences à l'AGA",
  vdl_datedeladerniereassembleegeneraleannuelle: "Date de la dernière assemblée générale annuelle",
  vdl_nombredemembresactifsauseindelorganisme: "Nombre de membres actifs au sein de l'organisme",
  vdl_duneconcertation: "D'une concertation (précisez)",
  vdl_dunregroupement: "D'un regroupement (précisez)",
  vdl_dunefederation: "D'une fédération (précisez)",
  vdl_produisezvousannuellementdesprevisionsbudgeta: "Produisez-vous annuellement des prévisions budgétaires?",
  vdl_vosderniersetatsfinanciersontilsfaitlobjet: "Vos derniers états financiers ont-ils fait l'objet d'une vérification?",
  vdl_datedefindevotreexercicefinancierannuel: "Date de fin de votre exercice financier annuel:",
  vdl_audit: "Audit",
  vdl_missiondexamen: "Mission d'examen",
  vdl_verificationcomptable: "Vérification comptable",
  vdl_datedeladeclarationaureq: "Date de la déclaration au REQ",
  vdl_admissibiliterenouvellement: "",
  vdl_resolutionduconseildadministration: "Résolution du conseil d'administration",
  vdl_reglementsgeneraux: "Règlements généraux",
  vdl_procesverbauxdesagaetags: "Procès-verbaux des AGA et AGS",
  vdl_lettrespatentesetcharte: "Lettres patentes et charte",
  vdl_rapportdactivite: "Rapport d'activité",
  vdl_etatsfinanciers: "États financiers",
};

const publicSpaceDisplayNames: DisplayNames<DemandPublicSpaceDetailsRow> = {
  vdl_espacemunicipalid: "",
  vdl_name: "Nom de l'activité / évènement",
  reservations: "",
  vdl_tournage: ""
};

const publicSpaceReservationDisplayNames: DisplayNames<DemandPublicSpaceReservationRow> =
  {
    vdl_reservationid: "",
    vdl_courriel: "Courriel",
    vdl_debutdelareservation: "Début réservation",
    vdl_findelareservation: "Fin réservation",
    vdl_ndetelephoneprincipal: "N° de téléphone principal",
    vdl_prenom: "Prénom",
    vdl_nom: "Nom",
    vdl_nombredeparticipants: "Nombre de participants",
    vdl_nomdusiteareserver: "Nom du site à réserver",
    vdl_sousplateau: "Sous-plateau",
    vdl_titre: "Titre",
  };

const specialEventsGeneralDisplayNames: DisplayNames<DemandSpecialEventsDetailsGeneralRow> =
  {
    vdl_evenementspecialid: "",
    vdl_lieudelevenement: "Lieu de l'évènement",
    vdl_precisionsurlelieu: "Précision sur le lieu",
    vdl_siautreprecisezlieu: "Si autre précisez",
    vdl_encasdintemperieslevenementest: "En cas d'intempéries, l'évènement est:",
    vdl_siremisle: "Si remis, le",
    dates: "Dates"
  };

  const publicRoadDisplayNames: DisplayNames<DemandPublicRoadDetailsRow> = {
    vdl_voiepubliqueid: "",
    vdl_fermeturedelarue: "Fermeture de la rue",
    vdl_troncon: "Tronçon",
    vdl_nomdelarue: "Nom de la rue",
    vdl_nord: "Nord",
    vdl_sud: "Sud",
    vdl_est: "Est",
    vdl_ouest: "Ouest",
    vdl_entrerueouadresses: "Entre (rue ou adresses)",
    vdl_circuit: "Circuit",
    vdl_trajetrueparrueavecdirection: "Trajet rue par rue avec direction",
    vdl_dateetheuredelafermeture: "Date et heure de la fermeture",
    vdl_dateetheuredelareouverture: "Date et heure de la réouverture",
    vdl_renseignementadditionnels: "Renseignement additionnels",
    vdl_fermeturedutrottoirliste: "Fermeture du trottoir",
    vdl_nomdelarue2: "Nom de la rue",
    vdl_entrerueouadresses4: "Entre (rue ou adresses)",
    vdl_nord2: "Nord",
    vdl_sud2: "Sud",
    vdl_est2: "Est",
    vdl_ouest2: "Ouest",
    vdl_dateetheuredelafermeture2: "Date et heure de la fermeture",
    vdl_dateetheuredelareouverture2: "Date et heure de la réouverture",
    vdl_renseignementadditionnels2: "Renseignements additionnels",
    vdl_raisondelademande: "Raison de la demande",
    vdl_nomdelarue3: "Nom de la rue",
    vdl_nord3: "Nord",
    vdl_est3: "Est",
    vdl_sud3: "Sud",
    vdl_ouest3: "Ouest",
    vdl_dateetheuredelafermeture3: "Date et heure de la fermeture",
    vdl_dateetheuredelareouverture3: "Date et heure de la réouverture",
    vdl_renseignementadditionnels3: "Renseignements additionnels",
    vdl_raisondelademande2: "Raison de la demande",
    vdl_nomdelarue4: "Nom de la rue",
    vdl_nord4: "Nord",
    vdl_est4: "Est",
    vdl_sud4: "Sud",
    vdl_ouest4: "Ouest",
    vdl_dateetheuredelafermeture4: "Date et heure de la fermeture",
    vdl_dateetheuredelareouverture4: "Date et heure de la réouverture",
    vdl_renseignementadditionnels4: "Renseignements additionnels",
    vdl_redonneeavelo: "Randonnée à vélo",
    vdl_defile: "Défilé",
    vdl_marche: "Marche",
    vdl_processionreligieuse: "Procession religieuse",
    vdl_vehiculehippomobilecaleche: "Véhicule hippomobile (calèche)",
    vdl_rassemblementsansdeplacement: "Rassemblement (sans déplacement)",
    vdl_nombredeparticipants: "Nombre de participants",
    vdl_enfants512ans: "Enfants 5-12 ans",
    vdl_adultes: "Adultes",
    vdl_trajetrueparrueavecdirection2: "Trajet rue par rue avec direction",
    vdl_rue: "Rue",
    vdl_trottoir: "Trottoir",
    vdl_pistescyclables: "Pistes cyclables",
    vdl_autre: "Autre",
    vdl_siautreprecisez: "Si autre, précisez",
    vdl_typedactivite: "",
    vdl_tolerancedestationnement: "Tolérance de stationnement",
    vdl_fermeturedelarue2: "Fermeture de rue",
    vdl_fermeturedutrottoircasecocher: "Fermeture du trottoir",
    vdl_interdictiondestationnement: "Interdiction de stationnement"
  };
  const securityPreventionDisplayNames: DisplayNames<DemandSecurityPreventionDetailsRow> = {
    vdl_enfants512ans: "Enfants (5-12 ans)",
    vdl_adultes: "Adultes",
    vdl_autre: "Autre",
    vdl_siautreprecisez: "Si autre précisez",
    vdl_securitepreventionid: "",
    vdl_feudecamp1mdediametre: "Feu de camp (<1m de diamètre)",
    vdl_feudejoie1mdediametre: "Feu de joie ( >1m de diamètre)",
    vdl_foyerbrasero: "Foyer / Brasero",
    vdl_jongleur: "Jongleur",
    vdl_cracheurdefeu: "Cracheur de feu",
    vdl_flambeaux: "Flambeaux",
    vdl_lampions: "Lampions",
    vdl_lanternes: "Lanternes",
    vdl_autre2: "Autre",
    vdl_siautreprecisez1: "Si autre précisez",
    vdl_feudartificedomestique: "Feu d'artifice domestique",
    vdl_feudartificeagrandeploiement: "Feu d'artifice à grand déploiement (artificiers certifiés requis)",
    vdl_effetspeciauxpyrotechniques: "Effet spéciaux pyrotechniques",
    vdl_precisez: "Précisez",
    vdl_effetsspeciaux: "Effets spéciaux - autre",
    vdl_precisez1: "Précisez",
    vdl_cascade: "Cascade",
    vdl_simulationdevol: "Simulation de vol",
    vdl_simulationdebagarre: "Simulation de bagarre",
    vdl_simulationdenlevement: "Simulation d'enlèvement",
    vdl_autre3: "Autre",
    vdl_siautreprecisez2: "Si autre précisez",
    vdl_generatrice: "Génératrice",
    vdl_appareildecuison: "Appareil de cuison",
    vdl_barbecue: "Barbecue",
    vdl_chauffagedappoint: "Chauffage d'appoint",
    vdl_autre4: "Autre",
    vdl_siautreprecisez3: "Si autre précisez",
    vdl_casernemobile: "Caserne mobile",
    vdl_gazpropane: "Gaz propane",
    vdl_essence: "Essence",
    vdl_charbon: "Charbon",
    vdl_autre5: "Si autre précisez",
    vdl_interieur: "Intérieur",
    vdl_exterieur: "Extérieur",
    vdl_heurededebutdutilisationdusite: "Heure de début d'utilisation du site",
    vdl_heuredefindutilisationdusite: "Heure de fin d'utilisation du site",
    vdl_nombredeparticipant: "Nombre de participant",
    vdl_adolescents1317ans: "Adolescents (13-17 ans)",
    vdl_utilisationdappareildetypeaviation: "Utilisation d'appareil de type aviation",
    vdl_precisez2: "Précisez",
    vdl_typedactivite: "",
    vdl_survolabasealtitude: "Survol à basse altitude - atterrissages/décollages - Hélicoptères - utilisation d'un drone",
    vdl_attentionparticuliereduservicedepolice: "Attention particulière du service de police",
    vdl_securite500persbenevolesagentsdesecurite: "Sécurité (500 participants et plus)",
    vdl_kiosquedepreventionpolice: "Kiosque de prévention Police",
    vdl_kiosquedepreventionincendies: "Kiosque de prévention Incendies",
    vdl_feuacielouvert: "Feu à ciel ouvert",
    vdl_artistedefeu: "Artiste de feu",
    vdl_accessoireavecfeu: "Accessoire avec feu",
    vdl_utilisationdepiecespyrotechniqueseteffetsspe: "Utilisation de pièces pyrotechniques et effets spéciaux",
    vdl_utilisationdunappareilacombustion: "Utilisation d'un appareil à combustion",
    vdl_activitedenuitsurunsitemunicipal: "Activité de nuit sur un site municipal",
    vdl_autre1: "Autre"
  };

  const permitDisplayNames: DisplayNames<DemandPermitDetailsRow> = {
    vdl_adultes: "Adultes",
    vdl_autre: "Autre",
    vdl_precisez1: "Précisez",
    vdl_precisez2: "Précisez",
    vdl_permisid: "",
    vdl_enfant: "Enfants",
    vdl_autres1: "Autre(s)",
    vdl_siautreprecisez: "Si autre(s), précisez",
    vdl_revenusdetarificationremisa: "Revenus de tarification remis à",
    vdl_utilisationdunsystemedesonorisationamplifie: "Utilisation d'un système de sonorisation amplifié",
    vdl_autreappareilsusceptibledenuireauvoisinage: "Autre appareil susceptible de nuire au voisinage",
    vdl_precisez8: "Précisez",
    vdl_precisez7: "Précisez",
    vdl_panneauxdesignalisation: "Panneau(x) de signalisation",
    vdl_precisezdimensionetmateriaux: "Précisez dimension et matériaux",
    vdl_bannieres: "Bannière(s)",
    vdl_precisezdimensionetsupport: "Précisez dimensions et support",
    vdl_fanions: "Fanion(s)",
    vdl_autres2: "Autre(s)",
    vdl_chapiteaumarquise: "Chapiteau-marquise",
    vdl_preciseznombreetdimension: "Précisez nombre et dimension",
    vdl_structuresgonflables: "Structure(s) gonflable(s)",
    vdl_tyrolienne: "Tyrolienne",
    vdl_scenemobile: "Scène mobile",
    vdl_autres3: "Autre(s)",
    vdl_equipementconformeauxnormesetreglements: "Équipement conforme aux normes et règlements",
    vdl_installateuretoperateursontqualifies: "Installateur et opérateur sont qualifiés",
    vdl_nomdufournisseur: "Nom du fournisseur",
    vdl_requerantassurelaresponsabilitecivile: "Requérant assure la responsabilité civile",
    vdl_venteestgereeparledemandeur1: "Vente est gérée par le demandeur",
    vdl_ventegereeparuntiers1: "Vente gérée par un tiers",
    vdl_precisez4: "Précisez",
    vdl_profitsdelaventeremis1: "Profits de la vente remis à",
    vdl_venteestgereeparledemandeur2: "Vente est gérée par le demandeur",
    vdl_ventegereeparuntiers2: "Vente gérée par un tiers",
    vdl_precisez6: "Précisez",
    vdl_nombredepointsdeventesurlesite1: "Nombre de points de vente sur le site",
    vdl_profitsdelaventeremis2: "Profits de la vente remis à",
    vdl_venteestgereeparledemandeur3: "Vente est gérée par le demandeur",
    vdl_ventegereeparuntiers3: "Vente gérée par un tiers",
    vdl_precisez5: "Précisez",
    vdl_nombredepointsdeventesurlesite2: "Nombre de points de vente sur le site",
    vdl_profitsdelaventeremisa3: "Profits de la vente remis à",
    vdl_collectedebouteille: "Collecte de bouteille",
    vdl_ventedecalendrier: "Vente de calendrier",
    vdl_precisez3: "Précisez",
    vdl_revenusdelactiviteremisa: "Revenus de l'activité remis à",
    vdl_typedactivite: "",
    vdl_tarificationdaccesaunsitemunicipal: "",
    vdl_diffusionsonore: "",
    vdl_eclairage: "",
    vdl_utilisationdunsystemdeclairagenuisance: "Utilisation d'un système d'éclairage pouvant représenter une nuisance pour le voisinage",
    vdl_affichagetemporairesignalisationpavoisement: "",
    vdl_installationdestructuretemporaire: "",
    vdl_consommationdeboissonsalcooliques: "",
    vdl_ventedeboissonsalcooliques: "",
    vdl_ventealimentsetboissonsexcomptoirkiosque: "",
    vdl_ventedeproduitsservicesobjetsexpovente: "",
    vdl_sollicitationporteaporte: "",
    vdl_camionrestaurant: "",
  }

  const ecoSupportDisplayNames: DisplayNames<DemandEcoSupportDetailsRow> =
  {
    vdl_soutientecoresponsableid: "",
    vdl_duobacsroulants240ldechetrecyclage: "Duo bacs roulants 240 L (déchet / recyclage)",
    vdl_qte2: "Qté",
    vdl_duobarils205ldechetsrecyclage: "Duo barils 205 L (déchets / recyclage)",
    vdl_qte4: "Qté",
    vdl_bacroulantbrun240lcompost: "Bac roulant brun 240 L (compost)",
    vdl_qte5: "Qté",
    vdl_bacslimjimnoir90lavecsacsdechet: "Bac « Slim Jim » noir 90 L avec sacs (déchet)",
    vdl_qte6: "Qté",
    vdl_bacslimjimbleu90lavecsacsrecyclage: "Bac « Slim Jim » bleu 90 L avec sacs (recyclage)",
    vdl_qte: "Qté",
    vdl_bacslimjimbrun90lavecsacscompost: "Bac « Slim Jim » brun 90 L avec sacs (compost)",
    vdl_qte1: "Qté",
    vdl_supportaveloavecchainesetcadenas: "Support à vélo avec chaînes et cadenas",
    vdl_addressedelivraison: "Addresse de livraison",
    vdl_datedelivraison: "Date de livraison",
    vdl_datederecupperation: "Date de récupération",
    vdl_kiosqueenvironnementecocitoyennete: "Kiosque environnement/écocitoyenneté",
    vdl_servicedeplanificationetdaccompagnement: "Service de planification et d'accompagnement"
  };

const specialEventDisplayNames: DisplayNames<
  Omit<DemandSpecialEventDetailsRow, keyof DemandSpecialEventsGeneralRow>
> = {
  vdl_nomdelevenement: "Nom de l'évènement",
  vdl_naturedelevenement: "Nature de l'évènement",
  vdl_siautreprecisez: "Si autre précisez nature",
  vdl_descriptiondelevenement: "Description de l'évènement",
  vdl_rayonnementdelevenement: "Rayonnement de l'évènement",
  vdl_nouvelledemande: "Nouvelle demande",
  vdl_demanderecurrente: "Demande récurrente",
  vdl_sireccurentdatedeladerniereedition: "Si récurrent, date de la dernière édition",
  vdl_nombredeparticipantsetdevisiteursattendus: "Nombre de participants et de visiteurs attendus",
  vdl_clienteleviseeparlevenement: "Clientèle visée par l'évènement",
  vdl_sispecifiqueprecisez: "Si spécifique, précisez",
  vdl_engagementloisetreglement: "Engagement lois et règlement",
  vdl_engagementequiteetinclusion: "Engagement équité et inclusion",
  vdl_engagementdeveloppementdurable: "Engagement développement durable",
  vdl_responsabilitedumaterielprete: "Responsabilité du matériel prêté",
  vdl_engagementhabitudessaines: "Engagement habitudes saines"
};

const filmingDisplayNames: DisplayNames<
  Omit<DemandFilmingDetailsRow, keyof DemandSpecialEventsGeneralRow>
> = {
  vdl_titredelaproduction: "Titre de la production",
  vdl_naturedutournage: "Nature du tournage",
  vdl_tournageetudiant: "Tournage étudiant",
  vdl_resumeduscenarioenquelquesmots: "Résumé du scénario en quelques mots",
  vdl_nombredevehiculesdeproductionetparticuliers: "Nombre de véhicules de production et particuliers",
  vdl_cubes: "Cube(s)",
  vdl_roulottes: "Roulotte(s)",
  vdl_voituresparticuliers: "Voitures particuliers",
  vdl_autresprecisez: "Autres (précisez)",
  vdl_generatricerequise: "Génératrice requise",
  vdl_descriptiondesequipementsaetreinstalles: "Description des équipements à être installés",
  vdl_cascadesoueffetsspeciaux: "Cascades ou effets spéciaux",
  vdl_descriptiondetailleedescascadeseffetsspeciaux: "Description détaillée des cascades/effets spéciaux",
  vdl_engagementloisetreglement: "Engagement lois et règlement",
  vdl_respectdesconditionsdusoutienmunicipal: "Respect des conditions du soutien municipal",
  vdl_envoidesdocuments2semmaximumavantletournage: "Envoi des documents 2 sem maximum avant le tournage",
  vdl_engagementapayerlafacture: "Engagement à payer la facture",
  vdl_sengageafournirlesjustificatifssurdemande: "S'engage à fournir les justificatifs sur demande"
};

const eventSupportDisplayNames: DisplayNames<DemandEventSupportDetailsRow> =
  {
    vdl_soutienevenementid: "",
    vdl_amenagementelectriquetemporaire: "Aménagement électrique temporaire",
    vdl_typedappareil: "Type d'appareil",
    vdl_nombredeprise: "Nombre de prise",
    vdl_intensiteetvoltage: "Intensité et voltage",
    vdl_typedappareil2: "Type d'appareil",
    vdl_nombredeprise2: "Nombre de prise",
    vdl_intensiteetvoltage2: "Intensité et voltage",
    vdl_typedappareil3: "Type d'appareil",
    vdl_nombredeprise3: "Nombre de prise",
    vdl_intensiteetvoltage3: "Intensité et voltage",
    vdl_descriptionadditionneldamenagementelectrique: "Description additionnel d'aménagement électrique"
  };

  const specialEventsDateDisplayNames: DisplayNames<DemandSpecialEventsDateBase> =
  {
    vdl_description: "Description",
    vdl_dateetheurededebut: "Date et heure de début",
    vdl_dateetheuredefin: "Date et heure de fin"
  };
  
export {
  demandDisplayNames,
  infoRequestDisplayNames,
  standardEquipmentDisplayNames,
  fundraiserDisplayNames,
  electronicPanelsDisplayNames,
  activitiesCalendarDisplayNames,
  televisionDisplayDisplayNames,
  websiteDisplayDisplayNames,
  prismeDisplayNames,
  admissibilityDisplayNames,
  publicSpaceDisplayNames,
  publicSpaceReservationDisplayNames,
  specialEventsGeneralDisplayNames,
  publicRoadDisplayNames,
  securityPreventionDisplayNames,
  permitDisplayNames,
  ecoSupportDisplayNames,
  specialEventDisplayNames, 
  filmingDisplayNames,
  eventSupportDisplayNames,
  specialEventsDateDisplayNames
};
