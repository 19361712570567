import { Grid, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ContactRow } from "../data/Contact/models";
import { getAccount } from "../data/Account/requests";
import useUser from "../hooks/useUserData";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import PrivateComponent from "../components/common/auth/PrivateComponent";
import { useNavigate } from "react-router-dom";
import { RouterRoutes, routes } from "../routes/routes";
import ContactCard from "../components/pageSpecific/Contacts/ContactCard/ContactCard";
import { sendInvite } from "../data/Contact/requests";
import { useSnackbar } from "../hooks/useSnackbar";
import ErrorMessage from "../components/common/error/ErrorMessage";
import CustomLinkButton from "../components/common/buttons/CustomLinkButton";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";
import ContactCardSkeleton from "../components/pageSpecific/Contacts/ContactCard/ContactCardSkeleton";

const Contacts = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [contacts, setContacts] = useState<ContactRow[]>();
  const [error, setError] = useState<string>();

  const { userData } = useUser();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    const querry = async () => {
      const { data, error } = await getAccount(userData.parentcustomerid.Id);
      if (data) {
        setContacts(data.contacts);
      }
      setIsLoading(false);
    };
    querry();
  }, []);

  const handleSendInvite = async (contactid: string) => {
    setIsLoading(true);
    const result = await sendInvite(contactid);
    if (result.status === "success") {
      showSnackbar("Invitation envoyée", "success");
    } else {
      showSnackbar("Erreur dans l'inivation", "error");
    }
    setIsLoading(false);
  };

  const handleSuspend = async (contactid: string) => {
    const result = await sendInvite(contactid);
    if (result.status === "success") {
      showSnackbar("Invitation envoyée", "success");
    } else {
      showSnackbar("Erreur dans l'inivation", "error");
    }
  };

  if (isLoading) return <ContactsPageLoading />;

  if (error || !contacts) return <ErrorMessage message={error} />;

  return (
    <PageTitleLayout
      title="Contacts"
      sideTitleComponent={
        <PrivateComponent roleMinimum={routes.newContact.minimumRole}>
          <CustomLinkButton type="submit" primary to={routes.newContact.path}>
            Nouveau contact
          </CustomLinkButton>
        </PrivateComponent>
      }
    >
      <BoxResponsivePadding>
        {contacts.map((contact) => {
          if (contact.contactid !== userData.contactid) {
            return (
              <ContactCard
                key={contact.contactid}
                handleSendInvite={handleSendInvite}
                handleSuspend={handleSuspend}
                contact={contact}
              />
            );
          }
        })}
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};

export default Contacts;

const ContactsPageLoading = () => {
  return (
    <PageTitleLayout isLoading title="Contacts">
      <BoxResponsivePadding>
        <Grid container gap={2}>
          <Grid item xs={12} xl>
            {Array(2)
              .fill(0)
              .map((i, idx) => (
                <ContactCardSkeleton icon key={idx} />
              ))}
          </Grid>
        </Grid>
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};
