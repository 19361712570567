import React, { useEffect, useState } from "react";
import SimpleFormWithSections from "../components/form/SimpleForm/SimpleFormWithSections";
import { Box } from "@mui/material";
import SectionLoading from "../components/common/loading/SectionLoading";
import {  DropDownChoiceWithLabel } from "../types";
import useUser from "../hooks/useUserData";
import { useSnackbar } from "../hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import { RouterRoutes } from "../routes/routes";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import {
  editProfileForm,
} from "../data/Contact/forms/editProfile";
import { AccountPatch } from "../data/Account/models";
import { updateAccount } from "../data/Account/requests";
import { getAccount } from "../data/Account/requests";
import {
  editAccountForm,
  EditAccountFields,
} from "../data/Account/forms/editAccount";
import { getAccounTypes } from "../data/AccountType/requests";
import ErrorMessage from "../components/common/error/ErrorMessage";

const EditAccount = () => {
  const { userData, updateUser } = useUser();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [error, setError] = useState<string>();
  const { showSnackbar } = useSnackbar();
  const [fields, setFields] = useState<EditAccountFields>(
    editAccountForm.fields
  );

  useEffect(() => {
    const fetchAccountData = async () => {
      try {        
        const { data, error } = await getAccount(userData.parentcustomerid.Id);

        if (error) {
          setError("Error fetching account data");
          setIsLoading(false);
          return;
        }
        
        let _fields = { ...fields };
        _fields.name.value = data?.name || "";
        _fields.vdl_autresnomorganisme.value =
          data?.vdl_autresnomorganisme || "";
        _fields.telephone1.value = data?.telephone1 || "";
        _fields.websiteurl.value = data?.websiteurl || "";
        _fields.vdl_pagefacebook.value = data?.vdl_pagefacebook || "";
        _fields.emailaddress1.value = data?.emailaddress1 || "";
        _fields.vdl_secteursiegesocial.value =
          data?.vdl_secteursiegesocial || "";
        _fields.address1_line1.value = data?.address1_line1 || "";
        _fields.address1_line2.value = data?.address1_line2 || "";
        _fields.address1_city.value = data?.address1_city || "";
        _fields.address1_postalcode.value = data?.address1_postalcode || "";
        _fields.vdl_adresseidentique.value = data?.vdl_adresseidentique ? data?.vdl_adresseidentique : false;
        _fields.address2_line1.value = data?.address2_line1 || "";
        _fields.address2_line2.value = data?.address2_line2 || "";
        _fields.address2_city.value = data?.address2_city || "";
        _fields.address2_postalcode.value = data?.address2_postalcode || "";
        //_fields.communicationConsent.value = data?.communicationconsent || '';

        setFields(_fields);
      } catch (err) {
        setError("An error occurred while fetching account data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchAccountData();
  }, []);

  const handleSuccess = async (fields: EditAccountFields) => {
    const account: AccountPatch = {
      name: fields.name.value,
      websiteurl: fields.websiteurl.value,
      emailaddress1: fields.emailaddress1.value,
      telephone1: fields.telephone1.value,
      address1_city: fields.address1_city.value,
      address1_line1: fields.address1_line1.value,
      address1_line2: fields.address1_line2.value,
      address1_postalcode: fields.address1_postalcode.value,
      address2_city: fields.address2_city.value,
      address2_line1: fields.address2_line1.value,
      address2_line2: fields.address2_line2.value,
      address2_postalcode: fields.address2_postalcode.value,
      vdl_adresseidentique: fields.vdl_adresseidentique.value,
      vdl_secteursiegesocial: fields.vdl_secteursiegesocial.value,
      vdl_autresnomorganisme: fields.vdl_autresnomorganisme.value,
      vdl_pagefacebook: fields.vdl_pagefacebook.value,
    };
    const { status, error } = await updateAccount(
      userData.parentcustomerid.Id,
      account
    );
    if (status === "success") {
      updateUser();
      showSnackbar("Organisation modifiée", "success");
      navigate(`/${RouterRoutes.Account}`);
    } else {
      showSnackbar(
        "Erreur dans la modification de l'organisation. Réessayez ou contactez le support.",
        "error"
      );
    }
  };

  if (isLoading) return <SectionLoading />;
  if (error) return <ErrorMessage message={error}/>;
  return (
    <PageTitleLayout title="Modifier mon organisation">
      <Box>
        <SimpleFormWithSections
          maxWidth={900}
          fields={fields}
          handleSuccess={handleSuccess}
          submitText={"Enregistrer"}
          sections={editAccountForm.sections}
          columns={{ minWidth: 220, count: 2 }}
          removeFormPadding
        />
      </Box>
    </PageTitleLayout>
  );
};

export default EditAccount;
