import React, { useState } from "react";
import BoxResponsivePadding from "../../../common/boxes/BoxResponsivePadding";
import {
  DemandWebsiteDisplayPatch,
  DemandWebsiteDisplayRow,
} from "../../../../data/Demand/models";
import { NewDemandFields } from "../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../data/Demand/requests";
import EditDemandLayout from "../EditDemandLayout";
import {
  EditDemandWebsiteDisplayFields,
  editDemandWebsiteDisplayFields,
} from "../../../../data/Demand/forms/websiteDisplay";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import { fileToBase64 } from "../../../../data/common/fileToBase64";
import { dynamicsFileToFileType } from "../../../../data/common/buildFile";
import useTabs from "../../../../hooks/useTabs";
import {buildForm} from "../../../form/SimpleForm/buildForm";

type Props = {
  demand: DemandWebsiteDisplayRow;
  isNewDemand: boolean;
  fieldsGeneral: NewDemandFields;
  onUpdateSuccess: () => void;
  onUpdateFailure: (error?: string) => void;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const WebsiteDisplay = ({
  demand,
  isNewDemand,
  fieldsGeneral,
  setFieldsGeneral,
  onUpdateSuccess,
  onUpdateFailure,
  setError,
}: Props) => {
  const details = demand.vdl_detailsdelademande;
  const [fields, setFields] = useState<EditDemandWebsiteDisplayFields>(
    buildForm(editDemandWebsiteDisplayFields, {
      vdl_lieninternetdelapageconcernee:
        details.vdl_lieninternetdelapageconcernee ?? "",
      vdl_piecejointe: details.vdl_piecejointe
        ? dynamicsFileToFileType(details.vdl_piecejointe)
        : undefined,
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      return;
    }

    const _demand: DemandWebsiteDisplayPatch = {
      title: fieldsGeneral.title.value,
      vdl_contactprincipal: fieldsGeneral.vdl_contactprincipal.value,
      type: demand.type,
      vdl_detailsdelademande: {
        vdl_lieninternetdelapageconcernee:
          fields.vdl_lieninternetdelapageconcernee.value,
      },
    };
    if (fields.vdl_piecejointe.changed) {
      if (fields.vdl_piecejointe.value) {
        const pieceJointeToBase = await fileToBase64(
          fields.vdl_piecejointe.value
        );
        if (pieceJointeToBase) {
          _demand.vdl_detailsdelademande.vdl_piecejointe = {
            filename: fields.vdl_piecejointe.value.name,
            content: pieceJointeToBase,
          };
        }
      }
    }

    if (demand.statuscode === "Inutilisée") {
      _demand.vdl_detailsdelademande.statuscode = "En création";
    }

    const { status, error } = await updateDemand(demand.incidentid, _demand);
    if (status === "success") {
      onUpdateSuccess();
    } else {
      onUpdateFailure(error);
    }
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_lienhautduformulaireportail}
    >
      <BoxResponsivePadding bgcolor="common.white">
        <SimpleFields fields={fields} setFields={setFields} />
      </BoxResponsivePadding>
    </EditDemandLayout>
  );
};

export default WebsiteDisplay;
