import React, { useEffect, useState } from "react";
import { FormFields, DropDownChoice, DropDownChoiceWithLabel } from "../types";
import SimpleFormWithSections from "../components/form/SimpleForm/SimpleFormWithSections";
import { NewContactFields, newContactForm } from "../data/Contact/forms/new";
import {  Box } from "@mui/material";
import { createContact } from "../data/Contact/requests";
import useUser from "../hooks/useUserData";
import { useNavigate } from "react-router-dom";
import { RouterRoutes } from "../routes/routes";
import { useSnackbar } from "../hooks/useSnackbar";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import { ContactPost } from "../data/Contact/models";
import { getRoles } from "../data/Role/requests";
import ErrorMessage from "../components/common/error/ErrorMessage";

const NewContact = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [rolesChoices, setRolesChoices] = useState<DropDownChoiceWithLabel[]>(
    []
  );
  const [formFields, setFormFields] = useState<NewContactFields>(
    newContactForm.fields
  );
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { userData, userHasRole } = useUser();

  useEffect(() => {
    const updateRolesChoices = async () => {
      const { status, data, error } = await getRoles();
      if (status === "success" && data) {
        setRolesChoices(data);
      } else {
        setRolesChoices([]);
        setError(error);
      }
      setIsLoading(false);
    };

    updateRolesChoices();
  }, []);

  const handleSuccess = async (fields: NewContactFields) => {
    let _contact: ContactPost = {
      firstname: fields.firstname.value,
      lastname: fields.lastname.value,
      emailaddress1: fields.emailaddress1.value,
      telephone1: fields.telephone1.value,
      parentcustomerid: userData.parentcustomerid.Id,
      vdl_fonction: fields.vdl_fonction.value ? fields.vdl_fonction.value : "",
      vdl_salutation: fields.vdl_salutation.value,
    };
    if (userHasRole("Administrateur")) {
      _contact.vdl_accesauportail = fields.vdl_accesauportail.value === "Oui";
      if (fields.vdl_roledesecurite.value) {
        _contact.vdl_roledesecurite = fields.vdl_roledesecurite.value;
      }
    }
    const { data, error } = await createContact(_contact);
    if (data && !error) {
      showSnackbar("Utilisateur ajouté", "success");
      navigate(-1);  
    } else {
      showSnackbar(
        "Erreur dans l'ajout de l'utilisateur. Réessayez ou contactez le support.",
        "error"
      );
    }
  };

  function roleDisabled(fields: FormFields): boolean {
    try {
      return !(
        fields.vdl_accesauportail.value === "Oui" &&
        userHasRole("Administrateur")
      );
    } catch (e) {
      return false;
    }
  }

  if (isLoading)
    return (
      <PageTitleLayout
        title="Nouveau contact"
        loadingSpinner={isLoading}/>
    );
  if (error) return <ErrorMessage message={error} />;
  return (
    <PageTitleLayout title="Nouveau contact">
      <Box>
        <SimpleFormWithSections
          maxWidth={900}
          fields={{
            ...newContactForm.fields,
            vdl_roledesecurite: {
              ...newContactForm.fields.vdl_roledesecurite,
              choices: rolesChoices,
              disabledCondition: roleDisabled,
            },
            vdl_accesauportail: {
              ...newContactForm.fields.vdl_accesauportail,
              disabledCondition: () => !userHasRole("Administrateur"),
            },
          }}
          sections={newContactForm.sections}
          handleSuccess={handleSuccess}
          submitText={"Créer"}
          columns={{ minWidth: 220, count: 2 }}
          removeFormPadding
        />
      </Box>
    </PageTitleLayout>
  );
};

export default NewContact;
