import { Box, Typography, styled } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { DemandRegroupmentRow } from "../../../../data/Demand/types/specialEvents/specialEventsGeneral";
import CustomChip from "../../../common/misc/CustomChip";
import LinkIcon from "../../../common/buttons/icons/LinkIcon";
import { Add, Block, Edit, Info } from "@mui/icons-material";
import { RouterRoutes } from "../../../../routes/routes";

const CardBox = styled(Box)(({ theme }) => ({
  display: "grid",
  rowGap: "8px",
  columnGap: "16px",
  height: "100%",
  alignItems: "center",
  gridTemplateColumns: "1fr",
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "4fr 3fr 6.5rem",
    gridTemplateRows: "auto auto auto",
    gridAutoFlow: "column",
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "3fr 2fr 1fr 6.5rem",
    gridTemplateRows: "auto auto",
    rowGap: "0",
  },
  [theme.breakpoints.up("xl")]: {
    gridTemplateColumns: "4fr 3fr 1fr 6.5rem",
  },
}));

type Props = {
  demand: DemandRegroupmentRow;
};

const UnusedDemandCard = ({ demand }: Props) => {
  return (
    <Box bgcolor="common.white" padding="16px" borderRadius="4px">
      <CardBox color="text.disabled">
        <Typography
          variant="h6"
          fontWeight={600}
          color="primary.light"
          lineHeight={1.4}
          sx={{
            opacity: "0.8",
            gridColumn: {
              sm: "span 3",
              lg: "span 1",
            },
          }}
        >
          {demand.title}
        </Typography>
        <Typography
          fontSize={"0.9rem"}
          fontWeight="600"
          display="inline"
          component="span"
        >
          {demand.ticketnumber}
        </Typography>
        <Typography
          sx={{
            gridRow: {
              lg: "span 2",
            },
          }}
        >
          <Typography fontSize={"0.9rem"} display="inline" component="span">
            Type:{" "}
          </Typography>
          <Typography
            fontSize={"0.9rem"}
            display="inline"
            component="span"
            fontWeight="600"
          >
            {demand.vdl_typededemande.Name}
          </Typography>
        </Typography>
        <Box
          sx={{
            gridRow: {
              xs: "2",
              sm: "span 2",
              lg: "span 2",
            },
          }}
        >
          <CustomChip text={demand.statuscode} color="grey" />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          sx={{
            justifyContent: {
              sm: "end",
            },
            gridRow: {
              sm: "span 2",
              lg: "span 2",
            },
          }}
        >
          <LinkIcon
            backgroundColor="background.default"
            icon={<Add />}
            tooltip="Compléter cette demande"
            to={`${RouterRoutes.Demands}/${demand.incidentid}/${RouterRoutes.Edit}?tab=1&redirect=parent`}
          />
        </Box>
      </CardBox>
      <Box
        display="flex"
        alignItems="center"
        fontSize="0.8rem"
        gap="8px"
        color="text.disabled"
        mt="0.6rem"
      >
        <Info color="inherit" fontSize="small" />
        <Typography color="inherit" fontSize="inherit">
          Cette demande n'est pas utilisée, compléter ces
          informations pour l'ajouter.
        </Typography>
      </Box>
    </Box>
  );
};

export default UnusedDemandCard;
