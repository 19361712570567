import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

const NotFound = () => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column', 
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
        }}>
            <div>
                <h1>404 - Cette page n'existe pas</h1>
            </div>
            <div>
                <Link to="/">Retour à l'accueil</Link>
            </div>
        </Box>
    );
}

export default NotFound;
