import React, { useEffect, useState } from "react";
import SimpleFormWithSections from "../components/form/SimpleForm/SimpleFormWithSections";
import { Box } from "@mui/material";
import SectionLoading from "../components/common/loading/SectionLoading";
import { FormFields, QueryError } from "../types";
import useUser from "../hooks/useUserData";
import { updateContact } from "../data/Contact/requests";
import { useSnackbar } from "../hooks/useSnackbar";
import { useNavigate, useParams } from "react-router-dom";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import { editContactForm, EditContactFields } from "../data/Contact/forms/edit";
import { ContactPatch, ContactRow } from "../data/Contact/models";
import { getRoles } from "../data/Role/requests";
import ErrorMessage from "../components/common/error/ErrorMessage";
import { buildFormObj } from "../components/form/SimpleForm/buildForm";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { GetResponse } from "../data/common/types";
import { getContact } from "../data/Contact/queries";
import QueryErrorMessage from "../components/common/error/QueryErrorMessage";

const EditContact = () => {
  const { email } = useParams();

  const { data, error, isLoading, isPlaceholderData, isFetching } = useQuery<
    ContactRow,
    QueryError
  >({
    queryKey: ["Contact", email],
    queryFn: () => getContact(email),
    refetchOnMount: true,
    placeholderData: undefined,
  });

  const [fields, setFields] = useState<EditContactFields>(
    editContactForm.fields
  );
  const [contactId, setContactId] = useState<string | undefined>();

  const { userHasRole } = useUser();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  function roleDisabled(fields: FormFields): boolean {
    try {
      return !(
        fields.vdl_accesauportail.value === "Oui" &&
        userHasRole("Administrateur")
      );
    } catch (e) {
      return false;
    }
  }
  const handleSuccess = async (fields: FormFields) => {
    let _contact: ContactPatch = {
      firstname: fields.firstname.value,
      lastname: fields.lastname.value,
      telephone1: fields.telephone1.value,
      telephone2: fields.telephone2.value,
      vdl_salutation: fields.vdl_salutation.value,
      vdl_fonction: fields.vdl_fonction.value,
      vdl_accesauportail: fields.vdl_accesauportail.value === "Oui",
    };
    if (userHasRole("Administrateur")) {
      _contact.vdl_accesauportail = fields.vdl_accesauportail.value === "Oui";
      if (fields.vdl_roledesecurite.value) {
        _contact.vdl_roledesecurite = fields.vdl_roledesecurite.value;
      }
    }
    if (!contactId) return;

    const { status, error } = await updateContact(contactId, _contact);
    if (status === "success") {
      showSnackbar("Contact modifié", "success");
      navigate(`/contacts/${email}`);
    } else {
      showSnackbar(
        "Erreur dans la modification du contact. Réessayez ou contactez le support.",
        "error"
      );
    }
  };

  if (isLoading || isFetching)
    return (
      <PageTitleLayout title="Modifier un contact">
        <SectionLoading />
      </PageTitleLayout>
    );
  if (error || !data) return <QueryErrorMessage error={error} />;
  return (
    <PageTitleLayout title="Modifier un contact">
      <Box>
        <SimpleFormWithSections
          maxWidth={900}
          fields={buildFormObj(editContactForm.fields, {
            vdl_salutation: { value: data.vdl_salutation },
            firstname: { value: data.firstname },
            lastname: { value: data.lastname },
            vdl_fonction: { value: data.vdl_fonction },
            telephone1: { value: data.telephone1 },
            telephone2: { value: data.telephone2 ?? "" },
            vdl_accesauportail: {
              value: data.vdl_accesauportail ? "Oui" : "Non",
            },
            vdl_roledesecurite: {
              value: data.vdl_roledesecurite
                ? data.vdl_roledesecurite.Name
                : undefined,
            },
          })}
          handleSuccess={handleSuccess}
          submitText={"Enregistrer"}
          sections={editContactForm.sections}
          columns={{ minWidth: 220, count: 2 }}
        />
      </Box>
    </PageTitleLayout>
  );
};

export default EditContact;
