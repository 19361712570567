import { createContext, useEffect, useState } from "react";
import AppLoading from "../components/common/loading/AppLoading";
import { NewUser, User } from "../types";
import { useMsal } from "@azure/msal-react";
import { getContact } from "../data/Contact/requests";
import { Role } from "../types";
import ErrorMessage from "../components/common/error/ErrorMessage";
import ErrorMessageContactDisabled from "../components/common/error/ErrorMessageContactDisabled";

type Props = {
  children: React.ReactNode;
  noInfoComponent: React.ReactNode;
};

interface UserContext {
  userData: User;
  updateUser: () => Promise<void>;
  userHasRole: (role?: Role) => boolean;
}

interface UpdateUserContext {
  updateUser: () => Promise<void>;
}
export const UserContext = createContext<UserContext | undefined>(undefined);

export const UpdateUserContext = createContext<UpdateUserContext | undefined>(
  undefined
);

export function UserProvider({ noInfoComponent, children }: Props) {
  const { instance } = useMsal();
  const [userData, setUserData] = useState<User | NewUser | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(true);
  const updateUser = async () => {
    try {
      const msalAccount = instance.getActiveAccount();
      if (msalAccount) {
        const { status, data, error } = await getContact(msalAccount.username);

        if (status === "error") {
          setUserData(undefined);
          return;
        }
        if (!data) {
          setUserData({ newUser: true, msalInfo: msalAccount });
          return;
        }

        const userData: User = {
          newUser: false,
          msalInfo: msalAccount,
          emailaddress1: data.emailaddress1,
          fullname: `${data.firstname} ${data.lastname}`,
          firstname: data.firstname,
          lastname: data.lastname,
          contactid: data.contactid,
          telephone1: data.telephone1,
          parentcustomerid: data.parentcustomerid,
          vdl_fonction: data.vdl_fonction,
          telephone2: data.telephone2,
          vdl_roledesecurite: data.vdl_roledesecurite,
          vdl_consentement: data.vdl_consentement,
          vdl_salutation: data.vdl_salutation,
          vdl_accesauportail: data.vdl_accesauportail,
          modifiedon: data.modifiedon,
          createdon: data.createdon,
          statecode: data.statecode,
        };
        setUserData(userData);
      }
    } catch (error) {
      setUserData(undefined);
    } finally {
      setLoading(false);
    }
  };

  function userHasRole(role?: Role): boolean {
    if (!userData || userData.newUser) return false;
    if (role === undefined) return true;
    if (!userData.vdl_roledesecurite) return false;

    if (role === "Usager") return true;
    if (role === "Employé") {
      return (
        userData.vdl_roledesecurite.Name === "Employé" ||
        userData.vdl_roledesecurite.Name === "Administrateur"
      );
    }

    return userData.vdl_roledesecurite.Name === "Administrateur";
  }

  useEffect(() => {
    updateUser();
  }, []);

  if (loading) {
    return <AppLoading />;
  }
  if (!userData)
    return (
      <ErrorMessage message="Erreur dans la récupération de l'utilisateur. N'est pas un nouvel utiliasteur et aucun contact associé" />
    );
  if (userData.newUser) {
    return (
      <UpdateUserContext.Provider value={{ updateUser: updateUser }}>
        {noInfoComponent}
      </UpdateUserContext.Provider>
    );
  }
  if ((!userData.newUser && !userData.contactid) || userData.contactid === "") {
    return (
      <ErrorMessage message="Erreur dans l'authentification. Erreur dans la récupération du contact" />
    );
  }

  if (!userData.vdl_accesauportail || userData.statecode === "Inactif") {
    return <ErrorMessageContactDisabled />;
  }

  return (
    <UserContext.Provider
      value={{
        userData: userData,
        updateUser: updateUser,
        userHasRole: userHasRole,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
