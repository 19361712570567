import React, { useEffect, useState } from "react";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import { Box, Typography, useTheme } from "@mui/material";
import { getPrograms } from "../data/Program/requests";
import ProgramCard from "../components/pageSpecific/NewDemands/ProgramCard/ProgramCard";
import ProgramCardSkeletton from "../components/pageSpecific/NewDemands/ProgramCard/ProgramCardSkeletton";
import { getAccount } from "../data/Account/requests";
import useUser from "../hooks/useUserData";
import { AccountRow } from "../data/Account/models";
import NewDemandDialog from "../components/pageSpecific/NewDemands/ProgramCard/NewDemandDialog/NewDemandDialog";
import { ProgramRow } from "../data/Program/models";
import ErrorMessage from "../components/common/error/ErrorMessage";
import CustomButton from "../components/common/buttons/CustomButton";
import { ServiceRow } from "../data/Service/models";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";

const NewDemands = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [selectedType, setSelectedType] = useState<ServiceRow>();
  const [account, setAccount] = useState<AccountRow>();
  const [programs, setPrograms] = useState<ProgramRow[]>();
  const { userData } = useUser();

  useEffect(() => {
    const getData = async () => {
      const [account, programs] = await Promise.all([
        getAccount(userData.parentcustomerid.Id),
        getPrograms(),
      ]);

      if (!account.data || account.error) {
        setIsLoading(false);
        setError(account.error);
        return;
      }

      if (!programs.data || programs.error) {
        setIsLoading(false);
        setError(account.error);
        return;
      }

      setAccount(account.data);
      setPrograms(programs.data);
      setIsLoading(false);
    };
    getData();
  }, []);

  const handleTypeClick = (type: ServiceRow) => {
    if (selectedType !== type) setSelectedType(type);
    setDialogOpen(true);
  };

  if (isLoading) return <NewDemandsLoading />;
  if (error || !account || !programs) return <ErrorMessage message={error} />;

  const contactProgram = programs.find(
    (program) =>
      program.vdl_name === "Vous ne trouvez pas ce que vous cherchez ?"
  );

  return (
    <PageTitleLayout title="Nouvelle demande">
      <BoxResponsivePadding>
        {selectedType ? (
          <NewDemandDialog
            selectedType={selectedType}
            open={dialogOpen}
            setOpen={setDialogOpen}
            contacts={account.contacts}
          />
        ) : null}

        <Box display="grid" gap="16px">
          {programs.map((program) => {
            if (
              program.vdl_programmeid !== contactProgram?.vdl_programmeid &&
              program.services.length > 0
            ) {
              return (
                <ProgramCard
                  key={program.vdl_programmeid}
                  program={program}
                  handleTypeClick={handleTypeClick}
                />
              );
            }
          })}
          {contactProgram && contactProgram.services[0]? (
            <Box
              bgcolor="primary.main"
              padding="1rem"
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
              gap="16px"
            >
              <Typography color="common.white" variant="h6" fontSize="bold">
                {contactProgram.vdl_name}
              </Typography>
              <CustomButton
                onClick={() => handleTypeClick(contactProgram.services[0])}
                sx={{ color: "common.white", border: "1px solid white" }}
              >
                {contactProgram.services[0].vdl_name}
              </CustomButton>
            </Box>
          ) : null}
        </Box>
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};

export default NewDemands;

const NewDemandsLoading = () => {
  const theme = useTheme();
  return (
    <PageTitleLayout title={"Nouvelle demande"} isLoading>
      <Box
        sx={{
          ...theme.custom.pageCardContainer,
        }}
        display="grid"
        gap="16px"
      >
        {Array(4)
          .fill(0)
          .map((i, idx) => (
            <ProgramCardSkeletton key={idx} />
          ))}
      </Box>
    </PageTitleLayout>
  );
};
