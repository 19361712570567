import fetchWithMsal from "../fetchWithMsal";
import { ContactRow, ContactPatch, ContactPost } from "./models";
import { GetResponse, PatchResponse, PostResponse } from "../common/types";
import { ErrorType, QueryError } from "../../types";
import { errorHandler } from "../../components/common/error/errorHandler";

export const getContact = async (email?: string): Promise<ContactRow> => {
  try {
    if (!email)
      throw new QueryError(
        ErrorType.NOT_FOUND,
        "Aucun Email n'a été fourni dans l'URL, vérifiez l'URL.",
      );

    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_contact/${email}`;

    const result = await fetchWithMsal("GET", endpoint);

    if (result.StatusCode === 404)
      throw new QueryError(
        ErrorType.NOT_FOUND,
        "Aucun contact n'a été trouvé pour ce Email, vérifiez l'URL.",
      );

    if (!result.Value.Data) throw new Error("Erreur dans la requête");
    const data = result.Value.Data;
    const tempData: ContactRow = {
      contactid: data.contactid,
      firstname: data.firstname,
      lastname: data.lastname,
      fullname: `${data.firstname} ${data.lastname}`,
      emailaddress1: data.emailaddress1,
      telephone1: data.telephone1,
      vdl_consentement: data.vdl_consentement,
      parentcustomerid: {
        Name: data.parentcustomerid.Name,
        Id: data.parentcustomerid.Id,
      },
      vdl_fonction: data.vdl_fonction,
      vdl_salutation: data.vdl_salutation,
      telephone2: data.telephone2,
      vdl_roledesecurite: data.vdl_roledesecurite ?? null,
      vdl_accesauportail: data.vdl_accesauportail
        ? data.vdl_accesauportail
        : false,
      modifiedon: data.modifiedon,
      createdon: data.createdon,
      statecode: data.statecode,
    };
    return tempData;
  } catch (e) {
    throw QueryError.TransformUnknown(e, "Contact", "Get")
  }
};

export const createContact = async (
  contact: ContactPost
): Promise<PostResponse> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/create_contact`;
    const result = await fetchWithMsal("POST", endpoint, contact);

    if (result.StatusCode === 404)
      throw new QueryError(
        ErrorType.NOT_FOUND,
        "Aucun contact n'a été trouvé pour ce Email, vérifiez l'URL.",
      );

    if (result.StatusCode === 200) {
      return { status: "success", data: result.Value };
    } else {
      throw new Error("Erreur dans l'exécution");
    }
  } catch (e) {
    return errorHandler(e, "Erreur dans l'ajout du contact");
  }
};

export const updateContact = async (
  contactid: string,
  contact: ContactPatch
): Promise<void> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/update_contact/${contactid}`;
    const result = await fetchWithMsal("PATCH", endpoint, contact);

    if (result.StatusCode === 404)
      throw new QueryError(
        ErrorType.NOT_FOUND,
        "Aucun contact n'a été trouvé pour ce Email, vérifiez l'URL.",
        "Contact"
      );
    if (result.StatusCode !== 200) {
      throw "Erreur inconnue";
    }

  } catch (e) {
    throw QueryError.TransformUnknown(e, "Contact", "Update")
  }
};

export const sendInvite = async (contactid: string): Promise<PatchResponse> => {
  try {
    const data = {
      targetId: contactid,
    };
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/send_invite`;
    const result = await fetchWithMsal("POST", endpoint, data);
    if (result.StatusCode === 200) {
      return { status: "success" };
    } else {
      throw new Error("Erreur dans l'exécution");
    }
  } catch (e) {
    return errorHandler(e, "Erreur dans la modification du contact");
  }
};

/*
const setValue = <K extends keyof IContact>(key: K, value: IContact[K]) => {
      const _user: IContact = Object.assign({}, tempData);
      _user[key] = value;
      return _user;
    };
    */
