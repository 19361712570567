import { GetResponse, PatchResponse, PostResponse } from "../common/types";
import fetchWithMsal from "../fetchWithMsal";
import {
  DemandGeneralRow,
  DemandListRow,
  DemandPatch,
  DemandPost,
  DemandRegroupementRow,
  DemandRow,
  DemandType,
} from "./models";
import { PartialDemandWebsiteDisplayRow } from "./types/websiteDisplay";
import { PartialDemandActivitiesCalendarRow } from "./types/activitesCalendar";
import { PartialDemandElectronicPanelsRow } from "./types/electronicPanels";
import { PartialDemandFundraiserRow } from "./types/fundraiser";
import { PartialDemandInfoRequestRow } from "./types/infoRequest";
import { PartialDemandPrismeRow } from "./types/prisme";
import { PartialDemandStandardEquipmentRow } from "./types/standardEquipment";
import { PartialDemandTelevisionDisplayRow } from "./types/televisionDisplay";
import { PartialDemandAdmissibilityRow } from "./types/admissibility";
import { PartialDemandPublicSpaceRow } from "./types/publicSpace/publicSpace";
import { PartialDemandSpecialEventRow } from "./types/specialEvents/specialEvent/specialEvent";
import { PartialDemandPublicRoadRow } from "./types/publicRoad";
import { PartialDemandSecurityPreventionRow } from "./types/securityPrevention";
import { PartialDemandPermitRow } from "./types/permit";
import { PartialDemandEcoSupportRow } from "./types/ecoSupport";
import { PartialDemandFilmingRow } from "./types/specialEvents/filming/filming";
import { PartialDemandEventSupportRow } from "./types/eventSupport";
import { errorHandler } from "../../components/common/error/errorHandler";
import { promises } from "dns";

export const getDemand = async (
  id: string
): Promise<GetResponse<DemandRow>> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_incident/${id}`;
    const result = await fetchWithMsal("GET", endpoint);
    if (result.StatusCode === 404)
      return { status: "success", data: undefined };
    if (!result.Value) throw new Error("Réponse de la requête invalide");

    const data = result.Value.Data;

    const generalData: DemandGeneralRow = {
      title: data.title,
      incidentid: data.incidentid,
      vdl_contactprincipal: data.contactDetails
        ? {
            vdl_fonction: data.contactDetails.vdl_fonction,
            firstname: data.contactDetails.firstname,
            lastname: data.contactDetails.lastname,
            telephone1: data.contactDetails.telephone1,
            contactid: data.contactDetails.contactid,
          }
        : undefined,
      vdl_typededemande: {
        Id: data.vdl_typededemande.Id,
        Name: data.vdl_typededemande.Name,
      },
      ticketnumber: data.ticketnumber,
      statuscode: data.statuscode,
      modifiedon: data.modifiedon,
      createdon: data.createdon,
      vdl_lienhautduformulaireportail: data.lien_formulaire_portail,
      regroupement: data.regroupement
        ? {
            id: data.regroupement.id,
            name: data.regroupement.name,
            principalIncidentId: data.regroupement.principalIncidentId,
          }
        : undefined,
    };

    const demandData: DemandRow = {
      ...generalData,
      ...buildDemandDetail(data),
    };
    return { status: "success", data: demandData };
  } catch (e) {
    console.log(e);
    return errorHandler(e, "Erreur dans la récupération de la demande");
  }
};

export const getDemands = async (
  accountid: string
): Promise<GetResponse<DemandRegroupementRow[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_incidents/${accountid}`;
    const result = await fetchWithMsal("GET", endpoint);
    if (result.StatusCode === 404)
      return { status: "success", data: undefined };
    if (!result.Value) throw new Error("Erreur dans la requête");

    const data = result.Value.Data;

    const tempData: DemandRegroupementRow[] = [];

    if (data !== null) {
      data.forEach((demand: any) => {
        if (demand.regroupementName && demand.demandes) {
          tempData.push({
            type: "regroupement",
            regroupementName: demand.regroupementName,
            demandes: demand.demandes.map((demand: any) => ({
              title: demand.title,
              incidentid: demand.incidentid,
              ticketnumber: demand.ticketnumber,
              statuscode: demand.statuscode,
              modifiedon: demand.modifiedon,
              createdon: demand.createdon,
              vdl_contactprincipal: demand.vdl_contactprincipal
                ? {
                    value: demand.vdl_contactprincipal.Id,
                    label: demand.vdl_contactprincipal.Name,
                  }
                : undefined,
              vdl_notifications: demand.vdl_notifications ?? 0,
              vdl_typededemande: {
                value: demand.vdl_typededemande.Id,
                label: demand.vdl_typededemande.Name,
              },
            })),
          });
        } else {
          tempData.push({
            type: "demande",
            title: demand.title,
            incidentid: demand.incidentid,
            ticketnumber: demand.ticketnumber,
            statuscode: demand.statuscode,
            modifiedon: demand.modifiedon,
            createdon: demand.createdon,
            vdl_contactprincipal: demand.vdl_contactprincipal
              ? {
                  Id: demand.vdl_contactprincipal.Id,
                  Name: demand.vdl_contactprincipal.Name,
                }
              : undefined,
            vdl_notifications: demand.vdl_notifications ?? 0,
            vdl_typededemande: {
              Id: demand.vdl_typededemande.Id,
              Name: demand.vdl_typededemande.Name,
            },
          });
        }
      });
    }

    return { status: "success", data: tempData };
  } catch (e) {
    return errorHandler(e, "Erreur dans la récupération des demandes");
  }
};

export const createDemand = async (
  demand: DemandPost
): Promise<PostResponse> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/create_incident`;
    const result = await fetchWithMsal("POST", endpoint, demand);
    if (result.StatusCode === 200) {
      return { status: "success", data: result.Value };
    } else {
      throw new Error("Erreur dans l'exécution");
    }
  } catch (e) {
    return errorHandler(e, "Erreur dans l'ajout de la demande");
  }
};

export const updateDemand = async (
  demandid: string,
  demand: DemandPatch
): Promise<PatchResponse> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/update_incident/${demandid}`;
    var demandToUpdate;
    if ("type" in demand) {
      const { type, ...demandNoType } = { ...demand };
      demandToUpdate = demandNoType;
    } else {
      demandToUpdate = demand;
    }
    const result = await fetchWithMsal("PATCH", endpoint, demandToUpdate);
    if (result.StatusCode === 490) {
      return { status: "error", error: result.Value, statuscode: 490 };
    }
    if (result.StatusCode === 200) {
      return { status: "success" };
    } else {
      throw new Error("Erreur dans l'exécution");
    }
  } catch (e) {
    return errorHandler(e, "Erreur dans la modification de la demande");
  }
};

export const deleteDemand = async (
  demandid: string
): Promise<PatchResponse> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/delete_incident/${demandid}`;
    const result = await fetchWithMsal("POST", endpoint);
    if (result.StatusCode === 490) {
      return { status: "error", error: result.Value };
    }
    if (result.StatusCode === 200) {
      return { status: "success" };
    } else {
      throw new Error("Erreur dans l'exécution");
    }
  } catch (e) {
    return errorHandler(e, "Erreur dans la suppression de la demande");
  }
};

const buildDemandDetail = (demandData: any) => {
  const demandType: DemandType = demandData.vdl_typededemande.Name;
  const detailsData = demandData.vdl_detailsdelademandeid;

  switch (demandType) {
    case "Demande d'information":
      const demandInfoRequest: PartialDemandInfoRequestRow = {
        type: demandType,
        vdl_detailsdelademande: {
          vdl_demandeinformationid: detailsData.vdl_demandeinformationid,
          vdl_sujetdelademande: detailsData.vdl_sujetdelademande,
          vdl_name: detailsData.vdl_name,
          vdl_descriptiondelademande: detailsData.vdl_descriptiondelademande,
        },
      };

      return demandInfoRequest;

    case "Équipement spécialisé":
    case "Équipement standard":
      const demandMaterial: PartialDemandStandardEquipmentRow = {
        type: demandType,
        vdl_detailsdelademande: {
          vdl_equipementmunicipalid: detailsData.vdl_equipementmunicipalid,
          vdl_typedeproduit: {
            Id: detailsData.vdl_typedeproduit.Id,
            Name: detailsData.vdl_typedeproduit.Name,
          },
          vdl_adressecompletelivraison:
            detailsData.vdl_adressecompletelivraison,
          vdl_adressecompleterecuperation:
            detailsData.vdl_adressecompleterecuperation,
          vdl_identiquealadressedelivraison:
            detailsData.vdl_identiquealadressedelivraison,
          vdl_datedelivraison: detailsData.vdl_datedelivraison,
          vdl_horaire: detailsData.vdl_horaire,
          vdl_datederecuperation: detailsData.vdl_datederecuperation,
          vdl_horairerecuperation: detailsData.vdl_horairerecuperation,
          vdl_name: detailsData.vdl_name,
          vdl_secteurdeladressedelivraison:
            detailsData.vdl_secteurdeladressedelivraison,
          vdl_secteurdeladressederecuperation:
            detailsData.vdl_secteurdeladressederecuperation,
          vdl_remarqueoucommentaire: detailsData.vdl_remarqueoucommentaire,
          vdl_remarqueoucommentairerecuperation:
            detailsData.vdl_remarqueoucommentairerecuperation,
          vdl_nomdubatimentlivraison: detailsData.vdl_nomdubatimentlivraison,
          vdl_nomdubatimentrecuperation:
            detailsData.vdl_nomdubatimentrecuperation,

          products: [],
        },
      };

      if (detailsData.products) {
        detailsData.products.forEach((product: any) => {
          demandMaterial.vdl_detailsdelademande.products.push({
            description: product.description,
            vdl_produit: product.productid,
            vdl_quantite: product.vdl_quantite,
            vdl_photo: product.vdl_photo,
            vdl_deserie: product.vdl_deserie,
            name: product.name,
          });
        });
      }

      return demandMaterial;

    case "Collecte d'argent":
      const demandFundraiser: PartialDemandFundraiserRow = {
        type: demandType,
        vdl_detailsdelademande: {
          vdl_collectedargentid: detailsData.vdl_collectedargentid,
          vdl_erjour: detailsData.vdl_erjour,
          vdl_emejour: detailsData.vdl_emejour,
          vdl_3emejour: detailsData.vdl_3emejour,
          vdl_4emejour: detailsData.vdl_4emejour,
          vdl_5emejour: detailsData.vdl_5emejour,
          vdl_jemengageasuivrelaformationobligatoire:
            detailsData.vdl_jemengageasuivrelaformationobligatoire,
          vdl_jaidejasuivilaformationobligatoireannee:
            detailsData.vdl_jaidejasuivilaformationobligatoireannee,
          vdl_chomedeycartier: detailsData.vdl_chomedeycartier,
          vdl_jeanberauddanieljohnson: detailsData.vdl_jeanberauddanieljohnson,
          vdl_notredamelavatere: detailsData.vdl_notredamelavatere,
          vdl_stmartinboisdeboulogne: detailsData.vdl_stmartinboisdeboulogne,
          vdl_engagementaurespectdesproceduresetdirectives:
            detailsData.vdl_engagementaurespectdesproceduresetdirectives,
          vdl_projetdefinancement: detailsData.vdl_projetdefinancement,
          vdl_nombredadultesparticipants5min:
            detailsData.vdl_nombredadultesparticipants5min,
          vdl_nombredejeunesparticipants18ansetmoins:
            detailsData.vdl_nombredejeunesparticipants18ansetmoins,
        },
      };
      return demandFundraiser;
    case "Calendrier des activités":
      const demandActivitiesCalendar: PartialDemandActivitiesCalendarRow = {
        type: demandType,
        vdl_detailsdelademande: {
          vdl_soutienpromotionnelid: detailsData.vdl_soutienpromotionnelid,
          vdl_titredelevenement: detailsData.vdl_titredelevenement,
          vdl_lieudelevenement: detailsData.vdl_lieudelevenement,
          vdl_veuillezjoindreunephotofile:
            detailsData.vdl_veuillezjoindreunephotofile
              ? {
                  filename:
                    detailsData.vdl_veuillezjoindreunephotofile.filename,
                  content: detailsData.vdl_veuillezjoindreunephotofile.content,
                  mimetype:
                    detailsData.vdl_veuillezjoindreunephotofile.mimetype,
                }
              : undefined,
          vdl_adressecompletedulieudelevenement:
            detailsData.vdl_adressecompletedulieudelevenement,
          vdl_dateetheurededebut: detailsData.vdl_dateetheurededebut,
          vdl_dateetheuredefin: detailsData.vdl_dateetheuredefin,
          vdl_commentairessurleshorairesdelevenement:
            detailsData.vdl_commentairessurleshorairesdelevenement,
          vdl_cout: detailsData.vdl_cout,
          vdl_adultes: detailsData.vdl_adultes
            ? detailsData.vdl_adultes.Value
            : undefined,
          vdl_retraites: detailsData.vdl_retraites
            ? detailsData.vdl_retraites.Value
            : undefined,
          vdl_etudiants: detailsData.vdl_etudiants
            ? detailsData.vdl_etudiants.Value
            : undefined,
          vdl_enfants: detailsData.vdl_enfants
            ? detailsData.vdl_enfants.Value
            : undefined,
          vdl_prixdefamille: detailsData.vdl_prixdefamille
            ? detailsData.vdl_prixdefamille.Value
            : undefined,
          vdl_prixdegroupe: detailsData.vdl_prixdegroupe
            ? detailsData.vdl_prixdegroupe.Value
            : undefined,
          vdl_autressvpdetaillez2: detailsData.vdl_autressvpdetaillez2,
          vdl_stationnementpayant: detailsData.vdl_stationnementpayant,
          vdl_coutdestationnement: detailsData.vdl_coutdestationnement
            ? detailsData.vdl_coutdestationnement.Value
            : undefined,
          vdl_clientelesvisees: detailsData.vdl_clientelesvisees,
          vdl_siteinternet: detailsData.vdl_siteinternet,
          vdl_pagefacebook: detailsData.vdl_pagefacebook,
          vdl_courriel: detailsData.vdl_courriel,
          vdl_telephonecalendrier: detailsData.vdl_telephonecalendrier,
          vdl_descriptionsommairedelevenement:
            detailsData.vdl_descriptionsommairedelevenement,
          vdl_autreinformationpertinente:
            detailsData.vdl_autreinformationpertinente,
        },
      };
      return demandActivitiesCalendar;
    case "Panneaux électroniques":
      const demandElectronilPanel: PartialDemandElectronicPanelsRow = {
        type: demandType,
        vdl_detailsdelademande: {
          vdl_soutienpromotionnelid: detailsData.vdl_soutienpromotionnelid,
          vdl_datededebutdelaffichage: detailsData.vdl_datededebutdelaffichage,
          vdl_datedefindelaffichage: detailsData.vdl_datedefindelaffichage,
          vdl_quoi: detailsData.vdl_quoi,
          vdl_qui: detailsData.vdl_qui,
          vdl_quanddatesetheures: detailsData.vdl_quanddatesetheures,
          vdl_ou: detailsData.vdl_ou,
          vdl_telephonepourinformationsurlevenement:
            detailsData.vdl_telephonepourinformationsurlevenement,
        },
      };
      return demandElectronilPanel;
    case "Télévision communautaire":
      const demandTelevisionDisplay: PartialDemandTelevisionDisplayRow = {
        type: demandType,
        vdl_detailsdelademande: {
          vdl_soutienpromotionnelid: detailsData.vdl_soutienpromotionnelid,
          vdl_imagedepromotionfile: detailsData.vdl_imagedepromotionfile
            ? {
                filename: detailsData.vdl_imagedepromotionfile.filename,
                content: detailsData.vdl_imagedepromotionfile.content,
                mimetype: detailsData.vdl_imagedepromotionfile.mimetype,
              }
            : undefined,
          vdl_debutdediffusion: detailsData.vdl_debutdediffusion,
          vdl_findediffusion: detailsData.vdl_findediffusion,
          vdl_secteur1: detailsData.vdl_secteur1,
          vdl_secteur2: detailsData.vdl_secteur2,
          vdl_secteur3: detailsData.vdl_secteur3,
          vdl_secteur4: detailsData.vdl_secteur4,
          vdl_secteur5: detailsData.vdl_secteur5,
          vdl_secteur6: detailsData.vdl_secteur6,
        },
      };
      return demandTelevisionDisplay;
    case "Site web de la ville":
      const demandWebsiteDisplayRow: PartialDemandWebsiteDisplayRow = {
        type: demandType,
        vdl_detailsdelademande: {
          vdl_soutienpromotionnelid: detailsData.vdl_soutienpromotionnelid,
          vdl_lieninternetdelapageconcernee:
            detailsData.vdl_lieninternetdelapageconcernee,
          vdl_piecejointe: detailsData.vdl_piecejointe
            ? {
                filename: detailsData.vdl_piecejointe.filename,
                content: detailsData.vdl_piecejointe.content,
                mimetype: detailsData.vdl_piecejointe.mimetype,
              }
            : undefined,
        },
      };
      return demandWebsiteDisplayRow;
    case "Prisme":
      const demandPrismeRow: PartialDemandPrismeRow = {
        type: demandType,
        vdl_detailsdelademande: {
          vdl_soutienpromotionnelid: detailsData.vdl_soutienpromotionnelid,
          vdl_activiteapromouvoir: detailsData.vdl_activiteapromouvoir,
          vdl_nombredepanneaux: detailsData.vdl_nombredepanneaux,
          vdl_datedelactivite: detailsData.vdl_datedelactivite,
          vdl_datededebutdelevenement: detailsData.vdl_datededebutdelevenement,
          vdl_datedefindelevenement: detailsData.vdl_datedefindelevenement,
          vdl_esquissedelaffichefile: detailsData.vdl_esquissedelaffichefile
            ? {
                filename: detailsData.vdl_esquissedelaffichefile.filename,
                content: detailsData.vdl_esquissedelaffichefile.content,
                mimetype: detailsData.vdl_esquissedelaffichefile.mimetype,
              }
            : undefined,
          vdl_autoroute25bouldelaconcordeestsite3:
            detailsData.vdl_autoroute25bouldelaconcordeestsite3,
          vdl_monteedumoulinleparcdumoulinsite10:
            detailsData.vdl_monteedumoulinleparcdumoulinsite10,
          vdl_bouldeslaurentidesboulsaintmartinsite1:
            detailsData.vdl_bouldeslaurentidesboulsaintmartinsite1,
          vdl_bouldeslaurentidesboulcartiersite2:
            detailsData.vdl_bouldeslaurentidesboulcartiersite2,
          vdl_boullecorbusierbouldusouvenirsite8:
            detailsData.vdl_boullecorbusierbouldusouvenirsite8,
          vdl_bouldelaconcordebouldelavenirsite9:
            detailsData.vdl_bouldelaconcordebouldelavenirsite9,
          vdl_boulcurelabelleboullevesquesite4:
            detailsData.vdl_boulcurelabelleboullevesquesite4,
          vdl_boularthursauvebouldagenaisouestsite6:
            detailsData.vdl_boularthursauvebouldagenaisouestsite6,
          vdl_boulsamsonavjosaphatdemerssite11:
            detailsData.vdl_boulsamsonavjosaphatdemerssite11,
          vdl_bouldeslaurentidesbouldagenaisestsite5:
            detailsData.vdl_bouldeslaurentidesbouldagenaisestsite5,
        },
      };
      return demandPrismeRow;

    case "Déposer une demande d'admissibilité":
    case "Demande de renouvellement":
      const demandAdmissibilityRow: PartialDemandAdmissibilityRow = {
        type: demandType,
        vdl_detailsdelademande: {
          vdl_admisibiliteid: detailsData.vdl_admisibiliteid,
          vdl_admissibiliterenouvellement:
            detailsData.vdl_admissibiliterenouvellement,
          vdl_dorganismeabutnonlucratif:
            detailsData.vdl_dorganismeabutnonlucratif,
          vdl_dorganismedebienfaisanceenregistre:
            detailsData.vdl_dorganismedebienfaisanceenregistre,
          vdl_etesvousunchapitrelavalloisduneinstancepro:
            detailsData.vdl_etesvousunchapitrelavalloisduneinstancepro,
          vdl_siouiquelestlenometleneqdelorg:
            detailsData.vdl_siouiquelestlenometleneqdelorg,
          vdl_datedeladeclarationaureq:
            detailsData.vdl_datedeladeclarationaureq,
          vdl_aucuneassurance: detailsData.vdl_aucuneassurance,
          vdl_assurancedesadministrateurs:
            detailsData.vdl_assurancedesadministrateurs,
          vdl_assurancedesbiens: detailsData.vdl_assurancedesbiens,
          vdl_assuranceresponsabilitecivile:
            detailsData.vdl_assuranceresponsabilitecivile,
          vdl_assuranceadministrateursetdirigeants:
            detailsData.vdl_assuranceadministrateursetdirigeants,
          vdl_nomdelacompagniedassurance:
            detailsData.vdl_nomdelacompagniedassurance,
          vdl_numerodelapolicedassurance:
            detailsData.vdl_numerodelapolicedassurance,
          vdl_datedecheancedelapolicedassurance:
            detailsData.vdl_datedecheancedelapolicedassurance,

          vdl_missionetobjectif: detailsData.vdl_missionetobjectif,

          vdl_realitebesoinsoutien: detailsData.vdl_realitebesoinsoutien,

          vdl_pointsdeservicealaval: detailsData.vdl_pointsdeservicealaval,
          vdl_siouiaquelendroit: detailsData.vdl_siouiaquelendroit,
          vdl_dansqueldomainedactivitevotreorganismeinter:
            detailsData.vdl_dansqueldomainedactivitevotreorganismeinter,
          vdl_siautresprecisez: detailsData.vdl_siautresprecisez,
          vdl_principauxserviceouactivites:
            detailsData.vdl_principauxserviceouactivites,
          vdl_desregroupementsetoudesorganismes:
            detailsData.vdl_desregroupementsetoudesorganismes,
          vdl_territoiresdesservisparlorganisme2:
            detailsData.vdl_territoiresdesservisparlorganisme2 ?? [],
          vdl_nombredeparticipantsauxactivites:
            detailsData.vdl_nombredeparticipantsauxactivites,
          vdl_typedeclientele: detailsData.vdl_typedeclientele,

          vdl_prescolaire0a4ans: detailsData.vdl_prescolaire0a4ans,
          vdl_enfants5a12ans: detailsData.vdl_enfants5a12ans,
          vdl_adolescents13a17ans: detailsData.vdl_adolescents13a17ans,
          vdl_adultes18a64ans: detailsData.vdl_adultes18a64ans,
          vdl_aines65ansetplus65: detailsData.vdl_aines65ansetplus65,

          vdl_politiquedeverification: detailsData.vdl_politiquedeverification,
          vdl_entente: detailsData.vdl_entente,
          vdl_codedeconduite: detailsData.vdl_codedeconduite,
          vdl_formationsurladoptiondecomportementsadequats:
            detailsData.vdl_formationsurladoptiondecomportementsadequats,
          vdl_codedeconduitedelorganisation:
            detailsData.vdl_codedeconduitedelorganisation,
          vdl_codeetsignatureduncontratethique:
            detailsData.vdl_codeetsignatureduncontratethique,
          vdl_verificationantecedentjudiciaire:
            detailsData.vdl_verificationantecedentjudiciaire,
          vdl_mecanismedegestionderisqueencasdedoute:
            detailsData.vdl_mecanismedegestionderisqueencasdedoute,
          vdl_utilisationdelaplateformejebenevoleca:
            detailsData.vdl_utilisationdelaplateformejebenevoleca,
          vdl_jeveuxplusdinformationacesujet:
            detailsData.vdl_jeveuxplusdinformationacesujet,

          vdl_nombrederencontresduca: detailsData.vdl_nombrederencontresduca,
          vdl_nombredepresencesalaga: detailsData.vdl_nombredepresencesalaga,

          vdl_datedeladerniereassembleegeneraleannuelle:
            detailsData.vdl_datedeladerniereassembleegeneraleannuelle,
          vdl_nombredemembresactifsauseindelorganisme:
            detailsData.vdl_nombredemembresactifsauseindelorganisme,
          vdl_duneconcertation: detailsData.vdl_duneconcertation,
          vdl_dunregroupement: detailsData.vdl_dunregroupement,
          vdl_dunefederation: detailsData.vdl_dunefederation,

          vdl_produisezvousannuellementdesprevisionsbudgeta:
            detailsData.vdl_produisezvousannuellementdesprevisionsbudgeta,
          vdl_vosderniersetatsfinanciersontilsfaitlobjet:
            detailsData.vdl_vosderniersetatsfinanciersontilsfaitlobjet,
          vdl_datedefindevotreexercicefinancierannuel:
            detailsData.vdl_datedefindevotreexercicefinancierannuel,

          vdl_audit: detailsData.vdl_audit,
          vdl_missiondexamen: detailsData.vdl_missiondexamen,
          vdl_verificationcomptable: detailsData.vdl_verificationcomptable,
                    
          vdl_resolutionduconseildadministration: detailsData.vdl_resolutionduconseildadministration,
          vdl_reglementsgeneraux: detailsData.vdl_reglementsgeneraux,
          vdl_procesverbauxdesagaetags: detailsData.vdl_procesverbauxdesagaetags,
          vdl_lettrespatentesetcharte: detailsData.vdl_lettrespatentesetcharte,
          vdl_rapportdactivite: detailsData.vdl_rapportdactivite,
          vdl_etatsfinanciers: detailsData.vdl_etatsfinanciers,
        },
      };
      return demandAdmissibilityRow;
    case "Location ou prêt d'espaces municipaux":
      const demandPublicSpaceRow: PartialDemandPublicSpaceRow = {
        type: demandType,
        vdl_detailsdelademande: {
          vdl_tournage: detailsData.vdl_tournage,
          vdl_espacemunicipalid: detailsData.vdl_espacemunicipalid,
          vdl_name: detailsData.vdl_name ?? "",
          reservations: [],
        },
      };
      if (detailsData.reservations) {
        demandPublicSpaceRow.vdl_detailsdelademande.reservations =
          detailsData.reservations.map((reservation: any) => ({
            vdl_reservationid: reservation.vdl_reservationid,
            vdl_courriel: reservation.vdl_courriel,
            vdl_titre: reservation.vdl_titre,
            vdl_demandeespacemunicipal: reservation.vdl_demandeespacemunicipal,
            vdl_debutdelareservation: reservation.vdl_debutdelareservation,
            vdl_findelareservation: reservation.vdl_findelareservation,
            vdl_ndetelephoneprincipal: reservation.vdl_ndetelephoneprincipal,
            vdl_prenom: reservation.vdl_prenom,
            vdl_nom: reservation.vdl_nom,
            vdl_nombredeparticipants: reservation.vdl_nombredeparticipants,
            vdl_nomdusiteareserver: {
              value: reservation.vdl_nomdusiteareserver.Id,
              label: reservation.vdl_nomdusiteareserver.Name,
            },
            vdl_sousplateau: {
              value: reservation.vdl_sousplateau.Id,
              label: reservation.vdl_sousplateau.Name,
            },
          }));
      }

      return demandPublicSpaceRow;

    case "Évènement spécial":
      const demandSpecialEventRow: PartialDemandSpecialEventRow = {
        type: demandType,
        regroupement: {
          id: demandData.regroupement.id,
          name: demandData.regroupement.name,
          principalIncidentId: demandData.regroupement.principalIncidentId,
          demandes: [],
        },
        vdl_detailsdelademande: {
          vdl_evenementspecialid: detailsData.vdl_evenementspecialid,
          vdl_lieudelevenement: detailsData.vdl_lieudelevenement,
          vdl_precisionsurlelieu: detailsData.vdl_precisionsurlelieu,
          vdl_siautreprecisezlieu: detailsData.vdl_siautreprecisezlieu,
          vdl_encasdintemperieslevenementest:
            detailsData.vdl_encasdintemperieslevenementest,
          vdl_siremisle: detailsData.vdl_siremisle,

          vdl_nomdelevenement: detailsData.vdl_nomdelevenement,
          vdl_naturedelevenement: detailsData.vdl_naturedelevenement,
          vdl_siautreprecisez: detailsData.vdl_siautreprecisez,
          vdl_descriptiondelevenement: detailsData.vdl_descriptiondelevenement,
          vdl_rayonnementdelevenement: detailsData.vdl_rayonnementdelevenement,
          vdl_nouvelledemande: detailsData.vdl_nouvelledemande,
          vdl_demanderecurrente: detailsData.vdl_demanderecurrente,
          vdl_sireccurentdatedeladerniereedition:
            detailsData.vdl_sireccurentdatedeladerniereedition,
          vdl_nombredeparticipantsetdevisiteursattendus:
            detailsData.vdl_nombredeparticipantsetdevisiteursattendus,
          vdl_clienteleviseeparlevenement:
            detailsData.vdl_clienteleviseeparlevenement,
          vdl_sispecifiqueprecisez: detailsData.vdl_sispecifiqueprecisez,

          vdl_engagementloisetreglement:
            detailsData.vdl_engagementloisetreglement,
          vdl_engagementequiteetinclusion:
            detailsData.vdl_engagementequiteetinclusion,
          vdl_engagementdeveloppementdurable:
            detailsData.vdl_engagementdeveloppementdurable,
          vdl_responsabilitedumaterielprete:
            detailsData.vdl_responsabilitedumaterielprete,
          vdl_engagementhabitudessaines:
            detailsData.vdl_engagementhabitudessaines,

          dates: detailsData.dates ?? [],
        },
      };
      demandSpecialEventRow.regroupement.demandes =
        demandData.regroupement.demandes.map((demande: any) => ({
          title: demande.title,
          incidentid: demande.incidentid,
          ticketnumber: demande.ticketnumber,
          statuscode: demande.statuscode,
          modifiedon: demande.modifiedon,
          createdon: demande.createdon,
          vdl_typededemande: {
            value: demande.vdl_typededemande.Id,
            label: demande.vdl_typededemande.Name,
          },
        }));
      return demandSpecialEventRow;
    case "Voie publique":
      const demandPublicRoadRow: PartialDemandPublicRoadRow = {
        type: demandType,
        vdl_detailsdelademande: {
          vdl_voiepubliqueid: detailsData.vdl_voiepubliqueid,
          vdl_typedactivite: detailsData.vdl_typedactivite,

          vdl_fermeturedelarue2: detailsData.vdl_fermeturedelarue2,
          vdl_fermeturedelarue: detailsData.vdl_fermeturedelarue,
          vdl_troncon: detailsData.vdl_troncon,
          vdl_nomdelarue: detailsData.vdl_nomdelarue,
          vdl_nord: detailsData.vdl_nord,
          vdl_sud: detailsData.vdl_sud,
          vdl_est: detailsData.vdl_est,
          vdl_ouest: detailsData.vdl_ouest,
          vdl_entrerueouadresses: detailsData.vdl_entrerueouadresses,
          vdl_circuit: detailsData.vdl_circuit,
          vdl_trajetrueparrueavecdirection:
            detailsData.vdl_trajetrueparrueavecdirection,
          vdl_dateetheuredelafermeture:
            detailsData.vdl_dateetheuredelafermeture,
          vdl_dateetheuredelareouverture:
            detailsData.vdl_dateetheuredelareouverture,
          vdl_renseignementadditionnels:
            detailsData.vdl_renseignementadditionnels,

          //Fermeture du trottoir
          vdl_fermeturedutrottoircasecocher:
            detailsData.vdl_fermeturedutrottoircasecocher,
          vdl_fermeturedutrottoirliste:
            detailsData.vdl_fermeturedutrottoirliste,
          vdl_nomdelarue2: detailsData.vdl_nomdelarue2,
          vdl_entrerueouadresses4: detailsData.vdl_entrerueouadresses4,
          vdl_nord2: detailsData.vdl_nord2,
          vdl_sud2: detailsData.vdl_sud2,
          vdl_est2: detailsData.vdl_est2,
          vdl_ouest2: detailsData.vdl_ouest2,
          vdl_dateetheuredelafermeture2:
            detailsData.vdl_dateetheuredelafermeture2,
          vdl_dateetheuredelareouverture2:
            detailsData.vdl_dateetheuredelareouverture2,
          vdl_renseignementadditionnels2:
            detailsData.vdl_renseignementadditionnels2,

          //Interdiction de stationnement
          vdl_interdictiondestationnement:
            detailsData.vdl_interdictiondestationnement,
          vdl_raisondelademande: detailsData.vdl_raisondelademande,
          vdl_nomdelarue3: detailsData.vdl_nomdelarue3,
          vdl_nord3: detailsData.vdl_nord3,
          vdl_est3: detailsData.vdl_est3,
          vdl_sud3: detailsData.vdl_sud3,
          vdl_ouest3: detailsData.vdl_ouest3,
          vdl_dateetheuredelafermeture3:
            detailsData.vdl_dateetheuredelafermeture3,
          vdl_dateetheuredelareouverture3:
            detailsData.vdl_dateetheuredelareouverture3,
          vdl_renseignementadditionnels3:
            detailsData.vdl_renseignementadditionnels3,

          //Toléramce de stationnement
          vdl_tolerancedestationnement:
            detailsData.vdl_tolerancedestationnement,
          vdl_raisondelademande2: detailsData.vdl_raisondelademande2,
          vdl_nomdelarue4: detailsData.vdl_nomdelarue4,
          vdl_nord4: detailsData.vdl_nord4,
          vdl_est4: detailsData.vdl_est4,
          vdl_sud4: detailsData.vdl_sud4,
          vdl_ouest4: detailsData.vdl_ouest4,
          vdl_dateetheuredelafermeture4:
            detailsData.vdl_dateetheuredelafermeture4,
          vdl_dateetheuredelareouverture4:
            detailsData.vdl_dateetheuredelareouverture4,
          vdl_renseignementadditionnels4:
            detailsData.vdl_renseignementadditionnels4,

          //Activités
          vdl_redonneeavelo: detailsData.vdl_redonneeavelo,
          vdl_defile: detailsData.vdl_defile,
          vdl_marche: detailsData.vdl_marche,
          vdl_processionreligieuse: detailsData.vdl_processionreligieuse,
          vdl_vehiculehippomobilecaleche:
            detailsData.vdl_vehiculehippomobilecaleche,
          vdl_rassemblementsansdeplacement:
            detailsData.vdl_rassemblementsansdeplacement,
          vdl_nombredeparticipants: detailsData.vdl_nombredeparticipants,
          vdl_enfants512ans: detailsData.vdl_enfants512ans,
          vdl_adultes: detailsData.vdl_adultes,
          vdl_trajetrueparrueavecdirection2:
            detailsData.vdl_trajetrueparrueavecdirection2,
          vdl_rue: detailsData.vdl_rue,
          vdl_trottoir: detailsData.vdl_trottoir,
          vdl_pistescyclables: detailsData.vdl_pistescyclables,
          vdl_autre: detailsData.vdl_autre,
          vdl_siautreprecisez: detailsData.vdl_siautreprecisez,
        },
      };
      return demandPublicRoadRow;

    case "Sécurité prévention":
      const demandSecurityPreventionRow: PartialDemandSecurityPreventionRow = {
        type: demandType,
        vdl_detailsdelademande: {
          vdl_securitepreventionid: detailsData.vdl_securitepreventionid,
          vdl_typedactivite: detailsData.vdl_typedactivite,

          vdl_feuacielouvert: detailsData.vdl_feuacielouvert,
          vdl_feudecamp1mdediametre: detailsData.vdl_feudecamp1mdediametre,
          vdl_feudejoie1mdediametre: detailsData.vdl_feudejoie1mdediametre,
          vdl_foyerbrasero: detailsData.vdl_foyerbrasero,

          vdl_artistedefeu: detailsData.vdl_artistedefeu,
          vdl_jongleur: detailsData.vdl_jongleur,
          vdl_cracheurdefeu: detailsData.vdl_cracheurdefeu,
          vdl_autre: detailsData.vdl_autre,
          vdl_siautreprecisez: detailsData.vdl_siautreprecisez,

          vdl_accessoireavecfeu: detailsData.vdl_accessoireavecfeu,
          vdl_flambeaux: detailsData.vdl_flambeaux,
          vdl_lampions: detailsData.vdl_lampions,
          vdl_lanternes: detailsData.vdl_lanternes,
          vdl_autre2: detailsData.vdl_autre2,
          vdl_siautreprecisez1: detailsData.vdl_siautreprecisez1,

          vdl_utilisationdepiecespyrotechniqueseteffetsspe:
            detailsData.vdl_utilisationdepiecespyrotechniqueseteffetsspe,
          vdl_feudartificedomestique: detailsData.vdl_feudartificedomestique,
          vdl_feudartificeagrandeploiement:
            detailsData.vdl_feudartificeagrandeploiement,
          vdl_effetspeciauxpyrotechniques:
            detailsData.vdl_effetspeciauxpyrotechniques,
          vdl_precisez: detailsData.vdl_precisez,
          vdl_effetsspeciaux: detailsData.vdl_effetsspeciaux,
          vdl_precisez1: detailsData.vdl_precisez1,
          vdl_cascade: detailsData.vdl_cascade,
          vdl_simulationdevol: detailsData.vdl_simulationdevol,
          vdl_simulationdebagarre: detailsData.vdl_simulationdebagarre,
          vdl_simulationdenlevement: detailsData.vdl_simulationdenlevement,
          vdl_autre3: detailsData.vdl_autre3,
          vdl_siautreprecisez2: detailsData.vdl_siautreprecisez2,

          vdl_utilisationdunappareilacombustion:
            detailsData.vdl_utilisationdunappareilacombustion,
          vdl_generatrice: detailsData.vdl_generatrice,
          vdl_appareildecuison: detailsData.vdl_appareildecuison,
          vdl_barbecue: detailsData.vdl_barbecue,
          vdl_chauffagedappoint: detailsData.vdl_chauffagedappoint,
          vdl_autre4: detailsData.vdl_autre4,
          vdl_siautreprecisez3: detailsData.vdl_siautreprecisez3,
          vdl_gazpropane: detailsData.vdl_gazpropane,
          vdl_essence: detailsData.vdl_essence,
          vdl_charbon: detailsData.vdl_charbon,
          vdl_autre5: detailsData.vdl_autre5,

          vdl_activitedenuitsurunsitemunicipal:
            detailsData.vdl_activitedenuitsurunsitemunicipal,
          vdl_interieur: detailsData.vdl_interieur,
          vdl_exterieur: detailsData.vdl_exterieur,
          vdl_heurededebutdutilisationdusite:
            detailsData.vdl_heurededebutdutilisationdusite,
          vdl_heuredefindutilisationdusite:
            detailsData.vdl_heuredefindutilisationdusite,
          vdl_nombredeparticipant: detailsData.vdl_nombredeparticipant,
          vdl_enfants512ans: detailsData.vdl_enfants512ans,
          vdl_adolescents1317ans: detailsData.vdl_adolescents1317ans,
          vdl_adultes: detailsData.vdl_adultes,

          vdl_survolabasealtitude: detailsData.vdl_survolabasealtitude,
          vdl_utilisationdappareildetypeaviation:
            detailsData.vdl_utilisationdappareildetypeaviation,
          vdl_precisez2: detailsData.vdl_precisez2,

          vdl_attentionparticuliereduservicedepolice:
            detailsData.vdl_attentionparticuliereduservicedepolice,
          vdl_securite500persbenevolesagentsdesecurite:
            detailsData.vdl_securite500persbenevolesagentsdesecurite,
          vdl_casernemobile: detailsData.vdl_casernemobile,
          vdl_kiosquedepreventionpolice:
            detailsData.vdl_kiosquedepreventionpolice,
          vdl_kiosquedepreventionincendies:
            detailsData.vdl_kiosquedepreventionincendies,
        },
      };
      return demandSecurityPreventionRow;
    case "Permis":
      const demandPermitRow: PartialDemandPermitRow = {
        type: demandType,
        vdl_detailsdelademande: {
          vdl_permisid: detailsData.vdl_permisid,
          vdl_typedactivite: detailsData.vdl_typedactivite,

          vdl_tarificationdaccesaunsitemunicipal:
            detailsData.vdl_tarificationdaccesaunsitemunicipal,
          vdl_enfant: detailsData.vdl_enfant,
          vdl_adultes: detailsData.vdl_adultes,
          vdl_autres1: detailsData.vdl_autres1,
          vdl_revenusdetarificationremisa:
            detailsData.vdl_revenusdetarificationremisa,

          vdl_diffusionsonore: detailsData.vdl_diffusionsonore,
          vdl_utilisationdunsystemedesonorisationamplifie:
            detailsData.vdl_utilisationdunsystemedesonorisationamplifie,
          vdl_autreappareilsusceptibledenuireauvoisinage:
            detailsData.vdl_autreappareilsusceptibledenuireauvoisinage,
          vdl_precisez8: detailsData.vdl_precisez8,

          vdl_eclairage: detailsData.vdl_eclairage,
          vdl_utilisationdunsystemdeclairagenuisance:
            detailsData.vdl_utilisationdunsystemdeclairagenuisance,
          vdl_precisez7: detailsData.vdl_precisez7,

          vdl_affichagetemporairesignalisationpavoisement:
            detailsData.vdl_affichagetemporairesignalisationpavoisement,
          vdl_panneauxdesignalisation: detailsData.vdl_panneauxdesignalisation,
          vdl_precisezdimensionetmateriaux:
            detailsData.vdl_precisezdimensionetmateriaux,
          vdl_bannieres: detailsData.vdl_bannieres,
          vdl_precisezdimensionetsupport:
            detailsData.vdl_precisezdimensionetsupport,
          vdl_fanions: detailsData.vdl_fanions,
          vdl_autres2: detailsData.vdl_autres2,
          vdl_precisez1: detailsData.vdl_precisez1,

          vdl_installationdestructuretemporaire:
            detailsData.vdl_installationdestructuretemporaire,
          vdl_chapiteaumarquise: detailsData.vdl_chapiteaumarquise,
          vdl_preciseznombreetdimension:
            detailsData.vdl_preciseznombreetdimension,
          vdl_structuresgonflables: detailsData.vdl_structuresgonflables,
          vdl_tyrolienne: detailsData.vdl_tyrolienne,
          vdl_scenemobile: detailsData.vdl_scenemobile,
          vdl_autres3: detailsData.vdl_autres3,
          vdl_precisez2: detailsData.vdl_precisez2,
          vdl_equipementconformeauxnormesetreglements:
            detailsData.vdl_equipementconformeauxnormesetreglements,
          vdl_installateuretoperateursontqualifies:
            detailsData.vdl_installateuretoperateursontqualifies,
          vdl_nomdufournisseur: detailsData.vdl_nomdufournisseur,
          vdl_requerantassurelaresponsabilitecivile:
            detailsData.vdl_requerantassurelaresponsabilitecivile,

          vdl_consommationdeboissonsalcooliques:
            detailsData.vdl_consommationdeboissonsalcooliques,

          vdl_ventedeboissonsalcooliques:
            detailsData.vdl_ventedeboissonsalcooliques,
          vdl_venteestgereeparledemandeur1:
            detailsData.vdl_venteestgereeparledemandeur1,
          vdl_ventegereeparuntiers1: detailsData.vdl_ventegereeparuntiers1,
          vdl_precisez4: detailsData.vdl_precisez4,
          vdl_profitsdelaventeremis1: detailsData.vdl_profitsdelaventeremis1,

          vdl_ventealimentsetboissonsexcomptoirkiosque:
            detailsData.vdl_ventealimentsetboissonsexcomptoirkiosque,
          vdl_venteestgereeparledemandeur2:
            detailsData.vdl_venteestgereeparledemandeur2,
          vdl_ventegereeparuntiers2: detailsData.vdl_ventegereeparuntiers2,
          vdl_precisez6: detailsData.vdl_precisez6,
          vdl_nombredepointsdeventesurlesite1:
            detailsData.vdl_nombredepointsdeventesurlesite1,
          vdl_profitsdelaventeremis2: detailsData.vdl_profitsdelaventeremis2,

          vdl_ventedeproduitsservicesobjetsexpovente:
            detailsData.vdl_ventedeproduitsservicesobjetsexpovente,
          vdl_venteestgereeparledemandeur3:
            detailsData.vdl_venteestgereeparledemandeur3,
          vdl_ventegereeparuntiers3: detailsData.vdl_ventegereeparuntiers3,
          vdl_precisez5: detailsData.vdl_precisez5,
          vdl_nombredepointsdeventesurlesite2:
            detailsData.vdl_nombredepointsdeventesurlesite2,
          vdl_profitsdelaventeremisa3: detailsData.vdl_profitsdelaventeremisa3,

          vdl_sollicitationporteaporte:
            detailsData.vdl_sollicitationporteaporte,
          vdl_collectedebouteille: detailsData.vdl_collectedebouteille,
          vdl_ventedecalendrier: detailsData.vdl_ventedecalendrier,
          vdl_autre: detailsData.vdl_autre,
          vdl_precisez3: detailsData.vdl_precisez3,
          vdl_revenusdelactiviteremisa:
            detailsData.vdl_revenusdelactiviteremisa,

          vdl_camionrestaurant: detailsData.vdl_camionrestaurant,
        },
      };
      return demandPermitRow;
    case "Soutien Éco-Responsable":
      const demandEcoSupportRow: PartialDemandEcoSupportRow = {
        type: demandType,
        vdl_detailsdelademande: {
          vdl_soutientecoresponsableid:
            detailsData.vdl_soutientecoresponsableid,
          vdl_duobacsroulants240ldechetrecyclage:
            detailsData.vdl_duobacsroulants240ldechetrecyclage,
          vdl_qte2: detailsData.vdl_qte2,
          vdl_duobarils205ldechetsrecyclage:
            detailsData.vdl_duobarils205ldechetsrecyclage,
          vdl_qte4: detailsData.vdl_qte4,
          vdl_bacroulantbrun240lcompost:
            detailsData.vdl_bacroulantbrun240lcompost,
          vdl_qte5: detailsData.vdl_qte5,
          vdl_bacslimjimnoir90lavecsacsdechet:
            detailsData.vdl_bacslimjimnoir90lavecsacsdechet,
          vdl_qte6: detailsData.vdl_qte6,
          vdl_bacslimjimbleu90lavecsacsrecyclage:
            detailsData.vdl_bacslimjimbleu90lavecsacsrecyclage,
          vdl_qte: detailsData.vdl_qte,
          vdl_bacslimjimbrun90lavecsacscompost:
            detailsData.vdl_bacslimjimbrun90lavecsacscompost,
          vdl_qte1: detailsData.vdl_qte1,
          vdl_supportaveloavecchainesetcadenas:
            detailsData.vdl_supportaveloavecchainesetcadenas,

          vdl_addressedelivraison: detailsData.vdl_addressedelivraison,
          vdl_datedelivraison: detailsData.vdl_datedelivraison,
          vdl_datederecupperation: detailsData.vdl_datederecupperation,

          vdl_kiosqueenvironnementecocitoyennete:
            detailsData.vdl_kiosqueenvironnementecocitoyennete,
          vdl_servicedeplanificationetdaccompagnement:
            detailsData.vdl_servicedeplanificationetdaccompagnement,
        },
      };
      return demandEcoSupportRow;

    case "Soutien événement":
      const demandEventSupportRow: PartialDemandEventSupportRow = {
        type: demandType,
        vdl_detailsdelademande: {
          vdl_soutienevenementid: detailsData.vdl_soutienevenementid,

          vdl_amenagementelectriquetemporaire:
            detailsData.vdl_amenagementelectriquetemporaire,

          vdl_typedappareil: detailsData.vdl_typedappareil,
          vdl_nombredeprise: detailsData.vdl_nombredeprise,
          vdl_intensiteetvoltage: detailsData.vdl_intensiteetvoltage,

          vdl_typedappareil2: detailsData.vdl_typedappareil2,
          vdl_nombredeprise2: detailsData.vdl_nombredeprise2,
          vdl_intensiteetvoltage2: detailsData.vdl_intensiteetvoltage2,
          vdl_typedappareil3: detailsData.vdl_typedappareil3,
          vdl_nombredeprise3: detailsData.vdl_nombredeprise3,
          vdl_intensiteetvoltage3: detailsData.vdl_intensiteetvoltage3,

          vdl_descriptionadditionneldamenagementelectrique:
            detailsData.vdl_descriptionadditionneldamenagementelectrique,
        },
      };
      return demandEventSupportRow;
    case "Tournage":
      const demandFilmingRow: PartialDemandFilmingRow = {
        type: demandType,
        regroupement: {
          id: demandData.regroupement.id,
          name: demandData.regroupement.name,
          principalIncidentId: demandData.regroupement.principalIncidentId,
          demandes: [],
        },
        vdl_detailsdelademande: {
          vdl_evenementspecialid: detailsData.vdl_evenementspecialid,
          vdl_lieudelevenement: detailsData.vdl_lieudelevenement,
          vdl_precisionsurlelieu: detailsData.vdl_precisionsurlelieu,
          vdl_siautreprecisezlieu: detailsData.vdl_siautreprecisezlieu,
          vdl_encasdintemperieslevenementest:
            detailsData.vdl_encasdintemperieslevenementest,
          vdl_siremisle: detailsData.vdl_siremisle,

          vdl_titredelaproduction: detailsData.vdl_titredelaproduction,
          vdl_naturedutournage: detailsData.vdl_naturedutournage,
          vdl_tournageetudiant: detailsData.vdl_tournageetudiant,
          vdl_resumeduscenarioenquelquesmots:
            detailsData.vdl_resumeduscenarioenquelquesmots,
          vdl_nombredevehiculesdeproductionetparticuliers:
            detailsData.vdl_nombredevehiculesdeproductionetparticuliers,
          vdl_cubes: detailsData.vdl_cubes,
          vdl_roulottes: detailsData.vdl_roulottes,
          vdl_voituresparticuliers: detailsData.vdl_voituresparticuliers,
          vdl_autresprecisez: detailsData.vdl_autresprecisez,
          vdl_generatricerequise: detailsData.vdl_generatricerequise,
          vdl_descriptiondesequipementsaetreinstalles:
            detailsData.vdl_descriptiondesequipementsaetreinstalles,
          vdl_cascadesoueffetsspeciaux:
            detailsData.vdl_cascadesoueffetsspeciaux,
          vdl_descriptiondetailleedescascadeseffetsspeciaux:
            detailsData.vdl_descriptiondetailleedescascadeseffetsspeciaux,

          vdl_engagementloisetreglement:
            detailsData.vdl_engagementloisetreglement,
          vdl_respectdesconditionsdusoutienmunicipal:
            detailsData.vdl_respectdesconditionsdusoutienmunicipal,
          vdl_envoidesdocuments2semmaximumavantletournage:
            detailsData.vdl_envoidesdocuments2semmaximumavantletournage,
          vdl_engagementapayerlafacture:
            detailsData.vdl_engagementapayerlafacture,
          vdl_sengageafournirlesjustificatifssurdemande:
            detailsData.vdl_sengageafournirlesjustificatifssurdemande,
          dates: detailsData.dates ?? [],
        },
      };
      demandFilmingRow.regroupement.demandes =
        demandData.regroupement.demandes.map((demande: any) => ({
          title: demande.title,
          incidentid: demande.incidentid,
          ticketnumber: demande.ticketnumber,
          statuscode: demande.statuscode,
          modifiedon: demande.modifiedon,
          createdon: demande.createdon,
          vdl_typededemande: {
            value: demande.vdl_typededemande.Id,
            label: demande.vdl_typededemande.Name,
          },
        }));

      return demandFilmingRow;
  }
};
