import React, { useEffect, useMemo, useState } from "react";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import BasicTabs from "../components/layouts/AppTabs/AppTabs";
import { TabContent, User } from "../types";
import { DemandListRow, DemandRegroupementRow } from "../data/Demand/models";
import { getDemands } from "../data/Demand/requests";
import useUser from "../hooks/useUserData";
import DemandCard from "../components/pageSpecific/Demands/DemandCard/DemandCard";
import { Box, InputAdornment, Skeleton, TextField } from "@mui/material";
import DemandRegroupment from "../components/pageSpecific/Demands/DemandRegroupment/DemandRegroupment";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";
import DemandCardSkeleton from "../components/pageSpecific/Demands/DemandCard/DemandCardSkeleton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import AppTabsSkeleton from "../components/layouts/AppTabs/AppTabsSkeleton";
import { canUserReadDemand } from "../data/Demand/rules";

function filterDemandsByStatusCode(
  tab: number,
  demands: DemandRegroupementRow[],
  user: User
): DemandRegroupementRow[] {
  const statusCodesToFilter =
    tab === 0
      ? ["En création", "Soumise", "En traitement", "Inutilisée", "Incomplète", "Approuvée", "En cours"]
      : ["Annulée", "Fermée", "Refusée"];

  const filterByStatus = (demands: DemandListRow[]) => {
    return demands.filter(
      (subDemand) =>
        statusCodesToFilter.includes(subDemand.statuscode) &&
        canUserReadDemand(user, subDemand.vdl_contactprincipal?.Id)
    );
  };

  const tempDemands: DemandRegroupementRow[] = [];

  demands.forEach((demand: DemandRegroupementRow) => {
    if (
      demand.type === "demande" &&
      statusCodesToFilter.includes(demand.statuscode) &&
      canUserReadDemand(user, demand.vdl_contactprincipal?.Id)
    ) {
      tempDemands.push(demand);
    } else if (demand.type === "regroupement") {
      const filteredDemands = filterByStatus(demand.demandes);
      if (filteredDemands.length > 0) {
        tempDemands.push({
          ...demand,
          demandes: filteredDemands,
        });
      }
    }
  });

  return tempDemands;
}

function filterDemands(
  demands: DemandRegroupementRow[],
  searchValue: string
): DemandRegroupementRow[] {
  const searchValueLowercase = searchValue.trim().toLowerCase();

  if (searchValueLowercase === "") return demands;

  const tempDemands: DemandRegroupementRow[] = [];

  demands.forEach((demand: DemandRegroupementRow) => {
    if (
      demand.type === "demande" &&
      (demand.title.toLowerCase().includes(searchValueLowercase) ||
        demand.ticketnumber.toLowerCase().includes(searchValueLowercase))
    ) {
      tempDemands.push(demand);
    } else if (demand.type === "regroupement") {
      const filteredDemands = demand.demandes.filter(
        (subDemand) =>
          subDemand.title.toLowerCase().includes(searchValueLowercase) ||
          subDemand.ticketnumber.toLowerCase().includes(searchValueLowercase)
      );
      if (filteredDemands.length > 0) {
        tempDemands.push({
          ...demand,
          demandes: filteredDemands,
        });
      }
    }
  });
  return tempDemands;
}

const Demands = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [demands, setDemands] = useState<DemandRegroupementRow[]>([]);
  const [showClearIcon, setShowClearIcon] = useState("none");
  const [searchValue, setSearchValue] = useState("");
  const { userData } = useUser();

  useEffect(() => {
    const getData = async () => {
      const demands = await getDemands(userData.parentcustomerid.Id);

      if (!demands.data || demands.error) {
        setError(demands.error);
        setIsLoading(false);
        return;
      }
      setDemands(demands.data);

      setIsLoading(false);
    };
    getData();
  }, []);

  const handleSearchValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setSearchValue(value);
    setShowClearIcon(value !== "" ? "flex" : "none");
  };

  const handleClear = () => {
    setSearchValue("");
    setShowClearIcon("none");
  };

  const textField = (
    <TextField
      size="small"
      variant="outlined"
      value={searchValue}
      onChange={handleSearchValue}
      placeholder="Rechercher"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            style={{ display: showClearIcon }}
            onClick={handleClear}
            sx={{ cursor: "pointer" }}
          >
            <ClearIcon />
          </InputAdornment>
        ),
      }}
    />
  );

  const openDemands = useMemo(
    () => filterDemandsByStatusCode(0, demands, userData),
    [demands, userData]
  );
  const closedDemands = useMemo(
    () => filterDemandsByStatusCode(1, demands, userData),
    [demands, userData]
  );

  const tabData: TabContent[] = [
    {
      label: `Demandes en cours (${openDemands.length})`,
      component: (
        <Box display="grid" gap="16px">
          {textField}
          {filterDemands(openDemands, searchValue).map((demand, idx) =>
            demand.type === "demande" ? (
              <DemandCard key={demand.incidentid} demand={demand} />
            ) : (
              <DemandRegroupment
                key={idx}
                demands={demand.demandes}
                regroupmentName={demand.regroupementName}
              />
            )
          )}
        </Box>
      ),
    },
    {
      label: `Demandes fermées (${closedDemands.length})`,
      component: (
        <Box display="grid" gap="16px">
          {textField}
          {filterDemands(closedDemands, searchValue).map((demand, idx) =>
            demand.type === "demande" ? (
              <DemandCard key={demand.incidentid} demand={demand} />
            ) : (
              <DemandRegroupment
                key={idx}
                demands={demand.demandes}
                regroupmentName={demand.regroupementName}
              />
            )
          )}
        </Box>
      ),
    },
  ];

  if (isLoading) return <DemandsPageLoading />;

  return (
    <PageTitleLayout title="Mes demandes">
      <BasicTabs tabs={tabData} />
    </PageTitleLayout>
  );
};

export default Demands;

const DemandsPageLoading = () => {
  return (
    <PageTitleLayout isLoading title="Mes demandes">
      <AppTabsSkeleton tabsNumber={2} activeTabIndex={1} />

      <BoxResponsivePadding display="grid" gap="16px">
        <Skeleton
          variant="rectangular"
          sx={{
            borderRadius: "4px / 6.7px ",
            fontSize: "2.08rem",
          }}
        />
        {Array(2)
          .fill(0)
          .map((i, idx) => (
            <DemandCardSkeleton icon key={idx} />
          ))}
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};
