import React, { useState } from "react";
import EditDemandLayout from "../EditDemandLayout";
import {
  DemandActivitiesCalendarPatch,
  DemandActivitiesCalendarRow,
} from "../../../../data/Demand/models";
import { NewDemandFields } from "../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../data/Demand/requests";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import {
  EditDemandActivitiesCalendar,
  editDemandActivitiesCalendarFields,
  editDemandActivitiesCalendarSections,
} from "../../../../data/Demand/forms/activitesCalendar";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import { validateDateString } from "../../../../data/common/validateDateString";
import convertIsoNumberToNumber from "../../../../data/common/isoStringToNumber";
import { fileToBase64 } from "../../../../data/common/fileToBase64";
import { dynamicsFileToFileType } from "../../../../data/common/buildFile";
import { buildForm } from "../../../form/SimpleForm/buildForm";


type Props = {
  demand: DemandActivitiesCalendarRow;
  isNewDemand: boolean;
  fieldsGeneral: NewDemandFields;
  onUpdateSuccess: () => void;
  onUpdateFailure: (error?: string) => void;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const ActivitiesCalendar = ({
  demand,
  isNewDemand,
  fieldsGeneral,
  setFieldsGeneral,
  onUpdateSuccess,
  onUpdateFailure,
  setError,
}: Props) => {
  const details = demand.vdl_detailsdelademande;
  const [fields, setFields] = useState<EditDemandActivitiesCalendar>(
    buildForm(editDemandActivitiesCalendarFields, {
      vdl_titredelevenement: details.vdl_titredelevenement ?? "",
      vdl_lieudelevenement: details.vdl_lieudelevenement ?? "",
      vdl_adressecompletedulieudelevenement:
        details.vdl_adressecompletedulieudelevenement ?? "",
      vdl_veuillezjoindreunephotofile: details.vdl_veuillezjoindreunephotofile
        ? dynamicsFileToFileType(details.vdl_veuillezjoindreunephotofile)
        : undefined,
      vdl_dateetheurededebut: validateDateString(
        details.vdl_dateetheurededebut
      ),
      vdl_dateetheuredefin: validateDateString(details.vdl_dateetheuredefin),
      vdl_commentairessurleshorairesdelevenement:
        details.vdl_commentairessurleshorairesdelevenement ?? "",
      vdl_cout: details.vdl_cout,
      vdl_adultes: details.vdl_adultes ? details.vdl_adultes.toString() : "",
      vdl_retraites: details.vdl_retraites
        ? details.vdl_retraites.toString()
        : "",
      vdl_etudiants: details.vdl_etudiants
        ? details.vdl_etudiants.toString()
        : "",
      vdl_enfants: details.vdl_enfants ? details.vdl_enfants.toString() : "",
      vdl_prixdefamille: details.vdl_prixdefamille
        ? details.vdl_prixdefamille.toString()
        : "",
      vdl_prixdegroupe: details.vdl_prixdegroupe
        ? details.vdl_prixdegroupe.toString()
        : "",
      vdl_autressvpdetaillez2: details.vdl_autressvpdetaillez2 ?? "",
      vdl_stationnementpayant: details.vdl_stationnementpayant ?? false,
      vdl_coutdestationnement: details.vdl_coutdestationnement
        ? details.vdl_coutdestationnement.toString()
        : "",
      vdl_clientelesvisees: [],
      vdl_siteinternet: details.vdl_titredelevenement ?? "",
      vdl_pagefacebook: details.vdl_titredelevenement ?? "",
      vdl_courriel: details.vdl_titredelevenement ?? "",
      vdl_telephonecalendrier: details.vdl_titredelevenement ?? "",
      vdl_descriptionsommairedelevenement: details.vdl_titredelevenement ?? "",
      vdl_autreinformationpertinente: details.vdl_titredelevenement ?? "",
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    try {
      const errors = handleSimpleFormSubmit(fields, setFields);

      if (errors.length > 0) {
        if (activeTab !== 1) changeTab(1);
        const scrollTo = fields[errors[errors.length - 1]].fieldRef;
        if (scrollTo) {
          scrollTo.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
        return;
      }
      const _demand: DemandActivitiesCalendarPatch = {
        title: fieldsGeneral.title.value,
        vdl_contactprincipal: fieldsGeneral.vdl_contactprincipal.value,
        type: demand.type,
        vdl_detailsdelademande: {
          vdl_titredelevenement: fields.vdl_titredelevenement.value,
          vdl_lieudelevenement: fields.vdl_lieudelevenement.value,
          vdl_adressecompletedulieudelevenement:
            fields.vdl_adressecompletedulieudelevenement.value,
          vdl_dateetheurededebut:
            fields.vdl_dateetheurededebut.value?.toISOString(),
          vdl_dateetheuredefin:
            fields.vdl_dateetheuredefin.value?.toISOString(),
          vdl_commentairessurleshorairesdelevenement:
            fields.vdl_commentairessurleshorairesdelevenement.value,
          vdl_cout: fields.vdl_cout.value,
          vdl_adultes: convertIsoNumberToNumber(fields.vdl_adultes.value),
          vdl_retraites: convertIsoNumberToNumber(fields.vdl_retraites.value),
          vdl_etudiants: convertIsoNumberToNumber(fields.vdl_etudiants.value),
          vdl_enfants: convertIsoNumberToNumber(fields.vdl_enfants.value),
          vdl_prixdefamille: convertIsoNumberToNumber(
            fields.vdl_prixdefamille.value
          ),
          vdl_prixdegroupe: convertIsoNumberToNumber(
            fields.vdl_prixdegroupe.value
          ),
          vdl_autressvpdetaillez2: fields.vdl_autressvpdetaillez2.value,
          vdl_stationnementpayant: fields.vdl_stationnementpayant.value,
          vdl_coutdestationnement: convertIsoNumberToNumber(
            fields.vdl_coutdestationnement.value
          ),
          vdl_siteinternet: fields.vdl_siteinternet.value,
          vdl_pagefacebook: fields.vdl_pagefacebook.value,
          vdl_courriel: fields.vdl_courriel.value,
          vdl_telephonecalendrier: fields.vdl_telephonecalendrier.value,
          vdl_descriptionsommairedelevenement:
            fields.vdl_descriptionsommairedelevenement.value,
          vdl_autreinformationpertinente:
            fields.vdl_autreinformationpertinente.value,
          vdl_clientelesvisees: fields.vdl_clientelesvisees.value,
        },
      };

      if (demand.statuscode === "Inutilisée") {
        _demand.vdl_detailsdelademande.statuscode = "En création";
      }

      if (fields.vdl_veuillezjoindreunephotofile.changed) {
        if (fields.vdl_veuillezjoindreunephotofile.value) {
          const pieceJointeToBase = await fileToBase64(
            fields.vdl_veuillezjoindreunephotofile.value
          );

          if (pieceJointeToBase) {
            _demand.vdl_detailsdelademande.vdl_veuillezjoindreunephotofile = {
              filename: fields.vdl_veuillezjoindreunephotofile.value.name,
              content: pieceJointeToBase,
            };
          }
        }
      }
      const { status, error } = await updateDemand(demand.incidentid, _demand);
      if (status === "success") {
        onUpdateSuccess();
      } else {
        onUpdateFailure(error);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_lienhautduformulaireportail}
    >
      <SimpleFields
        updateRefs
        fields={fields}
        setFields={setFields}
        sections={editDemandActivitiesCalendarSections}
        sectionsBackground="background.paper"
      />
    </EditDemandLayout>
  );
};

export default ActivitiesCalendar;
