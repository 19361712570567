import React, { useEffect, useState } from "react";
import {
  DemandFilmingRow,
  DemandSpecialEventPatch,
  DemandSpecialEventRow,
} from "../../../../../data/Demand/models";
import { NewDemandFields } from "../../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../../data/Demand/requests";
import EditDemandLayout from "../../EditDemandLayout";
import SimpleFields from "../../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../../hooks/useTabs";
import {
  EditDemandSpecialEventsGeneralFields,
  editDemandSpecialEventsGeneralFields,
  editDemandSpecialEventsGeneralSections,
} from "../../../../../data/Demand/forms/specialEvents/specialEventsGeneral";
import {
  EditDemandSpecialEventFields,
  editDemandSpecialEventFields,
  editDemandSpecialEventSections,
} from "../../../../../data/Demand/forms/specialEvents/specialEvent/specialEvent";
import { validateDateString } from "../../../../../data/common/validateDateString";
import { Box, Typography } from "@mui/material";
import CustomButton from "../../../../common/buttons/CustomButton";
import DatesDialog from "./DatesDialog/DatesDialog";
import {
  DemandSpecialEventsDateBase,
  DemandSpecialEventsDateEditing,
} from "../../../../../data/Demand/types/specialEvents/dates";
import { DemandSpecialEventsDetailsGeneralRow } from "../../../../../data/Demand/types/specialEvents/specialEventsGeneral";
import DateCard from "./DateCard";

type Props = {
  demandDetails: DemandSpecialEventsDetailsGeneralRow;
  fields: EditDemandSpecialEventsGeneralFields;
  setFields: React.Dispatch<
    React.SetStateAction<EditDemandSpecialEventsGeneralFields>
  >;
  dates: DemandSpecialEventsDateEditing[];
  setDates: React.Dispatch<
    React.SetStateAction<DemandSpecialEventsDateEditing[]>
  >;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

function SpecialEvents({
  fields,
  setFields,
  demandDetails,
  dates,
  setDates,
  setError,
}: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    try {
      const _fields = { ...fields };

      _fields.vdl_lieudelevenement.value =
        demandDetails.vdl_lieudelevenement ?? "";
      _fields.vdl_precisionsurlelieu.value =
        demandDetails.vdl_precisionsurlelieu;
      _fields.vdl_siautreprecisezlieu.value =
        demandDetails.vdl_siautreprecisezlieu ?? "";
      _fields.vdl_encasdintemperieslevenementest.value =
        demandDetails.vdl_encasdintemperieslevenementest ?? [];

      _fields.vdl_siremisle.value = validateDateString(
        demandDetails.vdl_siremisle
      );
      setFields(_fields);
      setDates(demandDetails.dates);
    } catch (error) {
      setError("Erreur dans la récupération des champs de la demande");
    }
  }, []);

  const addDate = (newDate: DemandSpecialEventsDateBase) => {
    setDates((prev) => [...prev, newDate]);
    setDialogOpen(false);
  };

  function deleteDate(idx: number) {
    setDates((prev) => {
      if ("vdl_datesid" in prev[idx]) {
        return prev.map((item, index) =>
          index === idx ? { ...item, delete: true } : item
        );
      }
      return prev.filter((_, index) => {
        return index !== idx;
      });
    });
  }
  return (
    <>
      {dialogOpen && (
        <DatesDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          addDate={addDate}
        />
      )}
      <Box display="grid" gap="16px">
        <SimpleFields
          fields={fields}
          setFields={setFields}
          sections={editDemandSpecialEventsGeneralSections}
          sectionsBackground="background.paper"
        />
        <Box
          display="grid"
          gap="16px"
          bgcolor="background.paper"
          padding="16px"
          mb="16px"
        >
          <Box display="flex" gap="16px">
            <Typography variant="h5" color="primary" paddingBottom="4px">
              Dates
            </Typography>
            <CustomButton primary onClick={() => setDialogOpen(true)}>
              Ajouter
            </CustomButton>
          </Box>
          <Box display="grid" gap="16px">
            {dates.length > 0 ? (
              dates.map((date, idx) => {
                if (!("delete" in date)) {
                  let key: string;
                  if ("vdl_datesid" in date) {
                    key = date.vdl_datesid + date.vdl_description;
                  } else {
                    key =
                      date.vdl_dateetheurededebut +
                      date.vdl_dateetheuredefin +
                      date.vdl_description +
                      idx;
                  }
                  return (
                    <DateCard
                      date={date}
                      deleteDate={() => deleteDate(idx)}
                      key={key}
                    />
                  );
                }
              })
            ) : (
              <Box bgcolor="background.default" padding="16px">
                <Typography>Il n'y a aucune date dans la demande</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SpecialEvents;
