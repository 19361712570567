import { IconButton, Tooltip, useTheme } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

type Props = {
  color?: string;
  backgroundColor?: string;
  tooltip?: string;
  icon: React.ReactElement;
  to: string;
  size?: "small" | "medium" | "large" | number | string;
  disabled?: boolean;
  ariaLabel?: string;
};

/**
 *
 * @param {string} color: color of the Icon / default: inherit
 * @param {String} backgroundColor: backgroundColor if roundedBackground is true / default: contrasts color
 * @param {string} to: link
 * @param {React.ReactNode} icon: icon component
 */
const LinkIcon = ({
  color,
  backgroundColor,
  icon,
  tooltip,
  size,
  to,
  ariaLabel,
  disabled,
}: Props) => {
  const iconColor = color;

  let pxSize: number | undefined;
  let stringSize: "small" | "medium" | "large" | undefined;
  let fontSize: string | undefined;

  if (typeof size === "number") {
    pxSize = size;
  } else if (size === "small" || size === "medium" || size === "large") {
    stringSize = size;
  } else if (size) {
    fontSize = size;
  }
  const base = (
    <IconButton
      aria-label={ariaLabel}
      disabled={disabled}
      to={to}
      component={Link}
      size={stringSize ? stringSize : undefined}

      sx={{
        fontSize: fontSize ? fontSize : undefined,
        backgroundColor: backgroundColor ? backgroundColor : undefined,
        color: iconColor ? iconColor : undefined,
        opacity: disabled ? "50%" : "100%",
        "&.Mui-disabled": {
          backgroundColor: backgroundColor ? backgroundColor : undefined,
        },
        "&:hover": {
          backgroundColor: backgroundColor ? backgroundColor : undefined,
          opacity: "0.8"
        },
      }}
    >
      {pxSize
        ? React.cloneElement(icon, {
            fontSize: "inherit",
            sx: { width: pxSize, height: pxSize },
          })
        : React.cloneElement(icon, { fontSize: "inherit" })}
    </IconButton>
  );
  if (tooltip) {
    return <Tooltip title={tooltip}>{base}</Tooltip>;
  }
  return <>{base}</>;
};

export default LinkIcon;
