import React, { useEffect, useState } from "react";
import {
  DemandSecurityPreventionPatch,
  DemandSecurityPreventionRow,
} from "../../../../data/Demand/models";
import { NewDemandFields } from "../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../data/Demand/requests";
import EditDemandLayout from "../EditDemandLayout";

import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import {
  EditDemandSecurityPreventionFields,
  editDemandSecurityPreventionFields,
  editDemandSecurityPreventionSectionsShooting,
  editDemandSecurityPreventionSectionsSpecialEvent,
} from "../../../../data/Demand/forms/securityPrevention";
import { validateDateString } from "../../../../data/common/validateDateString";
import {buildForm} from "../../../form/SimpleForm/buildForm";

type Props = {
  demand: DemandSecurityPreventionRow;
  isNewDemand: boolean;
  fieldsGeneral: NewDemandFields;
  onUpdateSuccess: () => void;
  onUpdateFailure: (error?: string) => void;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const SecurityPrevention = ({
  demand,
  isNewDemand,
  fieldsGeneral,
  setFieldsGeneral,
  onUpdateSuccess,
  onUpdateFailure,
  setError,
}: Props) => {
  const details = demand.vdl_detailsdelademande;
  const [fields, setFields] = useState<EditDemandSecurityPreventionFields>(
    buildForm(editDemandSecurityPreventionFields, {
      //Feux à ciel ouvert
      vdl_feuacielouvert: details.vdl_feuacielouvert ?? false,
      vdl_feudecamp1mdediametre: details.vdl_feudecamp1mdediametre ?? false,
      vdl_feudejoie1mdediametre: details.vdl_feudejoie1mdediametre ?? false,
      vdl_foyerbrasero: details.vdl_foyerbrasero ?? false,

      //Artiste du feu
      vdl_artistedefeu: details.vdl_artistedefeu ?? false,
      vdl_jongleur: details.vdl_jongleur ?? false,
      vdl_cracheurdefeu: details.vdl_cracheurdefeu ?? false,
      vdl_autre: details.vdl_autre ?? false,
      vdl_siautreprecisez: details.vdl_siautreprecisez ?? "",

      //Accessoire avec feu
      vdl_accessoireavecfeu: details.vdl_accessoireavecfeu ?? false,
      vdl_flambeaux: details.vdl_flambeaux ?? false,
      vdl_lampions: details.vdl_lampions ?? false,
      vdl_lanternes: details.vdl_lanternes ?? false,
      vdl_autre2: details.vdl_autre2 ?? false,
      vdl_siautreprecisez1: details.vdl_siautreprecisez1 ?? "",

      //Utilisation de pièces pyrotechniques et effets spéciaux
      vdl_utilisationdepiecespyrotechniqueseteffetsspe:
        details.vdl_utilisationdepiecespyrotechniqueseteffetsspe ?? false,
      vdl_feudartificedomestique: details.vdl_feudartificedomestique ?? false,
      vdl_feudartificeagrandeploiement:
        details.vdl_feudartificeagrandeploiement ?? false,
      vdl_effetspeciauxpyrotechniques:
        details.vdl_effetspeciauxpyrotechniques ?? false,
      vdl_precisez: details.vdl_precisez ?? "",
      vdl_effetsspeciaux: details.vdl_effetsspeciaux ?? false,
      vdl_precisez1: details.vdl_precisez1 ?? "",
      vdl_cascade: details.vdl_cascade ?? false,
      vdl_simulationdevol: details.vdl_simulationdevol ?? false,
      vdl_simulationdebagarre: details.vdl_simulationdebagarre ?? false,
      vdl_simulationdenlevement: details.vdl_simulationdenlevement ?? false,
      vdl_autre3: details.vdl_autre3 ?? false,
      vdl_siautreprecisez2: details.vdl_siautreprecisez2 ?? "",

      //Utilisation d'un appareil à combustion
      vdl_utilisationdunappareilacombustion:
        details.vdl_utilisationdunappareilacombustion ?? false,
      vdl_generatrice: details.vdl_generatrice ?? false,
      vdl_appareildecuison: details.vdl_appareildecuison ?? false,
      vdl_barbecue: details.vdl_barbecue ?? false,
      vdl_chauffagedappoint: details.vdl_chauffagedappoint ?? false,
      vdl_autre4: details.vdl_autre4 ?? false,
      vdl_autre5: details.vdl_autre5 ?? "",
      vdl_gazpropane: details.vdl_gazpropane ?? false,
      vdl_essence: details.vdl_essence ?? false,
      vdl_charbon: details.vdl_charbon ?? false,
      vdl_autre1: details.vdl_autre1 ?? false,
      vdl_siautreprecisez3: details.vdl_siautreprecisez3 ?? "",

      //Activité de nuit sur un site municipal
      vdl_activitedenuitsurunsitemunicipal:
        details.vdl_activitedenuitsurunsitemunicipal ?? false,
      vdl_interieur: details.vdl_interieur ?? false,
      vdl_exterieur: details.vdl_exterieur ?? false,
      vdl_heurededebutdutilisationdusite: validateDateString(
        details.vdl_heurededebutdutilisationdusite
      ),
      vdl_heuredefindutilisationdusite: validateDateString(
        details.vdl_heuredefindutilisationdusite
      ),
      vdl_nombredeparticipant: details.vdl_nombredeparticipant,
      vdl_enfants512ans: details.vdl_enfants512ans,
      vdl_adolescents1317ans: details.vdl_adolescents1317ans,
      vdl_adultes: details.vdl_adultes,

      //Survol à basse altitude – atterrissages/décollages – Hélicoptères – utilisation d’un drone
      vdl_survolabasealtitude: details.vdl_survolabasealtitude ?? false,
      vdl_utilisationdappareildetypeaviation:
        details.vdl_utilisationdappareildetypeaviation ?? false,
      vdl_precisez2: details.vdl_precisez2 ?? "",

      //Autres
      vdl_attentionparticuliereduservicedepolice:
        details.vdl_attentionparticuliereduservicedepolice ?? false,
      vdl_securite500persbenevolesagentsdesecurite:
        details.vdl_securite500persbenevolesagentsdesecurite ?? false,
      vdl_casernemobile: details.vdl_casernemobile ?? false,
      vdl_kiosquedepreventionpolice:
        details.vdl_kiosquedepreventionpolice ?? false,
      vdl_kiosquedepreventionincendies:
        details.vdl_kiosquedepreventionincendies ?? false,
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      const scrollTo = fields[errors[errors.length - 1]].fieldRef;
      if (scrollTo) {
        scrollTo.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      return;
    }
    
    const _demand: DemandSecurityPreventionPatch = {
      title: fieldsGeneral.title.value,
      vdl_contactprincipal: fieldsGeneral.vdl_contactprincipal.value,
      type: demand.type,
      vdl_detailsdelademande: {
        vdl_feuacielouvert: fields.vdl_feuacielouvert.value,
        vdl_feudecamp1mdediametre: fields.vdl_feudecamp1mdediametre.value,
        vdl_feudejoie1mdediametre: fields.vdl_feudejoie1mdediametre.value,
        vdl_foyerbrasero: fields.vdl_foyerbrasero.value,

        vdl_artistedefeu: fields.vdl_artistedefeu.value,
        vdl_jongleur: fields.vdl_jongleur.value,
        vdl_cracheurdefeu: fields.vdl_cracheurdefeu.value,
        vdl_autre: fields.vdl_autre.value,
        vdl_siautreprecisez: fields.vdl_siautreprecisez.value,

        vdl_accessoireavecfeu: fields.vdl_accessoireavecfeu.value,
        vdl_flambeaux: fields.vdl_flambeaux.value,
        vdl_lampions: fields.vdl_lampions.value,
        vdl_lanternes: fields.vdl_lanternes.value,
        vdl_autre2: fields.vdl_autre2.value,
        vdl_siautreprecisez1: fields.vdl_siautreprecisez1.value,

        vdl_utilisationdepiecespyrotechniqueseteffetsspe:
          fields.vdl_utilisationdepiecespyrotechniqueseteffetsspe.value,
        vdl_feudartificedomestique: fields.vdl_feudartificedomestique.value,
        vdl_feudartificeagrandeploiement:
          fields.vdl_feudartificeagrandeploiement.value,
        vdl_effetspeciauxpyrotechniques:
          fields.vdl_effetspeciauxpyrotechniques.value,
        vdl_precisez: fields.vdl_precisez.value,
        vdl_effetsspeciaux: fields.vdl_effetsspeciaux.value,
        vdl_precisez1: fields.vdl_precisez1.value,
        vdl_cascade: fields.vdl_cascade.value,
        vdl_simulationdevol: fields.vdl_simulationdevol.value,
        vdl_simulationdebagarre: fields.vdl_simulationdebagarre.value,
        vdl_simulationdenlevement: fields.vdl_simulationdenlevement.value,
        vdl_autre3: fields.vdl_autre3.value,
        vdl_siautreprecisez2: fields.vdl_siautreprecisez2.value,

        vdl_utilisationdunappareilacombustion:
          fields.vdl_utilisationdunappareilacombustion.value,
        vdl_generatrice: fields.vdl_generatrice.value,
        vdl_appareildecuison: fields.vdl_appareildecuison.value,
        vdl_barbecue: fields.vdl_barbecue.value,
        vdl_chauffagedappoint: fields.vdl_chauffagedappoint.value,
        vdl_autre4: fields.vdl_autre4.value,
        vdl_autre5: fields.vdl_autre5.value,
        vdl_gazpropane: fields.vdl_gazpropane.value,
        vdl_essence: fields.vdl_essence.value,
        vdl_charbon: fields.vdl_charbon.value,
        vdl_autre1: fields.vdl_autre1.value,
        vdl_siautreprecisez3: fields.vdl_siautreprecisez3.value,

        vdl_activitedenuitsurunsitemunicipal:
          fields.vdl_activitedenuitsurunsitemunicipal.value,
        vdl_interieur: fields.vdl_interieur.value,
        vdl_exterieur: fields.vdl_exterieur.value,
        vdl_heurededebutdutilisationdusite:
          fields.vdl_heurededebutdutilisationdusite.value?.toISOString(),
        vdl_heuredefindutilisationdusite:
          fields.vdl_heuredefindutilisationdusite.value?.toISOString(),
        vdl_nombredeparticipant: fields.vdl_nombredeparticipant.value,
        vdl_enfants512ans: fields.vdl_enfants512ans.value,
        vdl_adolescents1317ans: fields.vdl_adolescents1317ans.value,
        vdl_adultes: fields.vdl_adultes.value,

        vdl_survolabasealtitude: fields.vdl_survolabasealtitude.value,
        vdl_utilisationdappareildetypeaviation:
          fields.vdl_utilisationdappareildetypeaviation.value,
        vdl_precisez2: fields.vdl_precisez2.value,

        vdl_attentionparticuliereduservicedepolice:
          fields.vdl_attentionparticuliereduservicedepolice.value,
        vdl_securite500persbenevolesagentsdesecurite:
          fields.vdl_securite500persbenevolesagentsdesecurite.value,
        vdl_casernemobile: fields.vdl_casernemobile.value,
        vdl_kiosquedepreventionpolice:
          fields.vdl_kiosquedepreventionpolice.value,
        vdl_kiosquedepreventionincendies:
          fields.vdl_kiosquedepreventionincendies.value,
      },
    };

    if (demand.statuscode === "Inutilisée") {
      _demand.vdl_detailsdelademande.statuscode = "En création";
    }

    const { status, error } = await updateDemand(demand.incidentid, _demand);
    if (status === "success") {
      onUpdateSuccess();
    } else {
      onUpdateFailure(error);
    }
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_lienhautduformulaireportail}
    >
      <SimpleFields
        updateRefs
        fields={fields}
        setFields={setFields}
        sections={
          demand.vdl_detailsdelademande.vdl_typedactivite === "Évènement"
            ? editDemandSecurityPreventionSectionsSpecialEvent
            : editDemandSecurityPreventionSectionsShooting
        }
        sectionsBackground="common.white"
      />
    </EditDemandLayout>
  );
};

export default SecurityPrevention;
