import { Box, Dialog, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SimpleFormWithSectionsWithState from "../../../../form/SimpleForm/SimpleFormWithSectionsWithState";
import { newDemandFiels } from "../../../../../data/Demand/forms/dialog";
import Transition from "../../../../common/animation/DialogTransition";
import { Route, useNavigate } from "react-router-dom";
import { createDemand } from "../../../../../data/Demand/requests";
import useUser from "../../../../../hooks/useUserData";
import { ContactRow } from "../../../../../data/Contact/models";
import { DropDownChoiceWithLabel } from "../../../../../types";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import { RouterRoutes } from "../../../../../routes/routes";
import { ServiceRow } from "../../../../../data/Service/models";
import clone from "just-clone";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  contacts: ContactRow[];
  selectedType: ServiceRow;
};

const NewDemandDialog = ({ open, setOpen, contacts, selectedType }: Props) => {
  const [fields, setFields] = useState(clone(newDemandFiels));
  const { userData } = useUser();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    let contactChoices: DropDownChoiceWithLabel[] = [];
    contacts.forEach((contact) => {
      if (contact.statecode === "Actif") {
        contactChoices.push({
          label: `${contact.firstname} ${contact.lastname}${
            contact.contactid === userData.contactid ? " (vous)" : ""
          }`,
          value: contact.contactid,
        });
      }
    });

    setFields({
      ...fields,
      vdl_contactprincipal: {
        ...fields.vdl_contactprincipal,
        choices: contactChoices,
        value: userData.contactid,
      },
    });
  }, []);

  async function handleSubmit() {
    if (!fields.vdl_contactprincipal.value) return;
    const { data, error } = await createDemand({
      title: fields.title.value,
      customerid: userData.parentcustomerid.Id,
      vdl_contactprincipal: fields.vdl_contactprincipal.value,
      vdl_typededemande: selectedType.vdl_typedeserviceid,
    });

    if (data && !error) {
      navigate(`${RouterRoutes.Demands}/${data}/${RouterRoutes.Edit}?tab=1`);
    } else {
      showSnackbar(
        "Erreur dans l'initiatlisation de la demande. Réessayez ou contactez le support.",
        "error"
      );
    }
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth="md"
      fullWidth
    >
      <Box padding="16px 16px 0 16px">
        <Typography variant="h5" color="primary">
          {selectedType?.vdl_name}
        </Typography>
        <Typography>
          <br />
          Après l'initiation de la demande, vous serez redirigé vers la page de
          détails de la demande pour compléter ses informations spécifiques.
        </Typography>
        {selectedType.vdl_informationscomplementairesauportail && (
          <Typography>
            <p>{selectedType.vdl_informationscomplementairesauportail}</p>
          </Typography>
        )}
      </Box>
      <SimpleFormWithSectionsWithState
        fields={fields}
        setFields={setFields}
        columns={{ minWidth: 220, count: 1 }}
        submitText={"Initier la création"}
        handleSuccess={handleSubmit}
        cancelText="Annuler"
        handleCancel={() => setOpen(false)}
      />
    </Dialog>
  );
};

export default NewDemandDialog;
