import React, { useState } from "react";
import {
  DemandPrismePatch,
  DemandPrismeRow,
} from "../../../../data/Demand/models";
import { NewDemandFields } from "../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../data/Demand/requests";
import EditDemandLayout from "../EditDemandLayout";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import {
  EditDemandPrismeFields,
  editDemandPrismeFields,
  editDemandPrismeSections,
} from "../../../../data/Demand/forms/prisme";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import { validateDateString } from "../../../../data/common/validateDateString";
import { dynamicsFileToFileType } from "../../../../data/common/buildFile";
import { fileToBase64 } from "../../../../data/common/fileToBase64";
import {buildForm} from "../../../form/SimpleForm/buildForm";

type Props = {
  demand: DemandPrismeRow;
  isNewDemand: boolean;
  fieldsGeneral: NewDemandFields;
  onUpdateSuccess: () => void;
  onUpdateFailure: (error?: string) => void;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const Prisme = ({
  demand,
  isNewDemand,
  fieldsGeneral,
  setFieldsGeneral,
  onUpdateSuccess,
  onUpdateFailure,
  setError,
}: Props) => {
  const details = demand.vdl_detailsdelademande;
  const [fields, setFields] = useState<EditDemandPrismeFields>(
    buildForm(editDemandPrismeFields, {
      vdl_activiteapromouvoir: details.vdl_activiteapromouvoir ?? "",
      vdl_nombredepanneaux: details.vdl_nombredepanneaux,
      vdl_esquissedelaffichefile: details.vdl_esquissedelaffichefile
        ? dynamicsFileToFileType(details.vdl_esquissedelaffichefile)
        : undefined,
      vdl_datedelactivite: validateDateString(details.vdl_datedelactivite),
      vdl_datededebutdelevenement: validateDateString(
        details.vdl_datededebutdelevenement
      ),
      vdl_datedefindelevenement: validateDateString(
        details.vdl_datedefindelevenement
      ),
      vdl_autoroute25bouldelaconcordeestsite3:
        details.vdl_autoroute25bouldelaconcordeestsite3 ?? false,
      vdl_monteedumoulinleparcdumoulinsite10:
        details.vdl_monteedumoulinleparcdumoulinsite10 ?? false,
      vdl_bouldeslaurentidesboulsaintmartinsite1:
        details.vdl_bouldeslaurentidesboulsaintmartinsite1 ?? false,
      vdl_bouldeslaurentidesboulcartiersite2:
        details.vdl_bouldeslaurentidesboulcartiersite2 ?? false,
      vdl_boullecorbusierbouldusouvenirsite8:
        details.vdl_boullecorbusierbouldusouvenirsite8 ?? false,
      vdl_bouldelaconcordebouldelavenirsite9:
        details.vdl_bouldelaconcordebouldelavenirsite9 ?? false,
      vdl_boulcurelabelleboullevesquesite4:
        details.vdl_boulcurelabelleboullevesquesite4 ?? false,
      vdl_boularthursauvebouldagenaisouestsite6:
        details.vdl_boularthursauvebouldagenaisouestsite6 ?? false,
      vdl_boulsamsonavjosaphatdemerssite11:
        details.vdl_boulsamsonavjosaphatdemerssite11 ?? false,
      vdl_bouldeslaurentidesbouldagenaisestsite5:
        details.vdl_bouldeslaurentidesbouldagenaisestsite5 ?? false,
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      const scrollTo = fields[errors[errors.length - 1]].fieldRef;
      if (scrollTo) {
        scrollTo.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      return;
    }

    const _demand: DemandPrismePatch = {
      title: fieldsGeneral.title.value,
      vdl_contactprincipal: fieldsGeneral.vdl_contactprincipal.value,
      type: demand.type,
      vdl_detailsdelademande: {
        vdl_activiteapromouvoir: fields.vdl_activiteapromouvoir.value,
        vdl_nombredepanneaux: fields.vdl_nombredepanneaux.value,
        vdl_datedelactivite: fields.vdl_datedelactivite.value?.toISOString(),
        vdl_datededebutdelevenement:
          fields.vdl_datededebutdelevenement.value?.toISOString(),
        vdl_datedefindelevenement:
          fields.vdl_datedefindelevenement.value?.toISOString(),
        vdl_autoroute25bouldelaconcordeestsite3:
          fields.vdl_autoroute25bouldelaconcordeestsite3.value,
        vdl_monteedumoulinleparcdumoulinsite10:
          fields.vdl_monteedumoulinleparcdumoulinsite10.value,
        vdl_bouldeslaurentidesboulsaintmartinsite1:
          fields.vdl_bouldeslaurentidesboulsaintmartinsite1.value,
        vdl_bouldeslaurentidesboulcartiersite2:
          fields.vdl_bouldeslaurentidesboulcartiersite2.value,
        vdl_boullecorbusierbouldusouvenirsite8:
          fields.vdl_boullecorbusierbouldusouvenirsite8.value,
        vdl_bouldelaconcordebouldelavenirsite9:
          fields.vdl_bouldelaconcordebouldelavenirsite9.value,
        vdl_boulcurelabelleboullevesquesite4:
          fields.vdl_boulcurelabelleboullevesquesite4.value,
        vdl_boularthursauvebouldagenaisouestsite6:
          fields.vdl_boularthursauvebouldagenaisouestsite6.value,
        vdl_boulsamsonavjosaphatdemerssite11:
          fields.vdl_boulsamsonavjosaphatdemerssite11.value,
        vdl_bouldeslaurentidesbouldagenaisestsite5:
          fields.vdl_bouldeslaurentidesbouldagenaisestsite5.value,
      },
    };
    if (fields.vdl_esquissedelaffichefile.changed) {
      if (fields.vdl_esquissedelaffichefile.value) {
        const pieceJointeToBase = await fileToBase64(
          fields.vdl_esquissedelaffichefile.value
        );
        if (pieceJointeToBase) {
          _demand.vdl_detailsdelademande.vdl_esquissedelaffichefile = {
            filename: fields.vdl_esquissedelaffichefile.value.name,
            content: pieceJointeToBase,
          };
        }
      }
    }
    if (demand.statuscode === "Inutilisée") {
      _demand.vdl_detailsdelademande.statuscode = "En création";
    }

    const { status, error } = await updateDemand(demand.incidentid, _demand);
    if (status === "success") {
      onUpdateSuccess();
    } else {
      onUpdateFailure(error);
    }
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_lienhautduformulaireportail}
    >
      <SimpleFields
        updateRefs
        fields={fields}
        setFields={setFields}
        sectionsBackground="common.white"
        sections={editDemandPrismeSections}
        columns={{ count: 2, minWidth: 280 }}
      />
    </EditDemandLayout>
  );
};

export default Prisme;
