import React, { useEffect } from "react";
import "./App.css";
import ThemeProvider from "./theme/theme";
import AuthenticatedLayout from "./components/layouts/AppLayouts/AuthenticatedLayout";
import { BrowserRouter, useLocation, useParams } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import {
  IPublicClientApplication,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import AppLoading from "./components/common/loading/AppLoading";
import { UserProvider } from "./providers/UserProvider";
import MsalProvider from "./providers/MsalProvider";
import NewAccount from "./pages/NewAccount.page";
import { SnackbarProvider } from "./providers/SnackbarProvider";
import { signUpRequestBuilder } from "./auth/authConfig";
import AuthenticationError from "./components/common/auth/AuthenticationError";
import { Location } from "@remix-run/router";
import TabsProvider from "./providers/TabsProvider";
import ConfirmationProvider from "./providers/ConfirmationProvider";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5000,
        gcTime: 5000,
        refetchOnWindowFocus: false,
      },
    },
    queryCache: new QueryCache({
      onSuccess(data, query) {
        console.log(query.queryKey);
      },
      onError: (error) => {
        console.log(error);
      },
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={true} />
      <BrowserRouter>
        <MsalProvider>
          <ThemeProvider>
            <ConfirmationProvider>
              <SnackbarProvider>
                <UserProvider noInfoComponent={<NewAccount />}>
                  <AppRoutes />
                </UserProvider>
              </SnackbarProvider>
            </ConfirmationProvider>
          </ThemeProvider>
        </MsalProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;

