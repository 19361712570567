import {
  Badge,
  Box,
  Card,
  CardContent,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { DemandListRow } from "../../../../data/Demand/models";
import CustomChip from "../../../common/misc/CustomChip";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { demandDisplayNames } from "../../../../data/Demand/displayNames";
import ClickableIcon from "../../../common/buttons/icons/ClickableIcon";
import { dateStringToShortString } from "../../../../data/common/fieldToString";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import { Link } from "react-router-dom";
import { RouterRoutes, basePaths, routes } from "../../../../routes/routes";
import LinkIcon from "../../../common/buttons/icons/LinkIcon";
import {
  canUserDeleteDemand,
  canUserEditDemand,
  isDemandEditable,
} from "../../../../data/Demand/rules";
import useUser from "../../../../hooks/useUserData";

const CardBox = styled(Box)(({ theme }) => ({
  display: "grid",
  rowGap: "8px",
  columnGap: "16px",
  height: "100%",
  alignItems: "center",
  gridTemplateColumns: "auto 1fr",
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "auto 2.5rem 4rem",
    justifyContent: "space-between",
  },
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "auto auto 8rem 4rem",
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "4fr 3rem 3fr 8rem 4rem",
    gridTemplateRows: "auto auto",
    gridAutoFlow: "column",
    rowGap: "0",
  },
}));

type Props = {
  demand: DemandListRow;
  redirectHome?: boolean;
};

const DemandCard = ({ demand, redirectHome }: Props) => {
  const { userData } = useUser();

  const baseDemandPath = `${routes.demands.path}/${demand.incidentid}`;

  return (
    <Box padding="16px" bgcolor="background.paper" borderRadius="4px">
      <CardBox>
        <Box
          sx={{
            gridRow: {},
            gridColumn: {
              xs: "span 2",
              sm: "span 3",
              md: "span 4",
              lg: "span 1",
            },
          }}
        >
          <Typography
            variant="h6"
            fontWeight={600}
            color="primary"
            lineHeight={1.4}
            component={Link}
            to={`${baseDemandPath}/?tab=1`}
            sx={{
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {demand.title}
          </Typography>
        </Box>
        <Box
          sx={{
            gridRow: {
              xs: "3",
              md: "2",
            },
            gridColumn: {
              xs: "span 2",
              sm: "span 1",
            },
          }}
        >
          <Typography
            fontSize={"0.9rem"}
            fontWeight="600"
            display="inline"
            component="span"
          >
            {demand.ticketnumber}
          </Typography>
          <Typography>
            <Typography fontSize={"0.9rem"} display="inline" component="span">
              {`${demandDisplayNames.createdon}: `}
            </Typography>
            <Typography
              fontSize={"0.9rem"}
              display="inline"
              component="span"
              fontWeight="600"
            >
              {dateStringToShortString(demand.createdon)}
            </Typography>
          </Typography>
        </Box>
        <Box
          sx={{
            gridRow: {
              xs: "2",
              sm: "2/span 2",
              md: "2",
              lg: "1 / span 2",
            },
            gridColumn: {
              xs: "2",
            },
          }}
        >
          <LinkIcon
            ariaLabel={`${demand.vdl_notifications} Nouvelles notifications`}
            disabled={demand.vdl_notifications === 0}
            icon={
              <Badge badgeContent={demand.vdl_notifications} color="primary">
                <NotificationsIcon color="action" />
              </Badge>
            }
            to={`${baseDemandPath}/?tab=0`}
          />
        </Box>
        <Box
          sx={{
            gridRow: {
              xs: "4",
              md: "3",
              lg: "1/span 2",
            },
            gridColumn: {
              xs: "span 2",
              sm: "span 1",
              lg: "3",
            },
            paddingX: {
              lg: "16px",
            },
          }}
        >
          <Typography>
            <Typography fontSize={"0.9rem"} display="inline" component="span">
              {`Créé par: `}
            </Typography>
            <Typography
              fontSize={"0.9rem"}
              display="inline"
              component="span"
              fontWeight="600"
            >
              {demand.vdl_contactprincipal
                ? demand.vdl_contactprincipal.Name
                : ""}
            </Typography>
          </Typography>
          <Typography>
            <Typography fontSize={"0.9rem"} display="inline" component="span">
              {`Type: `}
            </Typography>
            <Typography
              fontSize={"0.9rem"}
              display="inline"
              component="span"
              fontWeight="600"
            >
              {demand.vdl_typededemande.Name}
            </Typography>
          </Typography>
        </Box>
        <Box
          sx={{
            gridRow: {
              sm: "2",
              md: "2",
              lg: "span 2",
            },
            gridColumn: {
              md: "3",
              lg: "4",
            },
          }}
        >
          <CustomChip text={demand.statuscode} status={demand.statuscode} />
        </Box>
        <Box
          display="flex"
          gap="12px"
          alignItems="center"
          sx={{
            gridRow: {
              xs: "5",
              sm: "2/span 2",
              md: "2",
              lg: "span 2",
            },
            gridColumn: {
              xs: "span 2",
              sm: "3/span 1",
              md: "4",
              lg: "5",
            },
            justifyContent: {
              xs: "start",
              sm: "end"
            }
          }}
        >
          {isDemandEditable(demand) &&
            canUserEditDemand(userData, demand.vdl_contactprincipal?.Id) && (
              <LinkIcon
                backgroundColor="background.default"
                icon={<EditIcon />}
                to={`${baseDemandPath}/${basePaths.edit}?tab=1${
                  redirectHome ? "&redirect=accueil" : ""
                }`}
              />
            )}
          {/* canUserDeleteDemand(
                userData,
                demand.vdl_contactprincipal?.value
              ) && (
                <ClickableIcon
                  backgroundColor="background.default"
                  icon={<BlockIcon />}
                  handleClick={() => {}}
                />
              ) */}
        </Box>
      </CardBox>
    </Box>
  );
};

export default DemandCard;
