import React, { useEffect, useState } from "react";
import { sendInvite } from "../data/Contact/requests";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import { Box, Typography, useTheme } from "@mui/material";
import DataCard from "../components/common/cards/DataCard/DataCard";
import Edit from "@mui/icons-material/Edit";
import DataCardSkeleton from "../components/common/cards/DataCard/DataCardSkeleton";
import contactDisplayNames from "../data/Contact/displayNames";
import LinkIcon from "../components/common/buttons/icons/LinkIcon";
import SendIcon from "@mui/icons-material/Send";
import BlockIcon from "@mui/icons-material/Block";
import useUser from "../hooks/useUserData";
import { Link, useParams } from "react-router-dom";
import { getDisplayFieldsFromRow } from "../data/common/fieldToString";
import { ContactPatch, ContactRow } from "../data/Contact/models";
import PrivateComponent from "../components/common/auth/PrivateComponent";
import ClickableIcon from "../components/common/buttons/icons/ClickableIcon";
import { useSnackbar } from "../hooks/useSnackbar";
import CustomChip from "../components/common/misc/CustomChip";
import ErrorMessage from "../components/common/error/ErrorMessage";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";
import { RouterRoutes, basePaths } from "../routes/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryError } from "../types";
import QueryErrorMessage from "../components/common/error/QueryErrorMessage";
import { getContact, updateContact } from "../data/Contact/queries";

const fieldsToDisplay: (keyof ContactRow)[] = [
  "vdl_salutation",
  "emailaddress1",
  "firstname",
  "lastname",
  "telephone1",
  "telephone2",
  "vdl_fonction",
  "statecode",
];

const ContactPage = () => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const { email } = useParams();

  const { data, error, isLoading, isFetching } = useQuery<
    ContactRow,
    QueryError
  >({
    queryKey: ["Contact", email],
    queryFn: () => getContact(email),
  });

  const mutation = useMutation({
    mutationFn: ({
      contactid,
      contact,
    }: {
      contactid: string;
      contact: ContactPatch;
    }) => updateContact(contactid, contact),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["Contact", email] });
    }
  });

  if (isLoading || mutation.isPending) return <ContactPageLoading />;

  if (error || !data) return <QueryErrorMessage error={error} />;

  const handleDeactivate = async () => {
    mutation.mutate(
      {
        contactid: data.contactid,
        contact: {
          vdl_accesauportail: false,
          statecode: "Inactif",
        },
      },
      {
        onSuccess: () => {
          showSnackbar("Contact désactivé", "success");
        },
        onError: () => {
          showSnackbar("Erreur dans la désactivation", "error");
        },
      }
    );
  };

  const handleSendInvite = async () => {
    mutation.mutate(
      {
        contactid: data.contactid,
        contact: {
          vdl_accesauportail: false,
          statecode: "Inactif",
        },
      },
      {
        onSuccess: () => {
          showSnackbar("Contact invité", "success");
        },
        onError: () => {
          showSnackbar("Erreur dans lors de l'invitation", "error");
        },
      }
    );
  };

  return (
    <PageTitleLayout
      title={`${data.firstname} ${data.lastname}`}
      sideTitleComponent={
        <Box
          display="flex"
          rowGap="1rem"
          columnGap="1.5rem"
          alignItems="center"
          flexWrap="wrap"
        >
          <CustomChip
            color={data.vdl_accesauportail ? "green" : "grey"}
            text={
              data.vdl_accesauportail
                ? "Accès au portail"
                : "Pas accès au portail"
            }
          />
          <PrivateComponent roleMinimum="Administrateur">
            <Box display="flex" gap="1rem">
              <LinkIcon
                size="1.3rem"
                tooltip="Modifier ce contact"
                to={basePaths.edit}
                disabled={isFetching}
                icon={<Edit />}
                backgroundColor="background.default"
              />
              {data.vdl_accesauportail ? null : (
                <ClickableIcon
                  size="1.3rem"
                  tooltip="Envoyer une invitation"
                  icon={<SendIcon />}
                  handleClick={handleSendInvite}
                  disabled={isFetching}
                  backgroundColor="background.default"
                />
              )}
              {data.vdl_accesauportail ? (
                <ClickableIcon
                  size="1.3rem"
                  tooltip="Désactiver l'accès portail"
                  handleClick={handleDeactivate}
                  icon={<BlockIcon />}
                  backgroundColor="background.default"
                />
              ) : null}
            </Box>
          </PrivateComponent>
        </Box>
      }
      underTitleComponent={
        <Typography fontWeight="bold">
          {data.vdl_roledesecurite?.Name}
        </Typography>
      }
    >
      <Box
        sx={{
          ...theme.custom.pageCardContainer,
        }}
      >
        <DataCard
          emptyTextPlaceHolder="---"
          title="Informations personnelles"
          displayFields={getDisplayFieldsFromRow(
            data,
            fieldsToDisplay,
            contactDisplayNames
          )}
        />
      </Box>
    </PageTitleLayout>
  );
};

export default ContactPage;

const ContactPageLoading = () => {
  return (
    <PageTitleLayout isLoading title="Profil">
      <BoxResponsivePadding>
        <DataCardSkeleton icon displayFieldsCount={fieldsToDisplay.length} />
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};
