import React, { useEffect, useState } from "react";
import SectionLoading from "../components/common/loading/SectionLoading";
import { DropDownChoiceWithLabel } from "../types";
import useUser from "../hooks/useUserData";
import { useSnackbar } from "../hooks/useSnackbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import ErrorMessage from "../components/common/error/ErrorMessage";
import { getDemand } from "../data/Demand/requests";
import { NewDemandFields, newDemandFiels } from "../data/Demand/forms/dialog";
import { getAccount } from "../data/Account/requests";
import InfoRequest from "../components/pageSpecific/EditDemand/InfoRequest/InfoRequest";
import { DemandRow } from "../data/Demand/models";
import { routes } from "../routes/routes";
import StandardEquipment from "../components/pageSpecific/EditDemand/StandardEquipment/StandardEquipment";
import Fundraiser from "../components/pageSpecific/EditDemand/Fundraiser/Fundraiser";
import ActivitiesCalendar from "../components/pageSpecific/EditDemand/ActivitiesCalendar/ActivitiesCalendar";
import TelevisionDisplay from "../components/pageSpecific/EditDemand/TelevisionDisplay/TelevisionDisplay";
import WebsiteDisplay from "../components/pageSpecific/EditDemand/WebsiteDisplay/WebsiteDisplay";
import Prisme from "../components/pageSpecific/EditDemand/Prisme/Prisme";
import ElectronicPanel from "../components/pageSpecific/EditDemand/ElectronicPanel/ElectronicPanel";
import Admissibility from "../components/pageSpecific/EditDemand/Admissibility/Admissibility";
import PublicSpace from "../components/pageSpecific/EditDemand/PublicSpace/PublicSpace";
import SpecialEvent from "../components/pageSpecific/EditDemand/SpecialEvents/SpecialEvent/SpecialEvent";
import PublicRoad from "../components/pageSpecific/EditDemand/PublicRoad/PublicRoad";
import SecurityPrevention from "../components/pageSpecific/EditDemand/SecurityPrevention/SecurityPrevention";
import Permit from "../components/pageSpecific/EditDemand/Permit/Permit";
import EcoSupport from "../components/pageSpecific/EditDemand/EcoSupport/EcoSupport";
import Filming from "../components/pageSpecific/EditDemand/SpecialEvents/Filming/Filiming";
import EventSupport from "../components/pageSpecific/EditDemand/EventSupport/EventSupport";

const EditDemand = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [fieldsGeneral, setFieldsGeneral] =
    useState<NewDemandFields>(newDemandFiels);
  const [demand, setDemand] = useState<DemandRow>();
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isNewDemand = queryParams.get("nouvelle") === "oui";
  const { userData } = useUser();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      if (!id) {
        setError("Url invalide");
        setIsLoading(false);
        return;
      }
      const demand = await getDemand(id);
      if (!demand.data || demand.error) {
        setError(demand.error);
        return;
      }

      setDemand(demand.data);

      const account = await getAccount(userData.parentcustomerid.Id);
      if (!account.data || account.error) {
        setIsLoading(false);
        setError(account.error);
        return;
      }

      let contactChoices: DropDownChoiceWithLabel[] = [];
      account.data.contacts.forEach((contact) => {
        if (contact.statecode === "Actif") {
          contactChoices.push({
            label: `${contact.firstname} ${contact.lastname}${
              contact.contactid === userData.contactid ? " (vous)" : ""
            }`,
            value: contact.contactid,
          });
        }
      });
      setFieldsGeneral({
        ...fieldsGeneral,
        title: {
          ...fieldsGeneral.title,
          value: demand.data.title,
        },
        vdl_contactprincipal: {
          ...fieldsGeneral.vdl_contactprincipal,
          choices: contactChoices,
          value: demand.data.vdl_contactprincipal
            ? demand.data.vdl_contactprincipal.contactid
            : undefined,
        },
      });

      setIsLoading(false);
    };
    getData();
  }, []);

  function renderDemandType(demand: DemandRow) {
    switch (demand.type) {
      case "Demande d'information":
        return (
          <InfoRequest
            onUpdateFailure={onUpdateFailure}
            onUpdateSuccess={onUpdateSuccess}
            demand={demand}
            isNewDemand={isNewDemand}
            fieldsGeneral={fieldsGeneral}
            setFieldsGeneral={setFieldsGeneral}
          />
        );

      case "Équipement spécialisé":
      case "Équipement standard":
        return (
          <StandardEquipment
            onUpdateFailure={onUpdateFailure}
            onUpdateSuccess={onUpdateSuccess}
            demand={demand}
            isNewDemand={isNewDemand}
            fieldsGeneral={fieldsGeneral}
            setFieldsGeneral={setFieldsGeneral}
            setError={setError}
          />
        );
      case "Collecte d'argent":
        return (
          <Fundraiser
            onUpdateFailure={onUpdateFailure}
            onUpdateSuccess={onUpdateSuccess}
            demand={demand}
            isNewDemand={isNewDemand}
            fieldsGeneral={fieldsGeneral}
            setFieldsGeneral={setFieldsGeneral}
            setError={setError}
          />
        );
      case "Calendrier des activités":
        return (
          <ActivitiesCalendar
            onUpdateFailure={onUpdateFailure}
            onUpdateSuccess={onUpdateSuccess}
            demand={demand}
            isNewDemand={isNewDemand}
            fieldsGeneral={fieldsGeneral}
            setFieldsGeneral={setFieldsGeneral}
            setError={setError}
          />
        );
      case "Panneaux électroniques":
        return (
          <ElectronicPanel
            onUpdateFailure={onUpdateFailure}
            onUpdateSuccess={onUpdateSuccess}
            demand={demand}
            isNewDemand={isNewDemand}
            fieldsGeneral={fieldsGeneral}
            setFieldsGeneral={setFieldsGeneral}
            setError={setError}
          />
        );
      case "Télévision communautaire":
        return (
          <TelevisionDisplay
            onUpdateFailure={onUpdateFailure}
            onUpdateSuccess={onUpdateSuccess}
            demand={demand}
            isNewDemand={isNewDemand}
            fieldsGeneral={fieldsGeneral}
            setFieldsGeneral={setFieldsGeneral}
            setError={setError}
          />
        );
      case "Site web de la ville":
        return (
          <WebsiteDisplay
            onUpdateFailure={onUpdateFailure}
            onUpdateSuccess={onUpdateSuccess}
            demand={demand}
            isNewDemand={isNewDemand}
            fieldsGeneral={fieldsGeneral}
            setFieldsGeneral={setFieldsGeneral}
            setError={setError}
          />
        );
      case "Prisme":
        return (
          <Prisme
            onUpdateFailure={onUpdateFailure}
            onUpdateSuccess={onUpdateSuccess}
            demand={demand}
            isNewDemand={isNewDemand}
            fieldsGeneral={fieldsGeneral}
            setFieldsGeneral={setFieldsGeneral}
            setError={setError}
          />
        );
      case "Déposer une demande d'admissibilité":
      case "Demande de renouvellement":
        return (
          <Admissibility
            onUpdateFailure={onUpdateFailure}
            onUpdateSuccess={onUpdateSuccess}
            demand={demand}
            isNewDemand={isNewDemand}
            fieldsGeneral={fieldsGeneral}
            setFieldsGeneral={setFieldsGeneral}
            setError={setError}
          />
        );
      case "Location ou prêt d'espaces municipaux":
        return (
          <PublicSpace
            onUpdateFailure={onUpdateFailure}
            onUpdateSuccess={onUpdateSuccess}
            demand={demand}
            isNewDemand={isNewDemand}
            fieldsGeneral={fieldsGeneral}
            setFieldsGeneral={setFieldsGeneral}
            setError={setError}
          />
        );
      case "Évènement spécial":
        return (
          <SpecialEvent
            onUpdateFailure={onUpdateFailure}
            onUpdateSuccess={onUpdateSuccess}
            demand={demand}
            isNewDemand={isNewDemand}
            fieldsGeneral={fieldsGeneral}
            setFieldsGeneral={setFieldsGeneral}
            setError={setError}
          />
        );
      case "Voie publique":
        return (
          <PublicRoad
            onUpdateFailure={onUpdateFailure}
            onUpdateSuccess={onUpdateSuccess}
            demand={demand}
            isNewDemand={isNewDemand}
            fieldsGeneral={fieldsGeneral}
            setFieldsGeneral={setFieldsGeneral}
            setError={setError}
          />
        );
      case "Sécurité prévention":
        return (
          <SecurityPrevention
            onUpdateFailure={onUpdateFailure}
            onUpdateSuccess={onUpdateSuccess}
            demand={demand}
            isNewDemand={isNewDemand}
            fieldsGeneral={fieldsGeneral}
            setFieldsGeneral={setFieldsGeneral}
            setError={setError}
          />
        );
      case "Permis":
        return (
          <Permit
            onUpdateFailure={onUpdateFailure}
            onUpdateSuccess={onUpdateSuccess}
            demand={demand}
            isNewDemand={isNewDemand}
            fieldsGeneral={fieldsGeneral}
            setFieldsGeneral={setFieldsGeneral}
            setError={setError}
          />
        );
      case "Soutien événement":
        return (
          <EventSupport
            onUpdateFailure={onUpdateFailure}
            onUpdateSuccess={onUpdateSuccess}
            demand={demand}
            isNewDemand={isNewDemand}
            fieldsGeneral={fieldsGeneral}
            setFieldsGeneral={setFieldsGeneral}
            setError={setError}
          />
        );
      case "Soutien Éco-Responsable":
        return (
          <EcoSupport
            onUpdateFailure={onUpdateFailure}
            onUpdateSuccess={onUpdateSuccess}
            demand={demand}
            isNewDemand={isNewDemand}
            fieldsGeneral={fieldsGeneral}
            setFieldsGeneral={setFieldsGeneral}
            setError={setError}
          />
        );
      case "Tournage":
        return (
          <Filming
            onUpdateFailure={onUpdateFailure}
            onUpdateSuccess={onUpdateSuccess}
            demand={demand}
            isNewDemand={isNewDemand}
            fieldsGeneral={fieldsGeneral}
            setFieldsGeneral={setFieldsGeneral}
            setError={setError}
          />
        );
    }
  }
  if (isLoading)
    return (
      <PageTitleLayout title="Modifier demande">
        <SectionLoading />
      </PageTitleLayout>
    );

  if (error || !demand) return <ErrorMessage message={error} />;

  const onUpdateSuccess = () => {
    const redirectString = queryParams.get("redirect");

    showSnackbar("Demande modifiée", "success");
    console.log(redirectString);
    console.log(demand);
    if (redirectString === "accueil") {
      navigate(`${routes.demands.path}`);
    } else {
      navigate(
        `${routes.demands.path}/${
          redirectString === "parent" && demand.regroupement
            ? demand.regroupement.principalIncidentId + "?tab=2"
            : demand.incidentid
        }`
      );
    }
  };

  const onUpdateFailure = (error?: string) => {
    showSnackbar(
      "Erreur dans la modification de la demande. Réessayez ou contactez le support.",
      "error"
    );
  };

  return renderDemandType(demand);
};

export default EditDemand;
