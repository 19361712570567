import React, { useState } from "react";
import {
  DemandFilmingPatch,
  DemandFilmingRow,
} from "../../../../../data/Demand/models";
import { NewDemandFields } from "../../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../../data/Demand/requests";
import EditDemandLayout from "../../EditDemandLayout";
import SimpleFields from "../../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../../hooks/useTabs";
import {
  EditDemandFilmingFields,
  editDemandFilmingFields,
  editDemandFilmingSections,
} from "../../../../../data/Demand/forms/specialEvents/filming/filiming";
import SpecialEvents from "../GeneralFields/SpecialEvents";
import {
  EditDemandSpecialEventsGeneralFields,
  editDemandSpecialEventsGeneralFields,
} from "../../../../../data/Demand/forms/specialEvents/specialEventsGeneral";
import {
  DemandSpecialEventsDateEditing,
  DemandSpecialEventsDatePatch,
} from "../../../../../data/Demand/types/specialEvents/dates";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import {buildForm} from "../../../../form/SimpleForm/buildForm";

type Props = {
  demand: DemandFilmingRow;
  isNewDemand: boolean;
  fieldsGeneral: NewDemandFields;
  onUpdateSuccess: () => void;
  onUpdateFailure: (error?: string) => void;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const Filming = ({
  demand,
  isNewDemand,
  fieldsGeneral,
  setFieldsGeneral,
  onUpdateSuccess,
  onUpdateFailure,
  setError,
}: Props) => {
  const details = demand.vdl_detailsdelademande;
  const [specialEventFields, setSpecialEventFields] =
    useState<EditDemandSpecialEventsGeneralFields>({
      ...editDemandSpecialEventsGeneralFields,
    });
  const [fields, setFields] = useState<EditDemandFilmingFields>(
    buildForm(editDemandFilmingFields, {
      vdl_titredelaproduction: details.vdl_titredelaproduction ?? "",
      vdl_naturedutournage: details.vdl_naturedutournage ?? [],
      vdl_tournageetudiant: details.vdl_tournageetudiant ?? false,
      vdl_resumeduscenarioenquelquesmots:
        details.vdl_resumeduscenarioenquelquesmots ?? "",
      vdl_nombredevehiculesdeproductionetparticuliers:
        details.vdl_nombredevehiculesdeproductionetparticuliers ?? undefined,
      vdl_cubes: details.vdl_cubes ?? undefined,
      vdl_roulottes: details.vdl_roulottes ?? undefined,
      vdl_voituresparticuliers: details.vdl_voituresparticuliers ?? undefined,
      vdl_autresprecisez: details.vdl_autresprecisez ?? "",
      vdl_generatricerequise: details.vdl_generatricerequise ?? false,
      vdl_descriptiondesequipementsaetreinstalles:
        details.vdl_descriptiondesequipementsaetreinstalles ?? "",
      vdl_cascadesoueffetsspeciaux:
        details.vdl_cascadesoueffetsspeciaux ?? false,
      vdl_descriptiondetailleedescascadeseffetsspeciaux:
        details.vdl_descriptiondetailleedescascadeseffetsspeciaux ?? "",

      vdl_engagementloisetreglement:
        details.vdl_engagementloisetreglement ?? false,
      vdl_respectdesconditionsdusoutienmunicipal:
        details.vdl_respectdesconditionsdusoutienmunicipal ?? false,
      vdl_envoidesdocuments2semmaximumavantletournage:
        details.vdl_envoidesdocuments2semmaximumavantletournage ?? false,
      vdl_engagementapayerlafacture:
        details.vdl_engagementapayerlafacture ?? false,
      vdl_sengageafournirlesjustificatifssurdemande:
        details.vdl_sengageafournirlesjustificatifssurdemande ?? false,
    })
  );
  const [dates, setDates] = useState<DemandSpecialEventsDateEditing[]>([]);
  const { activeTab, changeTab } = useTabs();
  const { showSnackbar } = useSnackbar();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      return;
    }

    const _demand: DemandFilmingPatch = {
      title: fieldsGeneral.title.value,
      vdl_contactprincipal: fieldsGeneral.vdl_contactprincipal.value,
      type: demand.type,
      vdl_detailsdelademande: {
        vdl_lieudelevenement: specialEventFields.vdl_lieudelevenement.value,
        vdl_precisionsurlelieu: specialEventFields.vdl_precisionsurlelieu.value,
        vdl_siautreprecisezlieu:
          specialEventFields.vdl_siautreprecisezlieu.value,
        vdl_encasdintemperieslevenementest:
          specialEventFields.vdl_encasdintemperieslevenementest.value,
        vdl_siremisle: specialEventFields.vdl_siremisle.value?.toISOString(),
        dates: [],

        vdl_titredelaproduction: fields.vdl_titredelaproduction.value,
        vdl_naturedutournage: fields.vdl_naturedutournage.value,
        vdl_tournageetudiant: fields.vdl_tournageetudiant.value,
        vdl_resumeduscenarioenquelquesmots:
          fields.vdl_resumeduscenarioenquelquesmots.value,
        vdl_nombredevehiculesdeproductionetparticuliers:
          fields.vdl_nombredevehiculesdeproductionetparticuliers.value,
        vdl_cubes: fields.vdl_cubes.value,
        vdl_roulottes: fields.vdl_roulottes.value,
        vdl_voituresparticuliers: fields.vdl_voituresparticuliers.value,
        vdl_autresprecisez: fields.vdl_autresprecisez.value,
        vdl_generatricerequise: fields.vdl_generatricerequise.value,
        vdl_descriptiondesequipementsaetreinstalles:
          fields.vdl_descriptiondesequipementsaetreinstalles.value,
        vdl_cascadesoueffetsspeciaux: fields.vdl_cascadesoueffetsspeciaux.value,
        vdl_descriptiondetailleedescascadeseffetsspeciaux:
          fields.vdl_descriptiondetailleedescascadeseffetsspeciaux.value,

        vdl_engagementloisetreglement:
          fields.vdl_engagementloisetreglement.value,
        vdl_respectdesconditionsdusoutienmunicipal:
          fields.vdl_respectdesconditionsdusoutienmunicipal.value,
        vdl_envoidesdocuments2semmaximumavantletournage:
          fields.vdl_envoidesdocuments2semmaximumavantletournage.value,
        vdl_engagementapayerlafacture:
          fields.vdl_engagementapayerlafacture.value,
        vdl_sengageafournirlesjustificatifssurdemande:
          fields.vdl_sengageafournirlesjustificatifssurdemande.value,
      },
    };

    const datesUpdate: DemandSpecialEventsDatePatch[] = [];

    let validDatesCount = 0;

    dates.forEach((date) => {
      if ("vdl_datesid" in date) {
        if ("delete" in date) {
          datesUpdate.push({
            vdl_datesid: date.vdl_datesid,
            delete: true,
          });
        } else {
          validDatesCount++;
        }
      } else {
        validDatesCount++;
        datesUpdate.push({
          ...date,
        });
      }
    });

    if (validDatesCount === 0) {
      showSnackbar(
        "Veuillez entrer au moins une date avant d'enregistrer la demande",
        "error"
      );
      return;
    }

    _demand.vdl_detailsdelademande.dates = datesUpdate;

    if (demand.statuscode === "Inutilisée") {
      _demand.vdl_detailsdelademande.statuscode = "En création";
    }

    const { status, error } = await updateDemand(demand.incidentid, _demand);
    if (status === "success") {
      onUpdateSuccess();
    } else {
      onUpdateFailure(error);
    }
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_lienhautduformulaireportail}
    >
      <SpecialEvents
        fields={specialEventFields}
        setFields={setSpecialEventFields}
        demandDetails={demand.vdl_detailsdelademande}
        dates={dates}
        setDates={setDates}
        setError={setError}
      />
      <SimpleFields
        fields={fields}
        setFields={setFields}
        sections={editDemandFilmingSections}
        sectionsBackground="background.paper"
      />
    </EditDemandLayout>
  );
};

export default Filming;
