import React from "react";
import { FormField, FormFields, FormSection } from "../../../types";
import SimpleFormSections from "./SimpleFormSections";
import { Box, Typography } from "@mui/material";
import BoxResponsivePadding from "../../common/boxes/BoxResponsivePadding";
import SimpleFormLoading from "./SimpleFormLoading";
import handleSimpleFormChange from "./handleSimpleFormChange";

type Props<T extends FormFields> = {
  title?: string;
  fields: T;
  columns?: {
    count: number;
    minWidth: number;
  };
  sections?: FormSection<T>[];
  sectionsBackground?: string;
  setFields: React.Dispatch<React.SetStateAction<T>>;
  isLoading?: boolean;
  updateRefs?: boolean
};

function SimpleFields<T extends FormFields>({
  fields,
  columns,
  sections,
  setFields,
  title,
  isLoading,
  sectionsBackground,
  updateRefs
}: Props<T>) {
  return (
    <Box position="relative" maxWidth="100%" overflow="hidden">
      <SimpleFormLoading loading={isLoading ? isLoading : false} />
      {title ? (
        <Typography variant="h5" color="primary" paddingBottom="16px">
          {title}
        </Typography>
      ) : null}
      <Box maxWidth="100%">
        <SimpleFormSections
          sectionsBackground={sectionsBackground}
          formData={fields}
          handleChange={(key: string, field: FormField) =>
            handleSimpleFormChange(key, field, fields, setFields)
          }
          columns={columns}
          sections={sections}
          updateRefs={updateRefs}
        />
      </Box>
    </Box>
  );
}

export default SimpleFields;
