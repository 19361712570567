import { Box, Dialog, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ClickableIcon from "../../../../../common/buttons/icons/ClickableIcon";
import { Close } from "@mui/icons-material";
import CustomButton from "../../../../../common/buttons/CustomButton";
import SimpleFields from "../../../../../form/SimpleForm/SimpleFields";
import { DropDownChoiceWithLabel, FormFields } from "../../../../../../types";
import { getSites } from "../../../../../../data/Plateau/requests";
import { SiteRow } from "../../../../../../data/Plateau/models.ts";
import {
  EditDemandPublicSpaceReservationFields,
  editDemandPublicSpaceReservationFields,
  editDemandRervationSections,
} from "../../../../../../data/Demand/forms/publicSpace/reservation";
import { DemandPublicSpaceReservationRowBase } from "../../../../../../data/Demand/types/publicSpace/reservation";
import handleSimpleFormSubmit from "../../../../../form/SimpleForm/handleSimpleFormSubmit";
import { useSnackbar } from "../../../../../../hooks/useSnackbar";
import CustomDialogHeader from "../../../../../dialog/CustomDialogHeader/CustomDialogHeader";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sites: SiteRow[];
  addReservation(newReservation: DemandPublicSpaceReservationRowBase): void;
};

const ReservationsDialog = ({
  open,
  setOpen,
  sites,
  addReservation,
}: Props) => {
  const [fields, setFields] = useState<EditDemandPublicSpaceReservationFields>(
    editDemandPublicSpaceReservationFields
  );
  const { showSnackbar } = useSnackbar();
  useEffect(() => {
    let sitesChoices: DropDownChoiceWithLabel[] = [];
    sites.forEach((site) => {
      sitesChoices.push({
        label: site.vdl_name,
        value: site.vdl_plateauxid,
      });
    });

    setFields((prevFields) => ({
      ...prevFields,
      vdl_nomdusiteareserver: {
        ...prevFields.vdl_nomdusiteareserver,
        choices: sitesChoices,
        onChangeEvent: onFormationChange,
      },
    }));
  }, []);

  function onFormationChange(fields: FormFields): FormFields | undefined {
    try {
      const _fields2 = fields as EditDemandPublicSpaceReservationFields;

      let _fields: Partial<EditDemandPublicSpaceReservationFields> = {};
      _fields.vdl_sousplateau = {
        ..._fields2.vdl_sousplateau,
        value: undefined,
        choices: [],
      };
      if (!_fields2.vdl_nomdusiteareserver.value) {
        _fields.vdl_sousplateau.choices = [];
        return _fields;
      }

      const sous_plateaux = sites.find(
        (site) => _fields2.vdl_nomdusiteareserver.value === site.vdl_plateauxid
      );
      if (!sous_plateaux) return _fields;

      const plateauChoices = sous_plateaux.plateaux.map((plateau) => ({
        label: plateau.vdl_name,
        value: plateau.vdl_plateauxid,
      }));

      _fields.vdl_sousplateau.choices = plateauChoices;
      return _fields;
    } catch (e) {
      return undefined;
    }
  }

  function handleSubmit() {
    try {
      const errors = handleSimpleFormSubmit(fields, setFields);

      if (errors.length < 1) {
        addReservation({
          vdl_debutdelareservation: fields.vdl_debutdelareservation.value?.toISOString() ?? "",
          vdl_findelareservation: fields.vdl_findelareservation.value?.toISOString() ?? "",
          vdl_ndetelephoneprincipal: fields.vdl_ndetelephoneprincipal.value,
          vdl_prenom: fields.vdl_prenom.value,
          vdl_nom: fields.vdl_nom.value,
          vdl_nombredeparticipants: fields.vdl_nombredeparticipants.value ?? 0,
          vdl_nomdusiteareserver: {
            Id: fields.vdl_nomdusiteareserver.value ?? "",
            Name: fields.vdl_nomdusiteareserver.choices.find(
              (choice) => choice.value === fields.vdl_nomdusiteareserver.value
            )?.label ?? "",
          },
          vdl_sousplateau: {
            Id: fields.vdl_sousplateau.value ?? "",
            Name: fields.vdl_sousplateau.choices.find(
              (choice) => choice.value === fields.vdl_sousplateau.value
            )?.label ?? "",
          },
          vdl_titre: fields.vdl_titre.value??""
        });
      }
    } catch (e) {
      showSnackbar("Erreur dans l'ajout de la réservation", "error");
    }
  }
  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
    >
      <CustomDialogHeader title="Nouvelle réservation" setOpen={setOpen}/>
      <Box>
        {sites.length > 1 ? (
          <SimpleFields
            columns={{ count: 2, minWidth: 250 }}
            fields={fields}
            setFields={setFields}
            sections={editDemandRervationSections}
          />
        ) : (
          <>
            <Box padding="2rem 1rem">
              <Typography variant="h5" color="primary">
                Erreur
              </Typography>
              <Typography>{"Erreur dans l'affichage des sites"}</Typography>
            </Box>
          </>
        )}
      </Box>
      <Box
        padding="16px"
        display="flex"
        alignItems="center"
        justifyContent="end"
      >
        {sites.length > 1 ? (
          <CustomButton primary onClick={handleSubmit}>
            Ajouter
          </CustomButton>
        ) : null}
      </Box>
    </Dialog>
  );
};

export default ReservationsDialog;