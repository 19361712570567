import { Box, Typography } from "@mui/material";
import { QueryError } from "../../../types";

type Props = {
  error?: QueryError | null | undefined;
};

const ErrorMessage = ({ error }: Props) => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      textAlign="center"
      boxSizing="border-box"
      padding="28px 20px"
    >
      <Typography variant="h4" color="primary" fontWeight="bold">
        Une erreur inattendue s'est produite.
      </Typography>
      <Typography variant="h5" color="primary">
        Rafraichir la page pour réessayer.
      </Typography>
      <Typography>Message d'erreur: {error?.message ?? "Erreur inconnue"}</Typography>
    </Box>
  );
};

export default ErrorMessage;
