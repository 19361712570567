import { errorHandler } from "../../components/common/error/errorHandler";
import { ContactRow } from "../Contact/models";
import { GetResponse, PatchResponse, PostResponse } from "../common/types";
import usefetchWithMsal from "../fetchWithMsal";
import {
  AccountPatch,
  AccountPost,
  AccountRow,
  AccountRowNoContacts,
} from "./models";

export const getAccount = async (
  id: string
): Promise<GetResponse<AccountRow>> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_account/${id}`;

    const result = await usefetchWithMsal("GET", endpoint);

    if (result.StatusCode !== 200)
      throw new Error("Erreur dans la requête du compte");
    const data = result.Value.Data;
    if (!data) throw new Error("Erreur dans la requête du compte");

    let tempContacts: ContactRow[] = [];
    if (data.contacts && data.contacts.length > 0) {
      data.contacts.forEach((contact: any) => {
        tempContacts.push({
          vdl_salutation: contact.vdl_salutation,
          contactid: contact.contactid,
          parentcustomerid: {
            Id: data.accountid,
            Name: data.Name,
          },
          firstname: contact.firstname,
          lastname: contact.lastname,
          emailaddress1: contact.emailaddress1,
          telephone1: contact.telephone1,
          vdl_fonction: contact.vdl_fonction,

          telephone2: contact.telephone2,
          vdl_consentement: contact.vdl_consentement,
          vdl_accesauportail: contact.vdl_accesauportail ?? false,
          createdon: contact.createdon,
          modifiedon: contact.modifiedon,
          statecode: contact.statecode,
          fullname: contact.firstname + "" + contact.lastname,
          vdl_roledesecurite: null
        });
      });
    }
    const tempData: AccountRow = {
      accountid: data.accountid,
      name: data.name,
      otherNames: data.otherNames,
      websiteurl: data.websiteurl,
      emailaddress1: data.emailaddress1,
      telephone1: data.telephone1,
      address1_city: data.address1_city,
      address1_line1: data.address1_line1,
      address1_line2: data.address1_line2,
      address1_line3: data.address1_line3,
      address1_country: data.address1_country,
      address1_fax: data.address1_fax,
      address1_postalcode: data.address1_postalcode,
      address1_stateorprovince: data.address1_stateorprovince,
      address2_city: data.address2_city,
      address2_country: data.address2_country,
      address2_line1: data.address2_line1,
      address2_line2: data.address2_line2,
      address2_line3: data.address2_line3,
      address2_postalcode: data.address2_postalcode,
      address2_stateorprovince: data.address2_stateorprovince,
      vdl_adresseidentique: data.vdl_adresseidentique,
      vdl_autresnomorganisme: data.vdl_autresnomorganisme,
      vdl_typedorganisation: {
        Id: data.vdl_typedorganisation.Id,
        Name: data.vdl_typedorganisation.Name,
      },
      vdl_neq: data.vdl_neq,
      vdl_pagefacebook: data.vdl_pagefacebook,
      vdl_secteursiegesocial: data.vdl_secteursiegesocial,
      contacts: tempContacts,
    };
    return { status: "success", data: tempData };
  } catch (e) {
    return errorHandler(e, "Erreur dans la récupération du compte");
  }
};

export const getAccountNoContacts = async (
  id: string
): Promise<GetResponse<AccountRowNoContacts>> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_account/${id}`;

    const result = await usefetchWithMsal("GET", endpoint);

    if (result.StatusCode !== 200)
      throw new Error("Erreur dans la requête du compte");
    const data = result.Value.Data;

    if (!data) throw new Error("Erreur dans la requête du compte");

    const tempData: AccountRowNoContacts = {
      accountid: data.accountid,
      name: data.name,
      otherNames: data.otherNames,
      websiteurl: data.websiteurl,
      emailaddress1: data.emailaddress1,
      telephone1: data.telephone1,
      address1_city: data.address1_city,
      address1_line1: data.address1_line1,
      address1_line2: data.address1_line2,
      address1_line3: data.address1_line3,
      address1_country: data.address1_country,
      address1_fax: data.address1_fax,
      address1_postalcode: data.address1_postalcode,
      address1_stateorprovince: data.address1_stateorprovince,
      address2_city: data.address2_city,
      address2_country: data.address2_country,
      address2_line1: data.address2_line1,
      address2_line2: data.address2_line2,
      address2_line3: data.address2_line3,
      address2_postalcode: data.address2_postalcode,
      address2_stateorprovince: data.address2_stateorprovince,
      vdl_adresseidentique: data.vdl_adresseidentique,
      vdl_autresnomorganisme: data.vdl_autresnomorganisme,
      vdl_typedorganisation: {
        Id: data.vdl_typedorganisation.Id,
        Name: data.vdl_typedorganisation.Name,
      },
      vdl_neq: data.vdl_neq,
      vdl_pagefacebook: data.vdl_pagefacebook,
      vdl_secteursiegesocial: data.vdl_secteursiegesocial,
    };

    return { status: "success", data: tempData };
  } catch (e) {
    return errorHandler(e, "Erreur dans la récupération du compte");
  }
};

export const createAccount = async (
  account: AccountPost
): Promise<PostResponse> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/create_account`;
    const result = await usefetchWithMsal("POST", endpoint, account);
    if (result.StatusCode === 200) {
      return { status: "success", data: result.Value };
    } else {
      throw new Error("Erreur dans l'exécution");
    }
  } catch (e) {
    return errorHandler(e, "Erreur dans l'ajout du compte");
  }
};

export const updateAccount = async (
  recordId: string,
  account: AccountPatch
): Promise<PatchResponse> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/update_account/${recordId}`;
    const result = await usefetchWithMsal("PATCH", endpoint, account);
    if (result.StatusCode === 200) {
      return { status: "success" };
    } else {
      throw new Error("Erreur dans l'exécution");
    }
  } catch (e) {
    return errorHandler(e, "Erreur dans la modification du compte");
  }
};
