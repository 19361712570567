import { Box, styled } from "@mui/material";
import React from "react";
import SimpleFormSectionTitle from "./SimpleFormSectionTitle";
import FormFieldWrapper from "./FormFieldWrapper";
import { FormField, FormFields, FormSection } from "../../../types";
import SimpleFormSection from "./SimpleFormSection";

export const FormBox = styled(Box)(() => ({
  display: "flex",
  gap: "16px",
  flexWrap: "wrap",
  maxWidth: "100%"
}));

type Props<T extends FormFields> = {
  formData: T;
  handleChange: (key: string, field: FormField) => void;
  columns?: {
    count: number;
    minWidth: number;
  };
  sections?: FormSection<T>[];
  sectionsBackground?: string;
  updateRefs?: boolean;
};

function SimpleFormSections<T extends FormFields>({
  formData,
  handleChange,
  columns,
  sections,
  sectionsBackground,
  updateRefs,
}: Props<T>) {
  return (
    <Box display="flex" gap="16px" flexDirection="column">
      {sections ? (
        sections.map((section, idx) => (
          <SimpleFormSection
            key={section.title??"" + idx}
            formData={formData}
            handleChange={handleChange}
            columns={columns}
            section={section}
            sectionsBackground={sectionsBackground}
            updateRefs={updateRefs}
          />
        ))
      ) : (
        <FormBox bgcolor={sectionsBackground} sx={{ paddingTop: "8px" }}>
          {Object.keys(formData).map((fieldKey, idx) => {
            const field = formData[fieldKey];
            const key = fieldKey.toString();
            return (
              <FormFieldWrapper
                field={field}
                columns={columns}
                key={key}
                fieldName={key}
                updateRefs={updateRefs}
                disabledConditionResult={field.disabledCondition
                  ? field.disabledCondition(formData)
                  : undefined}
                handleChange={handleChange} fields={formData}
                />
            );
          })}
        </FormBox>
      )}
    </Box>
  );
}

export default SimpleFormSections;
