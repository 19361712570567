import React, { useState } from "react";
import EditDemandLayout from "../EditDemandLayout";
import {
  DemandFundraiserPatch,
  DemandFundraiserRow,
} from "../../../../data/Demand/models";
import { NewDemandFields } from "../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../data/Demand/requests";
import {
  EditDemandFundraiserFields,
  editDemandFundraiser,
  editDemandFundraiserSections,
} from "../../../../data/Demand/forms/fundraiser";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import { validateDateString } from "../../../../data/common/validateDateString";
import {buildForm} from "../../../form/SimpleForm/buildForm";

type Props = {
  demand: DemandFundraiserRow;
  isNewDemand: boolean;
  fieldsGeneral: NewDemandFields;
  onUpdateSuccess: () => void;
  onUpdateFailure: (error?: string) => void;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const Fundraiser = ({
  demand,
  isNewDemand,
  fieldsGeneral,
  setFieldsGeneral,
  onUpdateSuccess,
  onUpdateFailure,
  setError,
}: Props) => {
  const details = demand.vdl_detailsdelademande;
  const [fields, setFields] = useState<EditDemandFundraiserFields>(
    buildForm(editDemandFundraiser, {
      vdl_projetdefinancement: details.vdl_projetdefinancement ?? "",
      vdl_stmartinboisdeboulogne: details.vdl_stmartinboisdeboulogne
        ? details.vdl_stmartinboisdeboulogne.toString()
        : undefined,
      vdl_jeanberauddanieljohnson: details.vdl_jeanberauddanieljohnson
        ? details.vdl_jeanberauddanieljohnson.toString()
        : undefined,
      vdl_chomedeycartier: details.vdl_chomedeycartier
        ? details.vdl_chomedeycartier.toString()
        : undefined,
      vdl_notredamelavatere: details.vdl_notredamelavatere
        ? details.vdl_notredamelavatere.toString()
        : undefined,
      vdl_nombredejeunesparticipants18ansetmoins:
        details.vdl_nombredejeunesparticipants18ansetmoins,
      vdl_nombredadultesparticipants5min:
        details.vdl_nombredadultesparticipants5min,
      vdl_erjour: validateDateString(details.vdl_erjour),
      vdl_emejour: validateDateString(details.vdl_emejour),
      vdl_3emejour: validateDateString(details.vdl_3emejour),
      vdl_4emejour: validateDateString(details.vdl_4emejour),
      vdl_5emejour: validateDateString(details.vdl_5emejour),
      vdl_engagementaurespectdesproceduresetdirectives:
        details.vdl_engagementaurespectdesproceduresetdirectives ?? false,
      vdl_jemengageasuivrelaformationobligatoire:
        details.vdl_jemengageasuivrelaformationobligatoire ?? false,
      vdl_jaidejasuivilaformationobligatoireannee: validateDateString(
        details.vdl_jaidejasuivilaformationobligatoireannee
      ),
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      const scrollTo = fields[errors[errors.length - 1]].fieldRef;
      if (scrollTo) {
        scrollTo.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      return;
    }

    /*
    const generalFields = validateGeneralFields(
      fieldsGeneral,
      setFieldsGeneral
    );
    if (generalFields === undefined) {
      setIsLoading(false);
      changeTab(0);
      return;
    }
    */
    const _demand: DemandFundraiserPatch = {
      title: fieldsGeneral.title.value,
      vdl_contactprincipal: fieldsGeneral.vdl_contactprincipal.value,
      type: demand.type,
      vdl_detailsdelademande: {
        vdl_projetdefinancement: fields.vdl_projetdefinancement.value,
        vdl_jeanberauddanieljohnson: fields.vdl_jeanberauddanieljohnson.value
          ? parseInt(fields.vdl_jeanberauddanieljohnson.value)
          : undefined,
        vdl_chomedeycartier: fields.vdl_chomedeycartier.value
          ? parseInt(fields.vdl_chomedeycartier.value)
          : undefined,
        vdl_stmartinboisdeboulogne: fields.vdl_stmartinboisdeboulogne.value
          ? parseInt(fields.vdl_stmartinboisdeboulogne.value)
          : undefined,
        vdl_notredamelavatere: fields.vdl_notredamelavatere.value
          ? parseInt(fields.vdl_notredamelavatere.value)
          : undefined,
        vdl_nombredejeunesparticipants18ansetmoins:
          fields.vdl_nombredejeunesparticipants18ansetmoins.value,
        vdl_nombredadultesparticipants5min:
          fields.vdl_nombredadultesparticipants5min.value,
        vdl_erjour: fields.vdl_erjour.value?.toString(),
        vdl_emejour: fields.vdl_emejour.value?.toString(),
        vdl_3emejour: fields.vdl_3emejour.value?.toString(),
        vdl_4emejour: fields.vdl_4emejour.value?.toString(),
        vdl_5emejour: fields.vdl_5emejour.value?.toString(),
        vdl_engagementaurespectdesproceduresetdirectives:
          fields.vdl_engagementaurespectdesproceduresetdirectives.value,
        vdl_jemengageasuivrelaformationobligatoire:
          fields.vdl_jemengageasuivrelaformationobligatoire.value,
        vdl_jaidejasuivilaformationobligatoireannee:
          fields.vdl_jaidejasuivilaformationobligatoireannee.value?.toString(),
      },
    };

    if (demand.statuscode === "Inutilisée") {
      _demand.vdl_detailsdelademande.statuscode = "En création";
    }

    const { status, error } = await updateDemand(demand.incidentid, _demand);
    if (status === "success") {
      onUpdateSuccess();
    } else {
      onUpdateFailure(error);
    }
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_lienhautduformulaireportail}
    >
      <SimpleFields
        updateRefs
        fields={fields}
        setFields={setFields}
        columns={{ count: 2, minWidth: 290 }}
        sections={editDemandFundraiserSections}
        sectionsBackground="white"
      />
    </EditDemandLayout>
  );
};

export default Fundraiser;
