import { User } from "../../types";
import { DemandFilmingRow, DemandListRow, DemandRow, DemandSpecialEventRow } from "./models";

export const demandEditableStatus = ["En création", "Inutilisée", "Incomplète"];

export const demandEventTypes = ["Évènement spécial", "Tournage"];

export const isDemandOfTypeEvent = (
  demand: DemandRow
): demand is DemandSpecialEventRow | DemandFilmingRow => {
  return demandEventTypes.indexOf(demand.type) > -1;
};

export const isDemandDeletable = (demand: DemandRow) =>
  (demand.statuscode === "En création" && !demand.regroupement) ||
  (demand.regroupement &&
    demand.regroupement.principalIncidentId === demand.incidentid);

export const isDemandEditable = (demand: DemandRow | DemandListRow) =>
  demandEditableStatus.indexOf(demand.statuscode) > -1;

  export const isDemandPrincipalIncident = (
    demand: DemandRow
  ) => {
    return demand.regroupement && demand.regroupement.principalIncidentId === demand.incidentid 
  };

export const canUserReadDemand = (
  user: User,
  demandContactId?: string
): boolean => {
  if (
    user.vdl_roledesecurite?.Name === "Administrateur" ||
    user.vdl_roledesecurite?.Name === "Employé"
  )
    return true;

  return demandContactId === user.contactid;
};

export const canUserEditDemand = (
  user: User,
  demandContactId?: string
): boolean => {
  if (user.vdl_roledesecurite?.Name === "Administrateur") return true;

  return demandContactId === user.contactid;
};

export const canUserDeleteDemand = (
  user: User,
  demandContactId?: string
): boolean => {
  if (user.vdl_roledesecurite?.Name === "Administrateur") return true;

  if (user.vdl_roledesecurite?.Name === "Employé")
    return demandContactId === user.contactid;

  return false;
};
