import React, { useState } from "react";
import {
  DemandElectronicPanelsPatch,
  DemandElectronicPanelsRow,
} from "../../../../data/Demand/models";
import { NewDemandFields } from "../../../../data/Demand/forms/dialog";
import { updateDemand } from "../../../../data/Demand/requests";
import EditDemandLayout from "../EditDemandLayout";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import {
  EditDemandElectronicPanelsFields,
  editDemandElectronicPanelsFields,
  editDemandElectronicPanelsSections,
} from "../../../../data/Demand/forms/electronicPanels";
import useTabs from "../../../../hooks/useTabs";
import { validateDateString } from "../../../../data/common/validateDateString";
import {buildForm} from "../../../form/SimpleForm/buildForm";

type Props = {
  demand: DemandElectronicPanelsRow;
  isNewDemand: boolean;
  fieldsGeneral: NewDemandFields;
  onUpdateSuccess: () => void;
  onUpdateFailure: (error?: string) => void;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const ElectronicPanel = ({
  demand,
  isNewDemand,
  fieldsGeneral,
  setFieldsGeneral,
  onUpdateSuccess,
  onUpdateFailure,
  setError,
}: Props) => {
  const details = demand.vdl_detailsdelademande;
  const [fields, setFields] = useState<EditDemandElectronicPanelsFields>(
    buildForm(editDemandElectronicPanelsFields, {
      vdl_datededebutdelaffichage: validateDateString(
        details.vdl_datededebutdelaffichage
      ),
      vdl_datedefindelaffichage: validateDateString(
        details.vdl_datedefindelaffichage
      ),
      vdl_quoi: details.vdl_quoi ?? "",
      vdl_qui: details.vdl_qui ?? "",
      vdl_ou: details.vdl_ou ?? "",
      vdl_quanddatesetheures: details.vdl_quanddatesetheures ?? "",
      vdl_telephonepourinformationsurlevenement:
        details.vdl_telephonepourinformationsurlevenement ?? "",
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);
    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      const scrollTo = fields[errors[errors.length - 1]].fieldRef;
      if (scrollTo) {
        scrollTo.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      return;
    }

    const _demand: DemandElectronicPanelsPatch = {
      title: fieldsGeneral.title.value,
      vdl_contactprincipal: fieldsGeneral.vdl_contactprincipal.value,
      type: demand.type,
      vdl_detailsdelademande: {
        vdl_datededebutdelaffichage:
          fields.vdl_datededebutdelaffichage.value?.toISOString(),
        vdl_datedefindelaffichage:
          fields.vdl_datedefindelaffichage.value?.toISOString(),
        vdl_quoi: fields.vdl_quoi.value,
        vdl_qui: fields.vdl_qui.value,
        vdl_quanddatesetheures: fields.vdl_quanddatesetheures.value,
        vdl_ou: fields.vdl_ou.value,
        vdl_telephonepourinformationsurlevenement:
          fields.vdl_telephonepourinformationsurlevenement.value,
      },
    };

    if (demand.statuscode === "Inutilisée") {
      _demand.vdl_detailsdelademande.statuscode = "En création";
    }

    const { status, error } = await updateDemand(demand.incidentid, _demand);
    if (status === "success") {
      onUpdateSuccess();
    } else {
      onUpdateFailure(error);
    }
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_lienhautduformulaireportail}
    >
      <SimpleFields
        updateRefs
        columns={{ count: 2, minWidth: 280 }}
        sectionsBackground="background.paper"
        fields={fields}
        setFields={setFields}
        sections={editDemandElectronicPanelsSections}
      />
    </EditDemandLayout>
  );
};

export default ElectronicPanel;
