import React from "react";
import { DemandRegroupmentRow } from "../../../../data/Demand/types/specialEvents/specialEventsGeneral";
import { Box, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import CustomChip from "../../../common/misc/CustomChip";
import LinkIcon from "../../../common/buttons/icons/LinkIcon";
import { Block, Edit } from "@mui/icons-material";
import ClickableIcon from "../../../common/buttons/icons/ClickableIcon";
import { basePaths, routes } from "../../../../routes/routes";
import { DemandGeneralPatch } from "../../../../data/Demand/models";
import { updateDemand } from "../../../../data/Demand/requests";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { useConfirm } from "../../../../hooks/useConfirmation";

const CardBox = styled(Box)(({ theme }) => ({
  display: "grid",
  rowGap: "8px",
  columnGap: "16px",
  height: "100%",
  alignItems: "center",
  borderRadius: "4px",
  padding: "16px",
  gridTemplateColumns: "1fr",
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "4fr 3fr 6.5rem",
    gridTemplateRows: "auto auto auto",
    gridAutoFlow: "column",
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "3fr 2fr 1fr 6.5rem",
    gridTemplateRows: "auto auto",
    rowGap: "0",
  },
  [theme.breakpoints.up("xl")]: {
    gridTemplateColumns: "4fr 3fr 1fr 6.5rem",
  },
}));

type Props = {
  demand: DemandRegroupmentRow;
  deactivate: () => void
};

const DemandCard = ({ demand, deactivate }: Props) => {

  const confirm = useConfirm();
  const {showSnackbar} = useSnackbar();

  return (
    <CardBox bgcolor="common.white">
      <Typography
        variant="h6"
        fontWeight={600}
        color="primary"
        lineHeight={1.4}
        component={Link}
        to={`/demandes/${demand.incidentid}`}
        sx={{
          gridColumn: {
            sm: "span 3",
            lg: "span 1",
          },
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        {demand.title}
      </Typography>
      <Typography
        fontSize={"0.9rem"}
        fontWeight="600"
        display="inline"
        component="span"
      >
        {demand.ticketnumber}
      </Typography>
      <Typography
        sx={{
          gridRow: {
            lg: "span 2",
          },
        }}
      >
        <Typography fontSize={"0.9rem"} display="inline" component="span">
          Type:{" "}
        </Typography>
        <Typography
          fontSize={"0.9rem"}
          display="inline"
          component="span"
          fontWeight="600"
        >
          {demand.vdl_typededemande.Name}
        </Typography>
      </Typography>
      <Box
        sx={{
          gridRow: {
            xs: "2",
            sm: "span 2",
            lg: "span 2",
          },
        }}
      >
        <CustomChip text={demand.statuscode} status={demand.statuscode} />
      </Box>

      <Box
        display="flex"
        gap="12px"
        alignItems="center"
        sx={{
          justifyContent: {
            sm: "end"
          },
          gridRow: {
            sm: "span 2",
            lg: "span 2",
          },
        }}
      >
        <LinkIcon
          backgroundColor="background.default"
          icon={<Edit />}
          to={`${routes.demands.path}/${demand.incidentid}/${basePaths.edit}?tab=1&redirect=parent`}
        />
        <ClickableIcon
          backgroundColor="background.default"
          tooltip="Ne plus utiliser cette demande"
          icon={<Block />}
          handleClick={deactivate}
        />
      </Box>
    </CardBox>
  );
};

export default DemandCard;
